import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@mui/material';
import {
  Mic,
  MicOff,
  PhotoCamera,
  Send,
  Edit,
  Delete,
  Save,
  FitnessCenter,
  DirectionsRun,
  Check,
  Close,
  ErrorOutline,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Fab from '@mui/material/Fab';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';

// Update to use environment variable only
const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

// Styled components
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const VoiceButton = styled(Button)(({ theme, islistening }) => ({
  height: islistening ? '120px' : '48px',
  transition: 'all 0.3s ease',
  background: islistening ? theme.palette.error.main : colors.accent,
  color: colors.primary,
  borderRadius: '12px',
  '&:hover': {
    background: islistening ? theme.palette.error.dark : colors.accent,
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
  },
}));

const TranscriptPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
}));

const WorkoutLogger = () => {
  const navigate = useNavigate();
  // Add workout type state
  const [workoutType, setWorkoutType] = useState('strength'); // 'strength' or 'cardio'
  
  // State for input methods
  const [isRecording, setIsRecording] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // State for workout data
  const [workoutData, setWorkoutData] = useState(null);
  const [workoutHistory, setWorkoutHistory] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [showTranscriptDialog, setShowTranscriptDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedTranscript, setEditedTranscript] = useState('');
  const [processingWorkout, setProcessingWorkout] = useState(false);
  const [editingResult, setEditingResult] = useState(false);
  const [editedResult, setEditedResult] = useState(null);
  const [success, setSuccess] = useState('');

  // Add new state variables for workout details
  const [workoutName, setWorkoutName] = useState('');
  const [workoutDate, setWorkoutDate] = useState(new Date().toISOString().split('T')[0]);  // YYYY-MM-DD format

  // Add new state variables at the top with other states
  const [distanceUnit, setDistanceUnit] = useState('miles'); // 'miles' or 'km'

  // Add theme and media query
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Add new state for mobile UI
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [expandedExercise, setExpandedExercise] = useState(null);

  // Add state for weight unit
  const [weightUnit, setWeightUnit] = useState(() => {
    // Try to get saved preference from localStorage, default to 'lbs'
    return localStorage.getItem('preferredWeightUnit') || 'lbs';
  });

  // Add useEffect to save unit preference
  useEffect(() => {
    localStorage.setItem('preferredWeightUnit', weightUnit);
  }, [weightUnit]);

  // Add unit selector component
  const WeightUnitSelector = () => (
    <ToggleButtonGroup
      value={weightUnit}
      exclusive
      onChange={(e, newUnit) => {
        if (newUnit !== null) {
          setWeightUnit(newUnit);
        }
      }}
      size="small"
      sx={{ mb: 2 }}
    >
      <ToggleButton value="lbs">
        lbs
      </ToggleButton>
      <ToggleButton value="kg">
        kg
      </ToggleButton>
    </ToggleButtonGroup>
  );

  // Add conversion functions
  const convertDistance = (value, fromUnit, toUnit) => {
    if (!value) return 0;
    const val = parseFloat(value);
    if (fromUnit === toUnit) return val;
    return toUnit === 'km' ? val * 1.60934 : val / 1.60934;
  };

  const convertPace = (pace, fromUnit, toUnit) => {
    if (!pace) return '';
    const [minutes, seconds = '00'] = pace.split(':');
    const totalMinutes = parseInt(minutes) + parseInt(seconds) / 60;
    
    if (fromUnit === toUnit) return pace;
    
    let newPaceMinutes;
    if (toUnit === 'km') {
      newPaceMinutes = totalMinutes / 1.60934;
    } else {
      newPaceMinutes = totalMinutes * 1.60934;
    }
    
    const newMinutes = Math.floor(newPaceMinutes);
    const newSeconds = Math.round((newPaceMinutes - newMinutes) * 60);
    return `${newMinutes}:${newSeconds.toString().padStart(2, '0')}`;
  };

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let currentTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          currentTranscript += event.results[i][0].transcript;
        }
        setTranscript(currentTranscript);
        setEditedTranscript(currentTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setError('Speech recognition error: ' + event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        if (transcript) {
          setShowTranscriptDialog(true);
        }
      };

      setRecognition(recognition);
    } else {
      setError('Speech recognition is not supported in this browser');
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  const startRecording = async () => {
    setError('');
    setTranscript('');
    setEditedTranscript('');
    
    if (recognition) {
      try {
        await recognition.start();
        setIsListening(true);
      } catch (err) {
        console.error('Error starting recognition:', err);
        setError('Could not start speech recognition');
      }
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      setEditDialogOpen(true);
      setEditedTranscript(transcript);
    }
  };

  const handleWorkoutTypeChange = (event, newType) => {
    if (newType !== null) {
      setWorkoutType(newType);
      // Reset states when changing type
      setInputText('');
      setTranscript('');
      setEditedTranscript('');
      setWorkoutData(null);
      setShowPreview(false);
    }
  };

  const handleAuthError = (error) => {
    if (error.status === 401) {
      alert('Your session has expired. Please sign in again.');
      navigate('/signin');
    } else if (error.status === 403) {
      alert('Your account is not active. Please contact support.');
      navigate('/signin');
    } else if (error.status === 404) {
      alert('User not found. Please sign in again.');
      navigate('/signin');
    }
  };

  // Modify handleTextSubmit to use different endpoints based on type
  const handleTextSubmit = async () => {
    console.log('handleTextSubmit called with:', { inputText, workoutType });
    if (!inputText.trim()) return;

    setLoading(true);
    setError('');
    setWorkoutData(null);

    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const endpoint = workoutType === 'strength' 
        ? 'process-gym-workout'
        : 'process-cardio-workout';
      
      console.log('Making request to endpoint:', endpoint);

      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 
          text: inputText,
          preferred_unit: weightUnit
        }),
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || `Failed to process ${workoutType} workout`);
      }

      const data = await response.json();
      console.log('Received workout data:', data);
      
      if (data) {
        console.log('Setting workout data and showing preview');
        setWorkoutData(data);
        setShowPreview(true);
        setInputText('');
      } else {
        throw new Error('No data received from server');
      }
    } catch (err) {
      console.error('Error in handleTextSubmit:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle image input with error handling
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError('No file selected');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      setError('Please upload a JPG or PNG image. Other formats like WebP are not supported.');
      event.target.value = '';
      return;
    }

    setLoading(true);
    setError('');

    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const formData = new FormData();
      formData.append('image', file, file.name);
      
      const backendWorkoutType = workoutType === 'strength' ? 'gym' : 'cardio';
      formData.append('workout_type', backendWorkoutType);

      const response = await fetch(`${BACKEND_URL}/process-image-workout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || `Failed to process ${workoutType} workout image`);
      }

      const data = await response.json();
      setWorkoutData(data);
      setShowPreview(true);
      setSelectedImage(URL.createObjectURL(file));
      setError('');
    } catch (err) {
      setError(err.message);
      event.target.value = '';
    } finally {
      setLoading(false);
    }
  };

  // Add feedback component
  const FeedbackMessage = ({ message, type }) => {
    if (!message) return null;
    
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999,
          p: 2,
          borderRadius: 1,
          bgcolor: type === 'success' ? 'success.light' : 'error.light',
          color: 'white',
          boxShadow: 3,
        }}
      >
        {message}
      </Box>
    );
  };

  const handleSaveWorkout = async () => {
    if (!workoutData) return;
    
    setLoading(true);
    setError('');
    
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const workoutToSave = {
        ...workoutData,
        name: workoutName || `${workoutType.charAt(0).toUpperCase() + workoutType.slice(1)} Workout`,
        date: new Date(workoutDate).toISOString(),
        type: workoutType
      };

      const response = await fetch(`${BACKEND_URL}/save-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(workoutToSave),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || 'Failed to save workout');
      }

      const data = await response.json();
      setWorkoutHistory([workoutToSave, ...workoutHistory]);
        setWorkoutData(null);
      setShowPreview(false);
      setWorkoutName('');
      setWorkoutDate(new Date().toISOString().split('T')[0]);
      
      const durationFormatted = data.duration?.formatted || workoutToSave.duration;
      setSuccess(`✅ Workout saved successfully!\nID: ${data.workout_id}\nDuration: ${durationFormatted}`);
      
        setTimeout(() => {
        setSuccess('');
      }, 5000);

    } catch (err) {
      setError(err.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitTranscript = async () => {
    try {
      setProcessingWorkout(true);
      setEditDialogOpen(false);

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const endpoint = workoutType === 'strength' 
        ? 'process-gym-workout'
        : 'process-cardio-workout';

      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ text: editedTranscript }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || `Failed to process ${workoutType} workout`);
      }

      const data = await response.json();
      setWorkoutData(data);
      setShowPreview(true);
      setEditedResult(null);
      setEditingResult(false);

    } catch (err) {
      setError(err.message);
    } finally {
      setProcessingWorkout(false);
    }
  };

  const handleSaveEditedWorkout = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const response = await fetch(`${BACKEND_URL}/save-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(editedResult),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || 'Failed to save edited workout');
      }

      setWorkoutData(editedResult);
      setEditedResult(null);
      setEditingResult(false);
      setSuccess('Workout updated successfully!');
      
      setTimeout(() => {
        setSuccess('');
      }, 5000);

    } catch (err) {
      setError(err.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  // Create a unified workout preview component
  const renderWorkoutContent = () => {
    console.log('renderWorkoutContent called with workoutData:', workoutData);
    if (!workoutData) return null;

    return (
      <Box>
        {/* Header Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 3, 
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Workout Name"
                value={workoutName}
                onChange={(e) => setWorkoutName(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 48,
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Workout Date"
                value={workoutDate}
                onChange={(e) => setWorkoutDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 48,
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        {workoutType === 'strength' ? (
          <Box>
            {/* Workout Details Section */}
            <Paper 
              elevation={0}
              sx={{ 
                p: 3,
                mb: 3,
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  color: colors.text,
                  fontWeight: 500,
                  mb: 3,
                }}
              >
                Workout Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Training Phase"
                    value={workoutData.training_phase || ''}
                    onChange={(e) => setWorkoutData({
                      ...workoutData,
                      training_phase: e.target.value
                    })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 48,
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  >
                    {['Strength', 'Hypertrophy', 'Power', 'Endurance'].map((phase) => (
                      <MenuItem key={phase.toLowerCase()} value={phase.toLowerCase()}>
                        {phase}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Workout Split"
                    value={workoutData.workout_split || ''}
                    onChange={(e) => setWorkoutData({
                      ...workoutData,
                      workout_split: e.target.value
                    })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 48,
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  >
                    {['Push', 'Pull', 'Legs', 'Upper', 'Lower', 'Full Body'].map((split) => (
                      <MenuItem key={split.toLowerCase()} value={split.toLowerCase()}>
                        {split}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Paper>

            {/* Exercises Section */}
            {workoutData.exercises?.map((exercise, index) => (
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  mb: 3,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '16px',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
                  },
                }} 
                key={index}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: colors.text,
                    fontWeight: 500,
                    mb: 3,
                  }}
                >
                  Exercise {index + 1}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Exercise Name"
                      value={exercise.name}
                      onChange={(e) => {
                        const updatedExercises = [...workoutData.exercises];
                        updatedExercises[index] = { ...exercise, name: e.target.value };
                        setWorkoutData({ ...workoutData, exercises: updatedExercises });
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.accent,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.accent,
                          },
                        },
                      }}
                    />
                  </Grid>
                  {exercise.sets?.map((set, setIndex) => (
                    <Grid container spacing={2} sx={{ px: 2, mt: 1 }} key={setIndex}>
                      <Grid item xs={3}>
                        <Typography sx={{ color: colors.muted, mb: 1 }}>Set {set.setNumber}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          type="number"
                          size="small"
                          label="Reps"
                          value={set.reps || ''}
                          onChange={(e) => {
                            const updatedExercises = [...workoutData.exercises];
                            updatedExercises[index].sets[setIndex] = {
                              ...set,
                              reps: parseInt(e.target.value)
                            };
                            setWorkoutData({ ...workoutData, exercises: updatedExercises });
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              '& fieldset': {
                                borderColor: colors.border,
                              },
                              '&:hover fieldset': {
                                borderColor: colors.accent,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: colors.accent,
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          size="small"
                          label="Weight"
                          value={set.weight || ''}
                          onChange={(e) => {
                            const updatedExercises = [...workoutData.exercises];
                            updatedExercises[index].sets[setIndex] = {
                              ...set,
                              weight: parseFloat(e.target.value)
                            };
                            setWorkoutData({ ...workoutData, exercises: updatedExercises });
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              '& fieldset': {
                                borderColor: colors.border,
                              },
                              '&:hover fieldset': {
                                borderColor: colors.accent,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: colors.accent,
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            ))}
          </Box>
        ) : (
          // Cardio workout content
          <Box>
            {/* Cardio Details Section */}
            <Paper 
              elevation={0}
              sx={{ 
                p: 3,
                mb: 3,
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  color: colors.text,
                  fontWeight: 500,
                  mb: 3,
                }}
              >
                Cardio Details
              </Typography>
              <Grid container spacing={3}>
                {/* Distance with unit toggle */}
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Distance"
                      value={workoutData.distance || ''}
                      onChange={(e) => {
                        const newDistance = e.target.value;
                        setWorkoutData({
                          ...workoutData,
                          distance: newDistance,
                          pace: calculatePace(newDistance, workoutData.duration)
                        });
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 48,
                          borderRadius: '12px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.accent,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.accent,
                          },
                        },
                      }}
                    />
                    <ToggleButtonGroup
                      size="small"
                      value={distanceUnit}
                      exclusive
                      onChange={(e, newUnit) => {
                        if (newUnit !== null) {
                          setDistanceUnit(newUnit);
                        }
                      }}
                      sx={{
                        '& .MuiToggleButton-root': {
                          border: `1px solid ${colors.border}`,
                          color: colors.text,
                          '&.Mui-selected': {
                            bgcolor: colors.accent,
                            color: colors.primary,
                            '&:hover': {
                              bgcolor: colors.accent,
                            },
                          },
                        },
                      }}
                    >
                      <ToggleButton value="miles">mi</ToggleButton>
                      <ToggleButton value="km">km</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>

                {/* Duration */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Duration (HH:MM:SS)"
                    value={workoutData.duration || ''}
                    onChange={(e) => {
                      const newDuration = e.target.value;
                      setWorkoutData({
                        ...workoutData,
                        duration: newDuration,
                        pace: calculatePace(workoutData.distance, newDuration)
                      });
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 48,
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Pace */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={`Pace (per ${distanceUnit === 'miles' ? 'mile' : 'kilometer'})`}
                    value={workoutData.pace || ''}
                    onChange={(e) => {
                      setWorkoutData({
                        ...workoutData,
                        pace: e.target.value
                      });
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 48,
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Calories */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Calories Burned"
                    value={workoutData.calories || ''}
                    onChange={(e) => {
                      setWorkoutData({
                        ...workoutData,
                        calories: e.target.value
                      });
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 48,
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Notes */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Notes"
                    value={workoutData.notes || ''}
                    onChange={(e) => {
                      setWorkoutData({
                        ...workoutData,
                        notes: e.target.value
                      });
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
          <Button
            startIcon={<Delete />}
            onClick={() => {
              setWorkoutData(null);
              setShowPreview(false);
              setWorkoutName('');
              setWorkoutDate(new Date().toISOString().split('T')[0]);
            }}
            sx={{
              height: 48,
              px: 3,
              borderColor: colors.border,
              color: colors.text,
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                borderColor: colors.accent,
                bgcolor: colors.subtle,
              },
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            startIcon={<Save />}
            onClick={handleSaveWorkout}
            sx={{
              height: 48,
              px: 3,
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
              transition: 'all 0.2s ease',
            }}
          >
            Save Workout
          </Button>
        </Box>
      </Box>
    );
  };

  // Update the preview rendering to use the unified component
  const renderWorkoutPreview = () => {
    console.log('renderWorkoutPreview called:', { workoutData, showPreview });
    if (!workoutData) return null;

    const content = renderWorkoutContent();

    return (
      <Dialog
        fullScreen={isMobile}
        open={showPreview}
        onClose={() => {
          console.log('Dialog onClose triggered');
          setShowPreview(false);
        }}
        PaperProps={{
          sx: {
            m: 2,
            maxHeight: '100%',
            height: isMobile ? '100%' : 'auto',
            borderRadius: isMobile ? 0 : '16px',
            bgcolor: colors.primary,
          }
        }}
      >
        <DialogContent sx={{ p: 3 }}>
          {content}
        </DialogContent>
      </Dialog>
    );
  };

  // Add helper function for pace calculation
  const calculatePace = (distance, duration) => {
    if (!distance || !duration) return '';
    
    // Parse duration (HH:MM:SS)
    const [hours = '0', minutes = '0', seconds = '0'] = duration.split(':');
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
    
    // Calculate pace in minutes per unit
    const paceMinutes = totalMinutes / distance;
    
    // Convert to MM:SS format
    const paceWholeMinutes = Math.floor(paceMinutes);
    const paceSeconds = Math.round((paceMinutes - paceWholeMinutes) * 60);
    
    return `${paceWholeMinutes}:${paceSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container maxWidth="lg" sx={{ pb: { xs: 10, md: 4 } }}>
      {/* Explanation section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          mb: 4,
          bgcolor: colors.subtle,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{
            fontWeight: 600,
            color: colors.text,
            mb: 2,
          }}
        >
          Log Completed Workout
        </Typography>
        <Typography 
          variant="body1"
          sx={{ 
            color: colors.muted,
            lineHeight: 1.6,
            mb: 2,
          }}
        >
          Use this page to log a workout you've already completed. Perfect for:
          • Uploading a photo of your workout
          • Typing out all your sets at once
          • Using voice to describe your entire workout
        </Typography>
        <Box sx={{ 
          p: 2, 
          bgcolor: colors.primary,
          borderRadius: '12px',
          border: `1px solid ${colors.border}`,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
          <FitnessCenter sx={{ color: colors.accent }} />
          <Typography variant="body1" sx={{ color: colors.text }}>
            Want to track your workout in real-time? Click "Start Active Workout" below!
          </Typography>
        </Box>
      </Paper>

      {/* Mobile Active Workout Button */}
      {isMobile && (
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => navigate('/active-workout')}
            sx={{ 
              bgcolor: colors.accent,
              color: colors.primary,
              fontWeight: 500,
              px: 4,
              py: 1.5,
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
          >
            Start Active Workout
          </Button>
        </Box>
      )}

      {/* Desktop Active Workout Button */}
      {!isMobile && (
        <Box sx={{ mb: 4 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 3 
          }}>
            <Typography variant="h6" sx={{ 
              color: colors.text,
              fontSize: '1.25rem',
              fontWeight: 500
            }}>
              Current Mode: {workoutType === 'strength' ? 'Gym Workout' : 'Cardio Workout'}
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate('/active-workout')}
              sx={{ 
                bgcolor: colors.accent,
                color: colors.primary,
                fontWeight: 500,
                px: 4,
                py: 1.5,
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: colors.accent,
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                },
              }}
            >
              Start Active Workout
            </Button>
          </Box>
        </Box>
      )}

      {/* Status Messages */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }}
          onClose={() => setSuccess('')}
        >
          {success}
        </Alert>
      )}

      {/* Workout Type Selection */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4,
          mb: 4,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 3,
            fontWeight: 500,
            color: colors.text,
          }}
        >
          Workout Type
        </Typography>
        <ToggleButtonGroup
          value={workoutType}
          exclusive
          onChange={handleWorkoutTypeChange}
          sx={{
            '& .MuiToggleButton-root': {
              border: `1px solid ${colors.border}`,
              borderRadius: '12px',
              px: 3,
              py: 1.5,
              color: colors.text,
              textTransform: 'none',
              '&.Mui-selected': {
                bgcolor: colors.accent,
                color: colors.primary,
                '&:hover': {
                  bgcolor: colors.accent,
                },
              },
              '&:hover': {
                bgcolor: colors.subtle,
              },
            },
          }}
        >
          <ToggleButton value="strength">Strength Training</ToggleButton>
          <ToggleButton value="cardio">Cardio</ToggleButton>
        </ToggleButtonGroup>
      </Paper>

      {/* Voice Input Section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4,
          mb: 4,
          bgcolor: isListening ? colors.accent : colors.primary,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
          transition: 'all 0.3s ease',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: isListening ? 2 : 0,
        }}>
          <Typography 
            variant="h6" 
            sx={{
              color: isListening ? colors.primary : colors.text,
              fontWeight: 500,
            }}
          >
            Voice Input
          </Typography>
          <Button
            variant="contained"
            onClick={isListening ? stopRecording : startRecording}
            sx={{
              bgcolor: isListening ? colors.primary : colors.accent,
              color: isListening ? colors.accent : colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              py: 1.5,
              px: 3,
              '&:hover': {
                bgcolor: isListening ? colors.primary : colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
            startIcon={isListening ? <MicOff /> : <Mic />}
          >
            {isListening ? 'Stop' : 'Start'} Recording
          </Button>
        </Box>
        {isListening && (
          <Typography 
            sx={{ 
              color: colors.primary,
              fontWeight: 300,
            }}
          >
            {transcript || 'Listening...'}
          </Typography>
        )}
      </Paper>

      {/* Manual Input Section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          height: '100%',
          borderRadius: '16px',
          border: `1px solid ${colors.border}`,
        }}
      >
        <Typography 
          variant="h6" 
          gutterBottom
          sx={{ 
            color: colors.text,
            fontWeight: 500,
          }}
        >
          Manual Input
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder={
            workoutType === 'strength'
              ? "e.g., '3 sets of squats at 225 lbs'"
              : "e.g., 'Ran 3 miles in 24 minutes'"
          }
          sx={{ 
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
          }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleTextSubmit}
          disabled={!inputText.trim()}
          startIcon={<Send />}
          sx={{
            bgcolor: colors.accent,
            color: colors.primary,
            borderRadius: '12px',
            textTransform: 'none',
            py: 1.5,
            '&:hover': {
              bgcolor: colors.accent,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
            },
            '&.Mui-disabled': {
              bgcolor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          Process Workout
        </Button>
      </Paper>

      {/* Image Upload Section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          height: '100%',
          borderRadius: '16px',
          border: `1px solid ${colors.border}`,
        }}
      >
        <Typography 
          variant="h6" 
          gutterBottom
          sx={{ 
            color: colors.text,
            fontWeight: 500,
          }}
        >
          Upload Screenshot
        </Typography>
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'block', 
            mb: 2,
            color: colors.muted,
          }}
        >
          Supported formats: JPG, PNG
        </Typography>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Selected workout"
              sx={{
                maxWidth: '100%',
                maxHeight: 200,
                objectFit: 'contain',
                borderRadius: '12px',
              }}
            />
          )}
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              py: 1.5,
              px: 4,
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
          >
            {selectedImage ? 'Change Image' : 'Upload Image'}
            <input
              type="file"
              hidden
              accept="image/jpeg,image/png"
              onChange={handleImageUpload}
              onClick={(e) => e.target.value = null}
            />
          </Button>
        </Box>
      </Paper>

      {/* Loading State */}
      {(loading || processingWorkout) && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1500,
            backdropFilter: 'blur(4px)',
          }}
        >
          <CircularProgress sx={{ color: colors.accent }} />
        </Box>
      )}

      {/* Render the workout preview */}
      {renderWorkoutPreview()}

      {/* Dialogs */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            bgcolor: colors.primary,
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          color: colors.text,
          fontWeight: 500,
        }}>
          Review Your Workout
        </DialogTitle>
        <DialogContent>
          <Typography 
            variant="body2" 
            sx={{ 
              mb: 2,
              color: colors.muted,
            }}
          >
            Review and edit your workout details below:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editedTranscript}
            onChange={(e) => setEditedTranscript(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setEditDialogOpen(false)}
            sx={{
              color: colors.muted,
              textTransform: 'none',
              '&:hover': {
                color: colors.text,
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmitTranscript} 
            variant="contained"
            sx={{
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
          >
            Process Workout
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WorkoutLogger;