import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Chip,
  Divider,
  useTheme,
  MenuItem,
} from '@mui/material';
import {
  PhotoCamera,
  Edit,
  Save,
  Restaurant,
  LocalDining,
  FreeBreakfast,
  Fastfood,
  Mic,
  MicOff,
  Send,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const Input = styled('input')({
  display: 'none',
});

const VoiceButton = styled(Button)(({ theme, islistening }) => ({
  height: islistening ? '120px' : '48px',
  transition: 'all 0.3s ease',
  background: islistening ? theme.palette.error.main : colors.accent,
  color: colors.primary,
  borderRadius: '12px',
  '&:hover': {
    background: islistening ? theme.palette.error.dark : colors.accent,
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
  },
}));

// Add new styled components for consistent look
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${colors.border}`,
  borderRadius: '16px',
  background: colors.primary,
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '&:hover fieldset': {
      borderColor: colors.accent,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.accent,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  textTransform: 'none',
  padding: '12px 24px',
  fontWeight: 500,
  '&.MuiButton-contained': {
    backgroundColor: colors.accent,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.accent,
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
    },
  },
}));

const MealLogger = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Add new state and ref for file input
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const fileInputRef = useRef(null);

  // Add voice recording states
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [showTranscriptDialog, setShowTranscriptDialog] = useState(false);
  const [editedTranscript, setEditedTranscript] = useState('');

  // State for input methods
  const [inputText, setInputText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // State for meal data
  const [mealData, setMealData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedMealData, setEditedMealData] = useState(null);

  // Add these to the state declarations at the top
  const [mealName, setMealName] = useState('');
  const [mealType, setMealType] = useState('lunch');
  const [mealDate, setMealDate] = useState(
    new Date().toLocaleDateString('en-CA')  // Format: YYYY-MM-DD in local timezone
  );

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let currentTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          currentTranscript += event.results[i][0].transcript;
        }
        setTranscript(currentTranscript);
        setEditedTranscript(currentTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setError('Speech recognition error: ' + event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        if (transcript) {
          setShowTranscriptDialog(true);
        }
      };

      setRecognition(recognition);
    } else {
      setError('Speech recognition is not supported in this browser');
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  // Voice recording handlers
  const startRecording = async () => {
    setError('');
    setTranscript('');
    setEditedTranscript('');
    
    if (recognition) {
      try {
        await recognition.start();
        setIsListening(true);
      } catch (err) {
        console.error('Error starting recognition:', err);
        setError('Could not start speech recognition');
      }
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      setShowTranscriptDialog(true);
    }
  };

  const handleAuthError = (error) => {
    if (error.status === 401) {
      alert('Your session has expired. Please sign in again.');
      navigate('/signin');
    } else if (error.status === 403) {
      alert('Your account is not active. Please contact support.');
      navigate('/signin');
    } else if (error.status === 404) {
      alert('User not found. Please sign in again.');
      navigate('/signin');
    }
  };

  // Update handleSubmitTranscript
  const handleSubmitTranscript = async () => {
    try {
      setLoading(true);
      setShowTranscriptDialog(false);

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const response = await fetch(`${BACKEND_URL}/process-voice-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          transcript: editedTranscript,
          meal_type: mealType,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || 'Failed to process voice input');
      }

      const data = await response.json();
      setMealData({
        foods: data.foods || [],
        total_nutrition: data.total_nutrition || {},
        meal_analysis: data.meal_analysis || {},
        health_insights: data.health_insights || {},
        rapidapi_analysis: data.rapidapi_analysis || {}
      });

      setSuccess('Voice input processed successfully!');
    } catch (error) {
      setError('Failed to process voice input: ' + error.message);
    } finally {
      setLoading(false);
      setTranscript('');
      setEditedTranscript('');
    }
  };

  // Update handleTextSubmit
  const handleTextSubmit = async () => {
    if (!inputText.trim()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const response = await fetch(`${BACKEND_URL}/process-text-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          text: inputText,
          meal_type: mealType,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || 'Failed to process meal text');
      }

      const data = await response.json();
      setMealData({
        foods: data.foods || [],
        total_nutrition: data.total_nutrition || {},
        meal_analysis: data.meal_analysis || {},
        health_insights: data.health_insights || {},
        rapidapi_analysis: data.rapidapi_analysis || {}
      });

      setInputText('');
      setSuccess('Meal processed successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Add image compression function
  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 0.1,          // Reduce to 100KB max
      maxWidthOrHeight: 800,    // Smaller max dimensions
      useWebWorker: true,
      fileType: 'image/jpeg',   // Force JPEG
      initialQuality: 0.7       // Lower initial quality
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('Original file size:', imageFile.size / 1024 / 1024, 'MB');
      console.log('Compressed file size:', compressedFile.size / 1024 / 1024, 'MB');
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw new Error('Failed to compress image');
    }
  };

  // Update handleImageUpload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    setError('');

    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      // Compress image before upload
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
      });

      const formData = new FormData();
      formData.append('file', compressedFile);

      const response = await fetch(`${BACKEND_URL}/analyzeFoodPlate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        if ([401, 403, 404].includes(response.status)) {
          handleAuthError({ status: response.status, ...errorData });
          return;
        }
        throw new Error(errorData.detail || 'Failed to analyze food plate');
      }

      const data = await response.json();
      setMealData(data);
      setSuccess('Image processed successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setFileInputKey(Date.now()); // Reset file input
    }
  };

  const handleFoodEdit = (index, field, value) => {
    if (!editMode) return;

    const updatedMealData = { ...mealData };
    const updatedFoods = [...updatedMealData.foods];
    const food = { ...updatedFoods[index] };

    // Update the specific field
    if (field === 'name' || field === 'portion') {
      food[field] = value;
    } else {
      // Handle nutrition fields
      if (!food.nutrition) food.nutrition = {};
      food.nutrition = { ...food.nutrition, [field]: Number(value) };
    }

    // Update the food in the array
    updatedFoods[index] = food;
    updatedMealData.foods = updatedFoods;

    // Recalculate total nutrition
    const totals = updatedFoods.reduce((acc, food) => ({
      calories: acc.calories + Number(food.nutrition?.calories || 0),
      protein: acc.protein + Number(food.nutrition?.protein || 0),
      carbs: acc.carbs + Number(food.nutrition?.carbs || 0),
      fats: acc.fats + Number(food.nutrition?.fats || 0)
    }), { calories: 0, protein: 0, carbs: 0, fats: 0 });

    updatedMealData.total_nutrition = totals;
    setMealData(updatedMealData);
  };

  // Handle saving meal
  const handleSaveMeal = async () => {
    try {
      setLoading(true);
      setError('');

      if (!mealData) {
        setError('No meal data to save');
        return;
      }

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const mealCreateData = {
        name: mealName || `Meal on ${new Date().toLocaleString()}`,
        meal_type: mealType,
        date: new Date(mealDate).toISOString(),
        total_nutrition: mealData.total_nutrition,
        foods: mealData.foods,
        meal_analysis: mealData.meal_analysis,
        health_insights: mealData.health_insights
      };

      const response = await fetch(`${BACKEND_URL}/save-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(mealCreateData)
      });

      if (!response.ok) {
        throw new Error('Failed to save meal');
      }

      setSuccess('Meal saved successfully!');
      
      // Reset ALL state variables
      setMealData(null);
      setSelectedImage(null);
      setInputText('');
      setMealName('');
      setMealType('lunch');
      setMealDate(new Date().toLocaleDateString('en-CA'));
      setTranscript('');
      setEditedTranscript('');
      
      // Force reset the file input
      setFileInputKey(Date.now());
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Clean up any object URLs
      if (selectedImage && selectedImage.startsWith('blob:')) {
        URL.revokeObjectURL(selectedImage);
      }
      
    } catch (error) {
      setError('Failed to save meal: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Render nutrition table
  const renderNutritionTable = (nutrition) => (
    <TableContainer component={Paper} variant="outlined" sx={{ mt: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nutrient</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(nutrition).map(([nutrient, value]) => (
            <TableRow key={nutrient}>
              <TableCell component="th" scope="row" sx={{ textTransform: 'capitalize' }}>
                {nutrient.replace('_', ' ')}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Render meal analysis
  const renderMealAnalysis = (analysis) => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Meal Analysis
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Meal Type
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {analysis.type}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Balance Score
            </Typography>
            <Typography variant="body1">
              {analysis.balance_score}/10
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Macronutrient Ratios
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Chip 
            label={`Protein: ${analysis.protein_ratio}%`}
            color="primary"
            variant="outlined"
          />
          <Chip 
            label={`Carbs: ${analysis.carb_ratio}%`}
            color="secondary"
            variant="outlined"
          />
          <Chip 
            label={`Fat: ${analysis.fat_ratio}%`}
            color="warning"
            variant="outlined"
          />
        </Box>
      </Box>
    </Box>
  );

  // Render health insights
  const renderHealthInsights = (insights) => {
    if (!insights) return null;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Health Insights
        </Typography>
        
        {insights.positive_aspects && insights.positive_aspects.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="success.main">
              Positive Aspects:
            </Typography>
            {insights.positive_aspects.map((aspect, index) => (
              <Typography key={index} variant="body2">
                • {aspect}
              </Typography>
            ))}
          </Box>
        )}

        {insights.areas_for_improvement && insights.areas_for_improvement.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="warning.main">
              Areas for Improvement:
            </Typography>
            {insights.areas_for_improvement.map((area, index) => (
              <Typography key={index} variant="body2">
                • {area}
              </Typography>
            ))}
          </Box>
        )}

        {insights.suggestions && insights.suggestions.length > 0 && (
          <Box>
            <Typography variant="subtitle2" color="info.main">
              Suggestions:
            </Typography>
            {insights.suggestions.map((suggestion, index) => (
              <Typography key={index} variant="body2">
                • {suggestion}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      {/* Explanation section */}
      <StyledPaper sx={{ mb: 4, bgcolor: colors.subtle }}>
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{
            fontWeight: 600,
            color: colors.text,
            mb: 2,
          }}
        >
          Log Your Meal
        </Typography>
        <Typography 
          variant="body1"
          sx={{ 
            color: colors.muted,
            lineHeight: 1.6,
            mb: 2,
          }}
        >
          Track your nutrition easily using:
          • Voice commands to describe your meal
          • Manual text input for detailed logging
          • Photo upload for quick meal analysis
        </Typography>
        <Box sx={{ 
          p: 2, 
          bgcolor: colors.primary,
          borderRadius: '12px',
          border: `1px solid ${colors.border}`,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
          <Restaurant sx={{ color: colors.accent }} />
          <Typography variant="body1" sx={{ color: colors.text }}>
            Get instant nutritional analysis and health insights for your meals
          </Typography>
        </Box>
      </StyledPaper>

      {/* Status Messages */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }}
          onClose={() => setSuccess('')}
        >
          {success}
        </Alert>
      )}

      {/* Input Methods Grid */}
      <Grid container spacing={3}>
        {/* Voice Input */}
        <Grid item xs={12}>
          <StyledPaper 
            sx={{ 
              bgcolor: isListening ? colors.accent : colors.primary,
              transition: 'all 0.3s ease',
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: isListening ? 2 : 0,
            }}>
              <Typography 
                variant="h6" 
                sx={{
                  color: isListening ? colors.primary : colors.text,
                  fontWeight: 500,
                }}
              >
                Voice Input
              </Typography>
              <VoiceButton
                variant="contained"
                onClick={isListening ? stopRecording : startRecording}
                islistening={isListening ? 1 : 0}
                startIcon={isListening ? <MicOff /> : <Mic />}
              >
                {isListening ? 'Stop Recording' : 'Start Recording'}
              </VoiceButton>
            </Box>
            {isListening && (
              <Typography 
                sx={{ 
                  color: colors.primary,
                  fontWeight: 300,
                  minHeight: '50px',
                }}
              >
                {transcript || 'Listening...'}
              </Typography>
            )}
          </StyledPaper>
        </Grid>

        {/* Manual Input */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: colors.text,
                fontWeight: 500,
                mb: 2,
              }}
            >
              Manual Input
            </Typography>
            <StyledTextField
              fullWidth
              multiline
              rows={4}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Describe your meal (e.g., '2 eggs, toast with butter, orange juice')"
              sx={{ mb: 2 }}
            />
            <StyledButton
              fullWidth
              variant="contained"
              onClick={handleTextSubmit}
              disabled={!inputText.trim() || loading}
              startIcon={<Send />}
            >
              Process Meal
            </StyledButton>
          </StyledPaper>
        </Grid>

        {/* Image Upload */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: colors.text,
                fontWeight: 500,
                mb: 2,
              }}
            >
              Upload Food Image
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}>
              {selectedImage && (
                <Box
                  component="img"
                  src={selectedImage}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: 200,
                    borderRadius: '12px',
                    objectFit: 'cover',
                  }}
                />
              )}
              <label htmlFor="meal-image-upload">
                <Input
                  accept="image/*"
                  id="meal-image-upload"
                  type="file"
                  onChange={handleImageUpload}
                  disabled={loading}
                  key={fileInputKey}
                  ref={fileInputRef}
                />
                <StyledButton
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                  disabled={loading}
                >
                  Upload Photo
                </StyledButton>
              </label>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Loading State */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1500,
            backdropFilter: 'blur(4px)',
          }}
        >
          <CircularProgress sx={{ color: colors.accent }} />
        </Box>
      )}

      {/* Edit Transcript Dialog */}
      <Dialog
        open={showTranscriptDialog}
        onClose={() => setShowTranscriptDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Review Your Meal Description</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editedTranscript}
            onChange={(e) => setEditedTranscript(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTranscriptDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmitTranscript} variant="contained">
            Process Meal
          </Button>
        </DialogActions>
      </Dialog>

      {/* Meal Data Display */}
      {mealData && (
        <StyledPaper sx={{ mt: 4 }}>
          {/* Meal Details Header */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 3,
                fontWeight: 600,
                color: colors.text,
              }}
            >
              Meal Details
            </Typography>
            
            <Grid container spacing={3}>
              {/* Meal Name */}
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label="Meal Name"
                  value={mealName}
                  onChange={(e) => setMealName(e.target.value)}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: colors.muted,
                    },
                  }}
                />
              </Grid>

              {/* Meal Type */}
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  select
                  label="Meal Type"
                  value={mealType}
                  onChange={(e) => setMealType(e.target.value)}
                >
                  <MenuItem value="breakfast">Breakfast</MenuItem>
                  <MenuItem value="lunch">Lunch</MenuItem>
                  <MenuItem value="dinner">Dinner</MenuItem>
                  <MenuItem value="snack">Snack</MenuItem>
                </StyledTextField>
              </Grid>

              {/* Date */}
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  type="date"
                  label="Date"
                  value={mealDate}
                  onChange={(e) => setMealDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Save Button */}
              <Grid item xs={12}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  onClick={handleSaveMeal}
                  disabled={loading}
                  startIcon={<Save />}
                  sx={{ mt: 2 }}
                >
                  Save Meal
                </StyledButton>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ 
            my: 4,
            borderColor: colors.border,
          }} />

          {/* Nutritional Information */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: colors.text,
                fontWeight: 500,
              }}
            >
              Nutritional Information
              <IconButton 
                size="small"
                onClick={() => {
                  if (!editMode && mealData) {
                    setEditedMealData({
                      ...mealData,
                      total_nutrition: { ...mealData.total_nutrition }
                    });
                  }
                  setEditMode(!editMode);
                }}
                sx={{ 
                  color: colors.accent,
                  '&:hover': {
                    backgroundColor: colors.subtle,
                  }
                }}
              >
                {editMode ? <Save /> : <Edit />}
              </IconButton>
            </Typography>

            <Grid container spacing={2}>
              {['calories', 'protein', 'carbs', 'fats'].map((nutrient) => (
                <Grid item xs={12} sm={6} md={3} key={nutrient}>
                  <StyledTextField
                    fullWidth
                    label={nutrient.charAt(0).toUpperCase() + nutrient.slice(1)}
                    type="number"
                    value={
                      editMode && editedMealData?.total_nutrition
                        ? editedMealData.total_nutrition[nutrient] || 0
                        : mealData?.total_nutrition?.[nutrient] || 0
                    }
                    onChange={(e) => {
                      if (editMode) {
                        setEditedMealData({
                          ...editedMealData,
                          total_nutrition: {
                            ...(editedMealData?.total_nutrition || {}),
                            [nutrient]: Number(e.target.value)
                          }
                        });
                      }
                    }}
                    disabled={!editMode}
                    size="small"
                    InputProps={{
                      endAdornment: nutrient === 'calories' ? null : (
                        <Typography variant="caption" sx={{ color: colors.muted }}>g</Typography>
                      )
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Foods List */}
          {mealData?.foods && (
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 3,
                  color: colors.text,
                  fontWeight: 500,
                }}
              >
                Foods
              </Typography>
              {mealData.foods.map((food, index) => (
                <StyledPaper 
                  key={index} 
                  sx={{ 
                    mb: 2,
                    bgcolor: colors.subtle,
                  }}
                >
                  {editMode ? (
                    <Box sx={{ mb: 1 }}>
                      <StyledTextField
                        fullWidth
                        label="Food Name"
                        value={food.name}
                        onChange={(e) => handleFoodEdit(index, 'name', e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <StyledTextField
                        fullWidth
                        label="Portion"
                        value={food.portion}
                        onChange={(e) => handleFoodEdit(index, 'portion', e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Grid container spacing={2}>
                        {Object.entries(food.nutrition).map(([key, value]) => (
                          <Grid item xs={6} key={key}>
                            <StyledTextField
                              fullWidth
                              label={key.charAt(0).toUpperCase() + key.slice(1)}
                              value={value}
                              onChange={(e) => handleFoodEdit(index, key, e.target.value)}
                              type="number"
                              size="small"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      <Typography 
                        variant="subtitle1"
                        sx={{
                          fontWeight: 500,
                          color: colors.text,
                          mb: 2,
                        }}
                      >
                        {food.name} - {food.portion}
                      </Typography>
                      <TableContainer 
                        component={Paper} 
                        variant="outlined" 
                        sx={{ 
                          borderRadius: '12px',
                          border: `1px solid ${colors.border}`,
                        }}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell 
                                sx={{ 
                                  fontWeight: 500,
                                  color: colors.text,
                                }}
                              >
                                Nutrient
                              </TableCell>
                              <TableCell 
                                align="right"
                                sx={{ 
                                  fontWeight: 500,
                                  color: colors.text,
                                }}
                              >
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(food.nutrition).map(([nutrient, value]) => (
                              <TableRow key={nutrient}>
                                <TableCell 
                                  component="th" 
                                  scope="row"
                                  sx={{ 
                                    textTransform: 'capitalize',
                                    color: colors.text,
                                  }}
                                >
                                  {nutrient.replace('_', ' ')}
                                </TableCell>
                                <TableCell 
                                  align="right"
                                  sx={{ color: colors.text }}
                                >
                                  {value}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </StyledPaper>
              ))}
            </Box>
          )}

          {/* Meal Analysis */}
          {mealData?.meal_analysis && (
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 3,
                  color: colors.text,
                  fontWeight: 500,
                }}
              >
                Meal Analysis
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledPaper sx={{ height: '100%' }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        color: colors.muted,
                        mb: 1,
                      }}
                    >
                      Meal Type
                    </Typography>
                    <Typography 
                      variant="h6"
                      sx={{ 
                        color: colors.text,
                        textTransform: 'capitalize',
                      }}
                    >
                      {mealData.meal_analysis.type}
                    </Typography>
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledPaper sx={{ height: '100%' }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        color: colors.muted,
                        mb: 1,
                      }}
                    >
                      Balance Score
                    </Typography>
                    <Typography 
                      variant="h6"
                      sx={{ color: colors.text }}
                    >
                      {mealData.meal_analysis.balance_score}/10
                    </Typography>
                  </StyledPaper>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 2,
                    color: colors.muted,
                  }}
                >
                  Macronutrient Ratios
                </Typography>
                <Box sx={{ 
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap'
                }}>
                  {[
                    { label: 'Protein', value: mealData.meal_analysis.protein_ratio, color: colors.accent },
                    { label: 'Carbs', value: mealData.meal_analysis.carb_ratio, color: '#4CAF50' },
                    { label: 'Fat', value: mealData.meal_analysis.fat_ratio, color: '#FF9800' }
                  ].map(({ label, value, color }) => (
                    <Chip
                      key={label}
                      label={`${label}: ${value}%`}
                      sx={{
                        borderRadius: '8px',
                        bgcolor: 'transparent',
                        border: `1px solid ${color}`,
                        color: color,
                        '&:hover': {
                          bgcolor: `${color}10`,
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          {/* Health Insights */}
          {mealData?.health_insights && (
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 3,
                  color: colors.text,
                  fontWeight: 500,
                }}
              >
                Health Insights
              </Typography>
              
              {/* Positive Aspects */}
              {mealData.health_insights.positive_aspects?.length > 0 && (
                <StyledPaper 
                  sx={{ 
                    mb: 2,
                    bgcolor: 'rgba(76, 175, 80, 0.03)',
                    border: '1px solid rgba(76, 175, 80, 0.1)',
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: '#2E7D32',
                      mb: 2,
                      fontWeight: 500,
                    }}
                  >
                    Positive Aspects
                  </Typography>
                  {mealData.health_insights.positive_aspects.map((aspect, index) => (
                    <Typography 
                      key={index} 
                      variant="body2"
                      sx={{ 
                        mb: 1,
                        color: colors.text,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      • {aspect}
                    </Typography>
                  ))}
                </StyledPaper>
              )}

              {/* Areas for Improvement */}
              {mealData.health_insights.areas_for_improvement?.length > 0 && (
                <StyledPaper 
                  sx={{ 
                    mb: 2,
                    bgcolor: 'rgba(255, 152, 0, 0.03)',
                    border: '1px solid rgba(255, 152, 0, 0.1)',
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: '#ED6C02',
                      mb: 2,
                      fontWeight: 500,
                    }}
                  >
                    Areas for Improvement
                  </Typography>
                  {mealData.health_insights.areas_for_improvement.map((area, index) => (
                    <Typography 
                      key={index} 
                      variant="body2"
                      sx={{ 
                        mb: 1,
                        color: colors.text,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      • {area}
                    </Typography>
                  ))}
                </StyledPaper>
              )}

              {/* Suggestions */}
              {mealData.health_insights.suggestions?.length > 0 && (
                <StyledPaper 
                  sx={{ 
                    bgcolor: 'rgba(25, 118, 210, 0.03)',
                    border: '1px solid rgba(25, 118, 210, 0.1)',
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: '#1976D2',
                      mb: 2,
                      fontWeight: 500,
                    }}
                  >
                    Suggestions
                  </Typography>
                  {mealData.health_insights.suggestions.map((suggestion, index) => (
                    <Typography 
                      key={index} 
                      variant="body2"
                      sx={{ 
                        mb: 1,
                        color: colors.text,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      • {suggestion}
                    </Typography>
                  ))}
                </StyledPaper>
              )}
            </Box>
          )}
        </StyledPaper>
      )}
    </Container>
  );
};

export default MealLogger; 