import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Chip,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  ArrowBack,
  FitnessCenter,
  DirectionsRun,
  Schedule,
  LocalFireDepartment,
  Speed,
  Straighten,
  CalendarToday,
  Notes,
} from '@mui/icons-material';
import { format } from 'date-fns';

// Theme colors matching the dashboard
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF',
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)',
  border: 'rgba(0, 0, 0, 0.08)',
};

const DashboardWorkoutDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchWorkoutDetails();
  }, [id]);

  const fetchWorkoutDetails = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('No auth token found');
      }

      // First fetch the workout details
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/workouts/${id}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch workout details');
      }

      const workoutData = await response.json();
      
      // For each exercise, fetch its sets
      if (workoutData.exercises) {
        for (const exercise of workoutData.exercises) {
          if (exercise.exercise_type === 'cardio') {
            // Fetch cardio sets
            const cardioSetsResponse = await fetch(
              `${process.env.REACT_APP_BACKEND_API_URL}/exercises/${exercise.id}/cardio-sets`,
              {
                headers: { 'Authorization': `Bearer ${authToken}` }
              }
            );
            if (cardioSetsResponse.ok) {
              const cardioSets = await cardioSetsResponse.json();
              exercise.cardio_sets = cardioSets.sort((a, b) => a.set_number - b.set_number);
            }
          } else {
            // Fetch strength sets
            const setsResponse = await fetch(
              `${process.env.REACT_APP_BACKEND_API_URL}/exercises/${exercise.id}/sets`,
              {
                headers: { 'Authorization': `Bearer ${authToken}` }
              }
            );
            if (setsResponse.ok) {
              const sets = await setsResponse.json();
              exercise.sets_data = sets.sort((a, b) => a.set_number - b.set_number);
            }
          }
        }
      }

      setWorkout(workoutData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0m';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatPace = (durationSeconds, distance, distanceUnit = 'meters') => {
    if (!durationSeconds || !distance) return '-';
    
    // Convert everything to miles pace
    let distanceInMiles;
    switch (distanceUnit) {
      case 'meters':
        distanceInMiles = distance / 1609.34;
        break;
      case 'kilometers':
      case 'km':
        distanceInMiles = distance / 1.60934;
        break;
      case 'miles':
        distanceInMiles = distance;
        break;
      default:
        distanceInMiles = distance / 1609.34; // default to meters
    }
    
    const paceInSeconds = durationSeconds / distanceInMiles;
    const paceMinutes = Math.floor(paceInSeconds / 60);
    const paceSeconds = Math.round(paceInSeconds % 60);
    return `${paceMinutes}:${paceSeconds.toString().padStart(2, '0')} min/mile`;
  };

  const renderStrengthWorkoutDetails = (workout) => {
    // Group exercises by name to show progression
    const exerciseGroups = workout.exercises?.reduce((groups, exercise) => {
      if (!groups[exercise.name]) {
        groups[exercise.name] = [];
      }
      groups[exercise.name].push(exercise);
      return groups;
    }, {});

    return (
      <Box>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: `1px solid ${colors.border}`,
                borderRadius: '12px',
                bgcolor: colors.subtle,
              }}
            >
              <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                Total Volume
              </Typography>
              <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                {workout.total_volume?.toLocaleString() || 0} lbs
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: `1px solid ${colors.border}`,
                borderRadius: '12px',
                bgcolor: colors.subtle,
              }}
            >
              <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                Total Sets
              </Typography>
              <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                {workout.total_sets || 0}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: `1px solid ${colors.border}`,
                borderRadius: '12px',
                bgcolor: colors.subtle,
              }}
            >
              <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                Duration
              </Typography>
              <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                {formatDuration(workout.duration)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: `1px solid ${colors.border}`,
                borderRadius: '12px',
                bgcolor: colors.subtle,
              }}
            >
              <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                Exercises
              </Typography>
              <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                {Object.keys(exerciseGroups || {}).length}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Exercise Details */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ color: colors.text, fontWeight: 500, mb: 2 }}>
            Exercises
          </Typography>
          <List>
            {Object.entries(exerciseGroups || {}).map(([exerciseName, exercises]) => (
              <Box key={exerciseName} sx={{ mb: 3 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ color: colors.text, fontWeight: 500, mb: 2 }}
                >
                  {exerciseName}
                </Typography>
                {exercises.flatMap(exercise => 
                  exercise.sets_detail?.map((set, setIndex) => (
                    <ListItem
                      key={`${exercise.id}-${setIndex}`}
                      sx={{
                        bgcolor: colors.subtle,
                        borderRadius: '8px',
                        mb: 1,
                      }}
                    >
                      <ListItemIcon>
                        <FitnessCenter sx={{ color: colors.accent }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Set ${set.set_number}`}
                        secondary={
                          <Typography variant="body2" sx={{ color: colors.muted }}>
                            {set.reps} reps × {set.weight} {set.unit || 'lbs'}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )) || []
                )}
              </Box>
            ))}
          </List>
        </Box>

        {/* Notes Section */}
        {workout.notes && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: colors.text, fontWeight: 500, mb: 2 }}>
              Notes
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: `1px solid ${colors.border}`,
                borderRadius: '12px',
                bgcolor: colors.subtle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Notes sx={{ color: colors.muted, mr: 2, mt: 0.5 }} />
                <Typography variant="body1" sx={{ color: colors.text }}>
                  {workout.notes}
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    );
  };

  const renderCardioWorkoutDetails = (workout) => {
    // Calculate totals for cardio workout
    const calculateCardioTotals = (exercises) => {
      let totalDistance = 0;
      let totalDuration = 0;
      let sets = [];
      let fastestPace = Infinity;
      let fastestInterval = null;
      let isIntervalWorkout = false;

      exercises.forEach(exercise => {
        if (exercise.cardio_sets) {
          // Check if this is an interval workout by looking at the first set's distance
          const firstSet = exercise.cardio_sets[0];
          if (firstSet && exercise.cardio_sets.length > 1 && 
              exercise.cardio_sets.every(set => set.distance === firstSet.distance)) {
            isIntervalWorkout = true;
          }

          exercise.cardio_sets.forEach(set => {
            totalDistance += set.distance;
            totalDuration += set.duration;
            sets.push(set);
            
            // Calculate pace for this interval
            const currentPace = set.duration / (set.distance / 1609.34); // Convert to mile pace
            if (currentPace < fastestPace) {
              fastestPace = currentPace;
              fastestInterval = set;
            }
          });
        }
      });

      return {
        totalDistance,
        totalDuration,
        sets,
        averagePace: totalDistance && totalDuration ? formatPace(totalDuration, totalDistance, 'meters') : '-',
        fastestInterval: fastestInterval ? {
          number: fastestInterval.set_number,
          pace: formatPace(fastestInterval.duration, fastestInterval.distance, fastestInterval.distance_unit),
          duration: formatDuration(fastestInterval.duration)
        } : null,
        intervalDistance: sets[0]?.distance || 0,
        intervalUnit: sets[0]?.distance_unit || 'meters',
        isIntervalWorkout
      };
    };

    const cardioTotals = workout.type === 'cardio' ? calculateCardioTotals(workout.exercises || []) : null;

    return (
      <Box>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {workout.type === 'cardio' && cardioTotals && (
            <>
              <Grid item xs={12} sm={3}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    border: `1px solid ${colors.border}`,
                    borderRadius: '12px',
                    bgcolor: colors.subtle,
                  }}
                >
                  <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                    Interval Distance
                  </Typography>
                  <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                    {cardioTotals.intervalDistance}{cardioTotals.intervalUnit}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    border: `1px solid ${colors.border}`,
                    borderRadius: '12px',
                    bgcolor: colors.subtle,
                  }}
                >
                  <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                    Intervals Completed
                  </Typography>
                  <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                    {cardioTotals.sets.length}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    border: `1px solid ${colors.border}`,
                    borderRadius: '12px',
                    bgcolor: colors.subtle,
                  }}
                >
                  <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                    Fastest Interval
                  </Typography>
                  <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                    {cardioTotals.fastestInterval ? cardioTotals.fastestInterval.pace : '-'}
                  </Typography>
                  {cardioTotals.fastestInterval && (
                    <Typography variant="caption" sx={{ color: colors.muted }}>
                      Interval {cardioTotals.fastestInterval.number} • {cardioTotals.fastestInterval.duration}
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    border: `1px solid ${colors.border}`,
                    borderRadius: '12px',
                    bgcolor: colors.subtle,
                  }}
                >
                  <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                    Average Pace
                  </Typography>
                  <Typography variant="h5" sx={{ color: colors.text, fontWeight: 500 }}>
                    {cardioTotals.averagePace}
                  </Typography>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>

        {/* Cardio Sets */}
        {workout.type === 'cardio' && workout.exercises && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: colors.text, fontWeight: 500, mb: 2 }}>
              Activities
            </Typography>
            <List>
              {workout.exercises.map((exercise, exerciseIndex) => (
                exercise.cardio_sets && exercise.cardio_sets.map((set, setIndex) => (
                  <ListItem
                    key={`${exerciseIndex}-${setIndex}`}
                    sx={{
                      bgcolor: colors.subtle,
                      borderRadius: '8px',
                      mb: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: { xs: 'flex-start', sm: 'center' },
                      gap: 2,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 120 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <DirectionsRun sx={{ color: colors.accent }} />
                      </ListItemIcon>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Activity {set.set_number}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Straighten sx={{ color: colors.muted, mr: 1, fontSize: '1.2rem' }} />
                        <Typography>
                          {set.distance}{set.distance_unit}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Schedule sx={{ color: colors.muted, mr: 1, fontSize: '1.2rem' }} />
                        <Typography>
                          {formatDuration(set.duration)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Speed sx={{ color: colors.muted, mr: 1, fontSize: '1.2rem' }} />
                        <Typography>
                          {formatPace(set.duration, set.distance, set.distance_unit)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))
              ))}
            </List>
          </Box>
        )}
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress sx={{ color: colors.accent }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert 
        severity="error" 
        sx={{ m: 3, borderRadius: '12px' }}
        onClose={() => setError('')}
      >
        {error}
      </Alert>
    );
  }

  if (!workout) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ color: colors.muted }}>
          Workout not found
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton 
          onClick={() => navigate(-1)}
          sx={{ mr: 2, color: colors.text }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 600, color: colors.text }}>
          Workout Details
        </Typography>
      </Box>

      {/* Main Content */}
      <Paper
        elevation={0}
        sx={{
          p: 3,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        {/* Workout Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 600, color: colors.text, mb: 1 }}>
              {workout.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarToday sx={{ fontSize: 20, color: colors.muted }} />
              <Typography variant="body1" sx={{ color: colors.muted }}>
                {format(new Date(workout.date), 'MMMM d, yyyy')}
              </Typography>
            </Box>
          </Box>
          <Chip
            icon={workout.type === 'strength' ? <FitnessCenter /> : <DirectionsRun />}
            label={workout.type === 'strength' ? 'Strength' : 'Cardio'}
            sx={{
              bgcolor: colors.subtle,
              color: colors.accent,
              borderRadius: '8px',
              px: 1,
            }}
          />
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Render appropriate content based on workout type */}
        {workout.type === 'strength' ? renderStrengthWorkoutDetails(workout) : renderCardioWorkoutDetails(workout)}
      </Paper>
    </Box>
  );
};

export default DashboardWorkoutDetails;
