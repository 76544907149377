import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import {
  TrendingUp,
  Camera,
  CalendarToday,
  BarChart,
  Timeline,
  FitnessCenter,
  Scale,
} from '@mui/icons-material';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';

const DashboardProgress = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [timeframe, setTimeframe] = useState('month');

  // Mock data for visualizations
  const weightData = [
    { x: 'Week 1', y: 185 },
    { x: 'Week 2', y: 183 },
    { x: 'Week 3', y: 182 },
    { x: 'Week 4', y: 180 },
  ];

  const strengthData = [
    {
      exercise: 'Bench Press',
      history: [
        { date: '2024-01-01', weight: 185, reps: 8 },
        { date: '2024-01-08', weight: 190, reps: 8 },
        { date: '2024-01-15', weight: 195, reps: 8 },
      ]
    },
    // Add more exercises...
  ];

  // Progress Chart Component
  const ProgressChart = ({ data, yAxisLabel }) => (
    <Box sx={{ height: 300 }}>
      <ResponsiveLine
        data={[
          {
            id: 'progress',
            data: data
          }
        ]}
        margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        curve="monotoneX"
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisLabel,
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        enableArea={true}
        areaOpacity={0.15}
        enableGridX={false}
        colors={[theme.palette.primary.main]}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: theme.palette.text.secondary
              }
            }
          },
          grid: {
            line: {
              stroke: theme.palette.divider
            }
          }
        }}
      />
    </Box>
  );

  // Progress Photos Section
  const ProgressPhotos = () => (
    <Grid container spacing={2}>
      {[...Array(3)].map((_, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Paper 
            sx={{ 
              p: 1, 
              aspectRatio: '1/1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'action.hover',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.selected'
              }
            }}
          >
            <Camera sx={{ fontSize: 40, color: 'text.secondary', mb: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Add Progress Photo
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  // Strength Progress Table
  const StrengthTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Exercise</TableCell>
            <TableCell align="right">Previous</TableCell>
            <TableCell align="right">Current</TableCell>
            <TableCell align="right">Change</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {strengthData.map((exercise) => {
            const latest = exercise.history[exercise.history.length - 1];
            const previous = exercise.history[exercise.history.length - 2];
            const change = latest.weight - previous.weight;

            return (
              <TableRow key={exercise.exercise}>
                <TableCell component="th" scope="row">
                  {exercise.exercise}
                </TableCell>
                <TableCell align="right">
                  {previous.weight}lbs × {previous.reps}
                </TableCell>
                <TableCell align="right">
                  {latest.weight}lbs × {latest.reps}
                </TableCell>
                <TableCell 
                  align="right"
                  sx={{ 
                    color: change > 0 ? 'success.main' : 'error.main',
                    fontWeight: 'bold'
                  }}
                >
                  {change > 0 ? '+' : ''}{change}lbs
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5">Progress Tracking</Typography>
        <Box>
          {['week', 'month', 'year'].map((tf) => (
            <Button
              key={tf}
              variant={timeframe === tf ? 'contained' : 'outlined'}
              size="small"
              onClick={() => setTimeframe(tf)}
              sx={{ ml: 1 }}
            >
              {tf.charAt(0).toUpperCase() + tf.slice(1)}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Progress Navigation */}
      <Tabs 
        value={activeTab} 
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab icon={<Scale />} label="Weight" />
        <Tab icon={<FitnessCenter />} label="Strength" />
        <Tab icon={<Camera />} label="Photos" />
        <Tab icon={<BarChart />} label="Measurements" />
      </Tabs>

      {/* Tab Content */}
      <Box>
        {activeTab === 0 && (
          <Paper sx={{ p: 3 }}>
            <ProgressChart data={weightData} yAxisLabel="Weight (lbs)" />
          </Paper>
        )}
        {activeTab === 1 && (
          <Paper sx={{ p: 3 }}>
            <StrengthTable />
          </Paper>
        )}
        {activeTab === 2 && (
          <Paper sx={{ p: 3 }}>
            <ProgressPhotos />
          </Paper>
        )}
        {activeTab === 3 && (
          <Paper sx={{ p: 3 }}>
            {/* Measurements implementation */}
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default DashboardProgress; 