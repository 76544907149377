import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Chip,
  Grid,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@mui/material';
import {
  FitnessCenter,
  DirectionsRun,
  Schedule,
  LocalFireDepartment,
  Speed,
  Straighten,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { format } from 'date-fns';

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const DashboardWorkouts = () => {
  const navigate = useNavigate();
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'strength', 'cardio'
  const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for newest first, 'asc' for oldest first
  const [expandedWorkouts, setExpandedWorkouts] = useState({});

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('No auth token found');
      }

      // Only fetch basic workout history
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/workout-history`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch workouts');
      }

      const data = await response.json();
      setWorkouts(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const handleSortChange = () => {
    setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc');
  };

  const toggleWorkoutExpansion = (workoutId) => {
    setExpandedWorkouts(prev => ({
      ...prev,
      [workoutId]: !prev[workoutId]
    }));
  };

  const handleWorkoutClick = (workoutId) => {
    navigate(`/dashboard-workout-details/${workoutId}`);
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0m';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  };

  const formatPace = (paceSeconds, unit = 'mile') => {
    if (!paceSeconds) return '-';
    const minutes = Math.floor(paceSeconds / 60);
    const seconds = paceSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')} /${unit}`;
  };

  const renderStrengthWorkout = (workout) => {
    return (
      <Card
        elevation={0}
        onClick={() => handleWorkoutClick(workout.id)}
        sx={{
          mb: 2,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
          transition: 'all 0.2s ease',
          cursor: 'pointer',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
          },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 500, color: colors.text }}>
                {workout.name}
              </Typography>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                {format(new Date(workout.date), 'MMM d, yyyy')}
              </Typography>
              {workout.workout_split && (
                <Chip
                  label={workout.workout_split.toUpperCase()}
                  size="small"
                  sx={{
                    mt: 1,
                    bgcolor: colors.subtle,
                    color: colors.accent,
                    borderRadius: '8px',
                  }}
                />
              )}
            </Box>
            <Chip
              icon={<FitnessCenter />}
              label="Strength"
              size="small"
              sx={{
                bgcolor: colors.subtle,
                color: colors.accent,
                borderRadius: '8px',
              }}
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Total Volume
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {workout.total_volume?.toLocaleString() || 0} lbs
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Total Sets
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {workout.total_sets || 0}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Duration
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {formatDuration(workout.duration)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Exercises
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {workout.exercises?.length || 0}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderCardioWorkout = (workout) => {
    const isExpanded = expandedWorkouts[workout.id];

    return (
      <Card
        elevation={0}
        onClick={() => handleWorkoutClick(workout.id)}
        sx={{
          mb: 2,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
          transition: 'all 0.2s ease',
          cursor: 'pointer',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
          },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 500, color: colors.text }}>
                {workout.name}
              </Typography>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                {format(new Date(workout.date), 'MMM d, yyyy')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                icon={<DirectionsRun />}
                label="Cardio"
                size="small"
                sx={{
                  bgcolor: colors.subtle,
                  color: colors.accent,
                  borderRadius: '8px',
                }}
              />
              <IconButton
                onClick={() => toggleWorkoutExpansion(workout.id)}
                size="small"
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Box>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Distance
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {workout.distance} {workout.cardio_distance_unit}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Duration
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {formatDuration(workout.duration)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Pace
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {formatPace(workout.pace, workout.cardio_distance_unit)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Calories
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text }}>
                {workout.calories_burned || 0}
              </Typography>
            </Grid>
          </Grid>

          {isExpanded && workout.notes && (
            <>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1, color: colors.text }}>
                  Notes
                </Typography>
                <Typography variant="body2" sx={{ color: colors.muted }}>
                  {workout.notes}
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    );
  };

  const filteredWorkouts = workouts
    .filter(workout => {
      if (filter === 'all') return true;
      return workout.type === filter;
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress sx={{ color: colors.accent }} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3, borderRadius: '12px' }}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 600, color: colors.text }}>
            Workout History
          </Typography>
          <Tooltip title={sortOrder === 'desc' ? 'Show oldest first' : 'Show newest first'}>
            <IconButton 
              onClick={handleSortChange}
              sx={{ 
                color: colors.accent,
                '&:hover': { bgcolor: colors.subtle }
              }}
            >
              {sortOrder === 'desc' ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          </Tooltip>
        </Box>
        
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          sx={{ mb: 3 }}
        >
          <ToggleButton 
            value="all"
            sx={{
              px: 3,
              py: 1,
              borderRadius: '12px',
              '&.Mui-selected': {
                bgcolor: colors.accent,
                color: colors.primary,
                '&:hover': {
                  bgcolor: colors.accent,
                },
              },
            }}
          >
            All Workouts
          </ToggleButton>
          <ToggleButton 
            value="strength"
            sx={{
              px: 3,
              py: 1,
              borderRadius: '12px',
              '&.Mui-selected': {
                bgcolor: colors.accent,
                color: colors.primary,
                '&:hover': {
                  bgcolor: colors.accent,
                },
              },
            }}
          >
            Strength
          </ToggleButton>
          <ToggleButton 
            value="cardio"
            sx={{
              px: 3,
              py: 1,
              borderRadius: '12px',
              '&.Mui-selected': {
                bgcolor: colors.accent,
                color: colors.primary,
                '&:hover': {
                  bgcolor: colors.accent,
                },
              },
            }}
          >
            Cardio
          </ToggleButton>
        </ToggleButtonGroup>

        {filteredWorkouts.length === 0 ? (
          <Paper
            elevation={0}
            sx={{
              p: 4,
              textAlign: 'center',
              border: `1px solid ${colors.border}`,
              borderRadius: '16px',
              bgcolor: colors.subtle,
            }}
          >
            <Typography variant="body1" sx={{ color: colors.muted }}>
              No workouts found. Start logging your workouts to see them here!
            </Typography>
          </Paper>
        ) : (
          filteredWorkouts.map(workout => (
            workout.type === 'strength' 
              ? renderStrengthWorkout(workout)
              : renderCardioWorkout(workout)
          ))
        )}
      </Box>
    </Box>
  );
};

export default DashboardWorkouts;
