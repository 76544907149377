import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedToken = localStorage.getItem('authToken');
    const storedSessionToken = localStorage.getItem('sessionToken');
    const storedUserId = localStorage.getItem('userId');
    return storedToken ? { token: storedToken, sessionToken: storedSessionToken, id: storedUserId } : null;
  });
  const [goals, setGoals] = useState([]);
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [goalsError, setGoalsError] = useState(null);
  
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('userId');
    setUser(null);
    setGoals([]);
    navigate('/Signin');
  };

  const fetchGoals = async () => {
    if (!user?.token) return;
    
    try {
      setGoalsLoading(true);
      setGoalsError(null);
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          logout();
          return;
        }
        throw new Error('Failed to fetch goals');
      }

      const data = await response.json();
      setGoals(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching goals:', error);
      setGoalsError(error.message);
      setGoals([]);
    } finally {
      setGoalsLoading(false);
    }
  };

  const createGoal = async (goalData) => {
    if (!user?.token) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(goalData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to create goal');
      }
      
      // Refresh goals after creation
      await fetchGoals();
      return true;
    } catch (error) {
      console.error('Error creating goal:', error);
      throw error;
    }
  };

  const updateGoal = async (goalId, goalData) => {
    if (!user?.token) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals/${goalId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(goalData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to update goal');
      }
      
      // Refresh goals after update
      await fetchGoals();
      return true;
    } catch (error) {
      console.error('Error updating goal:', error);
      throw error;
    }
  };

  const deleteGoal = async (goalId) => {
    if (!user?.token) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals/${goalId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete goal');
      }
      
      // Refresh goals after deletion
      await fetchGoals();
      return true;
    } catch (error) {
      console.error('Error deleting goal:', error);
      throw error;
    }
  };

  // Fetch goals whenever user changes
  useEffect(() => {
    if (user?.token) {
      fetchGoals();
    }
  }, [user?.token]);

  const refreshToken = async () => {
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/refresh-token`, null, {
        params: { session_token: sessionToken }
      });

      const { access_token, session_token: newSessionToken } = response.data;
      
      localStorage.setItem('authToken', access_token);
      localStorage.setItem('sessionToken', newSessionToken);
      
      setUser(prev => ({
        ...prev,
        token: access_token,
        sessionToken: newSessionToken
      }));

      return access_token;
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();
      throw error;
    }
  };

  useEffect(() => {
    // Create axios interceptor for automatic token refresh
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If the error is 401 and we haven't tried to refresh the token yet
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // Attempt to refresh the token
            const newToken = await refreshToken();
            
            // Update the failed request with the new token
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            
            // Retry the original request
            return axios(originalRequest);
          } catch (refreshError) {
            // If refresh fails, logout and redirect to login
            logout();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      logout, 
      refreshToken,
      goals,
      goalsLoading,
      goalsError,
      fetchGoals,
      createGoal,
      updateGoal,
      deleteGoal
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext;