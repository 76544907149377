import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
  Tooltip,
  Chip,
  useTheme,
} from '@mui/material';
import {
  TrendingUp,
  TrendingDown,
  Info,
  BarChart,
  PieChart,
  Timeline,
  CalendarMonth,
  Speed,
} from '@mui/icons-material';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveHeatMap } from '@nivo/heatmap';

const DashboardAnalytics = () => {
  const theme = useTheme();
  const [timeframe, setTimeframe] = useState('month');

  // Insight Card Component
  const InsightCard = ({ title, value, change, trend, info }) => (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography color="text.secondary" variant="body2">
            {title}
          </Typography>
          <Tooltip title={info}>
            <Info sx={{ color: 'text.secondary', fontSize: 18 }} />
          </Tooltip>
        </Box>
        <Typography variant="h4" gutterBottom>
          {value}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {trend === 'up' ? (
            <TrendingUp sx={{ color: 'success.main', mr: 1 }} />
          ) : (
            <TrendingDown sx={{ color: 'error.main', mr: 1 }} />
          )}
          <Typography
            variant="body2"
            color={trend === 'up' ? 'success.main' : 'error.main'}
          >
            {change}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  // Performance Score Component
  const PerformanceScore = () => (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Speed sx={{ mr: 2, color: theme.palette.primary.main }} />
        <Typography variant="h6">Performance Score</Typography>
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            width: '100%',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Circular Progress Implementation */}
          <Typography variant="h2" color="primary">
            85
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {[
            { label: 'Workout Consistency', score: 90 },
            { label: 'Nutrition Adherence', score: 82 },
            { label: 'Recovery Quality', score: 85 },
          ].map((metric) => (
            <Grid item xs={12} key={metric.label}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {metric.label}
                </Typography>
                <Typography variant="body2" color="primary">
                  {metric.score}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: 4,
                  bgcolor: 'grey.100',
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    width: `${metric.score}%`,
                    height: '100%',
                    bgcolor: 'primary.main',
                    borderRadius: 2,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );

  // Workout Heatmap
  const WorkoutHeatmap = () => (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Activity Patterns
      </Typography>
      <Box sx={{ height: 300 }}>
        <ResponsiveHeatMap
          data={[/* Heatmap data implementation */]}
          margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: 'Week Day',
            legendPosition: 'middle',
            legendOffset: 36
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Hour',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          colors={{
            type: 'sequential',
            scheme: 'blues'
          }}
        />
      </Box>
    </Paper>
  );

  return (
    <Box>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5">Analytics & Insights</Typography>
        <Box>
          {['week', 'month', 'year'].map((tf) => (
            <Button
              key={tf}
              variant={timeframe === tf ? 'contained' : 'outlined'}
              size="small"
              onClick={() => setTimeframe(tf)}
              sx={{ ml: 1 }}
            >
              {tf.charAt(0).toUpperCase() + tf.slice(1)}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Quick Insights */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <InsightCard
            title="Workout Frequency"
            value="4.2"
            change="+0.8 vs last week"
            trend="up"
            info="Average workouts per week"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InsightCard
            title="Avg. Workout Duration"
            value="52m"
            change="-3m vs last week"
            trend="down"
            info="Average time per workout"
          />
        </Grid>
        {/* Add more insight cards */}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <WorkoutHeatmap />
        </Grid>
        <Grid item xs={12} md={4}>
          <PerformanceScore />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardAnalytics; 