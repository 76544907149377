import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardMedia,
  Chip,
  InputAdornment,
  LinearProgress,
  Stack,
  Divider,
  Menu,
  MenuItem,
  CircularProgress,
  Tooltip,
  Select,
} from '@mui/material';
import {
  Search,
  FilterList,
  Add as AddIcon,
  MoreVert,
  AccessTime,
  Star,
  ArrowBack,
  ArrowForward,
  Sort,
} from '@mui/icons-material';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const DashboardNutrition = () => {
  const navigate = useNavigate();
  const [meals, setMeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('calories');
  const [filteredMeals, setFilteredMeals] = useState([]);

  useEffect(() => {
    fetchMeals();
  }, []);

  useEffect(() => {
    filterMeals();
  }, [activeTab, meals, searchQuery]);

  const filterMeals = () => {
    let filtered = [...meals];
    
    // Filter by meal type based on active tab
    if (activeTab !== 0) { // 0 is "All"
      const mealTypes = ['all', 'breakfast', 'lunch', 'dinner', 'snack'];
      filtered = filtered.filter(meal => meal.meal_type.toLowerCase() === mealTypes[activeTab]);
    }

    // Apply search filter if query exists
    if (searchQuery) {
      filtered = filtered.filter(meal => 
        meal.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      if (sortBy === 'calories') return b.calories - a.calories;
      if (sortBy === 'protein') return b.protein - a.protein;
      if (sortBy === 'date') return new Date(b.date) - new Date(a.date);
      return 0;
    });

    setFilteredMeals(filtered);
  };

  const fetchMeals = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/dashboard-nutrition/meals`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      const data = await response.json();
      setMeals(data);
      setFilteredMeals(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching meals:', error);
      setLoading(false);
    }
  };

  const MealCard = ({ meal }) => {
    const navigate = useNavigate();

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Chip 
              label={meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}
              size="small"
              sx={{ bgcolor: '#E8F5E9', color: '#2E7D32' }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => meal.id && navigate(`/dashboard-nutrition-details/${meal.id}`)}
                sx={{ 
                  borderColor: '#2E7D32',
                  color: '#2E7D32',
                  '&:hover': { borderColor: '#1B5E20', bgcolor: '#E8F5E9' },
                  textTransform: 'none'
                }}
              >
                View Details
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => navigate('/meal-logger')}
                sx={{ 
                  bgcolor: '#4CAF50',
                  '&:hover': { bgcolor: '#43A047' },
                  textTransform: 'none'
                }}
              >
                Add
              </Button>
            </Box>
          </Box>

          <Typography variant="h6" gutterBottom>{meal.name}</Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <AccessTime sx={{ fontSize: 16, mr: 0.5, color: 'text.secondary' }} />
            <Typography variant="body2" color="text.secondary">
              {new Date(meal.date).toLocaleDateString()}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body2" color="text.secondary">Calories</Typography>
              <Typography variant="subtitle1">{meal.calories}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" color="text.secondary">Protein</Typography>
              <Typography variant="subtitle1">{meal.protein}g</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" color="text.secondary">Carbs</Typography>
              <Typography variant="subtitle1">{meal.carbs}g</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" color="text.secondary">Fat</Typography>
              <Typography variant="subtitle1">{meal.fat}g</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const PopularMealCard = ({ meal }) => (
    <Card sx={{ display: 'flex', mb: 2, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, flexGrow: 1 }}>
        <Typography variant="subtitle2">{meal.name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
          <Chip 
            label={meal.meal_type}
            size="small"
            sx={{ 
              height: 20,
              fontSize: '0.7rem',
              bgcolor: '#E8F5E9',
              color: '#2E7D32'
            }}
          />
        </Box>
      </Box>
    </Card>
  );

  if (loading) {
    return (
      <Box 
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <CircularProgress sx={{ color: colors.accent }} />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      p: { xs: 3, md: 4 },
      bgcolor: colors.primary,
      maxWidth: '1200px',
      mx: 'auto'
    }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: { xs: 3, md: 4 }
      }}>
        <Box>
          <Typography 
            variant="h4" 
            sx={{ 
              fontSize: { xs: '1.5rem', md: '2rem' },
              fontWeight: 600,
              color: colors.text,
              mb: 1,
              letterSpacing: '-0.02em',
            }}
          >
            Nutrition Tracking
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: colors.muted,
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            Monitor your meals and nutritional intake
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon sx={{ fontSize: 24 }} />}
          onClick={() => navigate('/meal-logger')}
          sx={{
            height: 48,
            px: 3,
            bgcolor: colors.accent,
            color: colors.primary,
            borderRadius: '12px',
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              bgcolor: colors.accent,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
            },
            transition: 'all 0.2s ease',
          }}
        >
          Log Meal
        </Button>
      </Box>

      {/* Search and Filter Section */}
      <Paper 
        elevation={0}
        sx={{ 
          p: { xs: 2, md: 3 },
          mb: { xs: 3, md: 4 },
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            placeholder="Search meals"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': {
                  height: 48,
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                    <Search sx={{ color: colors.muted, fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              fullWidth
            sx={{
                height: 48,
                borderRadius: '12px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.border,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.accent,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.accent,
                },
              }}
            >
              <MenuItem value="calories">Calories</MenuItem>
              <MenuItem value="protein">Protein</MenuItem>
              <MenuItem value="date">Date</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {/* Meal Type Tabs */}
        <Box sx={{ mt: 3 }}>
          <Tabs 
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root': {
                height: 48,
                textTransform: 'none',
                minWidth: 'auto',
                px: 3,
                borderRadius: '12px',
                color: colors.text,
                fontWeight: 400,
                transition: 'all 0.2s ease',
                '&.Mui-selected': {
                  color: colors.primary,
                  bgcolor: colors.accent,
                  fontWeight: 500,
                },
                '&:hover': {
                  bgcolor: colors.subtle,
                },
              },
            }}
          >
            <Tab label="All" />
            <Tab label="Breakfast" />
            <Tab label="Lunch" />
            <Tab label="Dinner" />
            <Tab label="Snacks" />
          </Tabs>
        </Box>
      </Paper>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Meals List */}
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            {filteredMeals.length > 0 ? (
              filteredMeals.map((meal) => (
                <Paper
                  key={meal.id}
                  elevation={0}
                  sx={{
                    p: { xs: 2, md: 3 },
                    border: `1px solid ${colors.border}`,
                    borderRadius: '16px',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Chip 
                      label={meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}
                      size="small"
                      sx={{
                        height: 24,
                        bgcolor: colors.subtle,
                        color: colors.accent,
                        borderRadius: '8px',
                        fontWeight: 500,
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/dashboard-nutrition-details/${meal.id}`)}
                      sx={{
                        height: 36,
                        px: 2,
                        borderColor: colors.border,
                        color: colors.text,
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontWeight: 500,
                        '&:hover': {
                          borderColor: colors.accent,
                          bgcolor: colors.subtle,
                        },
                      }}
                    >
                      View Details
                    </Button>
                  </Box>

                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontSize: '1.25rem',
                      color: colors.text,
                      fontWeight: 500,
                      mb: 2,
                      lineHeight: 1.3,
                    }}
                  >
                    {meal.name}
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AccessTime sx={{ fontSize: 20, mr: 1, color: colors.muted }} />
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: colors.muted,
                        fontWeight: 400,
                      }}
                    >
                      {new Date(meal.date).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    {[
                      { label: 'Calories', value: meal.calories },
                      { label: 'Protein', value: `${meal.protein}g` },
                      { label: 'Carbs', value: `${meal.carbs}g` },
                      { label: 'Fat', value: `${meal.fat}g` }
                    ].map((stat) => (
                      <Grid item xs={3} key={stat.label}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: colors.muted,
                            mb: 0.5,
                            fontWeight: 400,
                          }}
                        >
                          {stat.label}
                        </Typography>
                        <Typography 
                          variant="h3" 
                          sx={{ 
                            fontSize: '2rem',
                            color: colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {stat.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              ))
            ) : (
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 3, md: 4 },
                  border: `1px solid ${colors.border}`,
                  borderRadius: '16px',
                  textAlign: 'center',
                }}
              >
                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: colors.text,
                    mb: 1,
                  }}
                >
                  0
                  <Typography 
                    component="span" 
                    variant="h6" 
                    sx={{ 
                      color: colors.muted,
                      ml: 1,
                      fontWeight: 400,
                    }}
                  >
                    Meals
                  </Typography>
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: colors.muted,
                    fontWeight: 300,
                    lineHeight: 1.6,
                  }}
                >
                  No meals logged today
                </Typography>
              </Paper>
            )}
          </Stack>
        </Grid>

        {/* Popular Meals Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 2, md: 3 },
              border: `1px solid ${colors.border}`,
              borderRadius: '16px',
              position: 'sticky',
              top: 24,
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '1.25rem',
                color: colors.text,
                fontWeight: 500,
                mb: 3,
              }}
            >
              Popular Meals
            </Typography>
            <Stack spacing={2}>
              {filteredMeals.length > 0 ? (
                filteredMeals.slice(0, 5).map((meal) => (
                  <Box
                    key={meal.id}
                    sx={{
                      p: 2,
                      bgcolor: colors.subtle,
                      borderRadius: '12px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontSize: '0.875rem',
                        color: colors.text,
                        fontWeight: 500,
                        mb: 1,
                        lineHeight: 1.3,
                      }}
                    >
                      {meal.name}
                    </Typography>
                    <Chip
                      label={meal.meal_type}
                      size="small"
                      sx={{
                        height: 20,
                        fontSize: '0.75rem',
                        bgcolor: colors.accent,
                        color: colors.primary,
                        borderRadius: '6px',
                        fontWeight: 500,
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Box sx={{ textAlign: 'center', py: 3 }}>
                  <Typography 
                    variant="h3" 
                    sx={{ 
                      fontSize: '2rem',
                      fontWeight: 600,
                      color: colors.text,
                      mb: 1,
                    }}
                  >
                    0
                    <Typography 
                      component="span" 
                      variant="h6" 
                      sx={{ 
                        color: colors.muted,
                        ml: 1,
                        fontWeight: 400,
                      }}
                    >
                      Popular Meals
                    </Typography>
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: colors.muted,
                      fontWeight: 300,
                      lineHeight: 1.6,
                    }}
                  >
                    No meals to show
                  </Typography>
                </Box>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardNutrition; 