import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  LinearProgress,
  CircularProgress,
  Paper,
  Button,
} from '@mui/material';
import {
  ArrowBack,
  Timer,
  CheckCircle,
  Info,
  Warning,
  AccessTime,
  Restaurant,
  Star,
} from '@mui/icons-material';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const DashboardNutritionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [meal, setMeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMealDetails = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          navigate('/signin');
          return;
        }

        const response = await fetch(`${BACKEND_URL}/dashboard-nutrition/meals/${id}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (!response.ok) {
          if (response.status === 401) {
            navigate('/signin');
            return;
          }
          throw new Error('Failed to fetch meal details');
        }

        const data = await response.json();
        setMeal(data);
      } catch (error) {
        console.error('Error fetching meal details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMealDetails();
    }
  }, [id, navigate]);

  if (loading) {
    return (
      <Box 
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <CircularProgress sx={{ color: colors.accent }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        sx={{ 
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: colors.text }}>
          Error: {error}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/dashboard-nutrition')}
          sx={{
            borderColor: colors.border,
            color: colors.text,
            borderRadius: '12px',
            textTransform: 'none',
            '&:hover': {
              borderColor: colors.accent,
              bgcolor: colors.subtle,
            },
          }}
        >
          Return to Dashboard
        </Button>
      </Box>
    );
  }

  if (!meal) {
    return (
      <Box 
        sx={{ 
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: colors.text }}>
          Meal not found
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/dashboard-nutrition')}
          sx={{
            borderColor: colors.border,
            color: colors.text,
            borderRadius: '12px',
            textTransform: 'none',
            '&:hover': {
              borderColor: colors.accent,
              bgcolor: colors.subtle,
            },
          }}
        >
          Return to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, bgcolor: colors.primary }}>
      {/* Header */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <IconButton 
            onClick={() => navigate('/dashboard-nutrition')}
            sx={{
              bgcolor: colors.subtle,
              color: colors.text,
              '&:hover': {
                bgcolor: colors.subtle,
                color: colors.accent,
              },
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box>
            <Chip 
              label={meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}
              size="small"
              sx={{
                mb: 1,
                bgcolor: colors.subtle,
                color: colors.accent,
                borderRadius: '8px',
                fontWeight: 500,
              }}
            />
            <Typography 
              variant="h4" 
              sx={{ 
                color: colors.text,
                fontWeight: 600,
                wordBreak: 'break-word',
              }}
            >
              {meal.name || 'Unnamed Meal'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 2, ml: 7 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTime sx={{ fontSize: 16, mr: 1, color: colors.muted }} />
            <Typography variant="body2" sx={{ color: colors.muted }}>
              {new Date(meal.date).toLocaleString()}
            </Typography>
          </Box>
          {meal.ai_analysis?.meal_analysis?.balance_score && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Star sx={{ fontSize: 16, mr: 1, color: colors.accent }} />
              <Typography variant="body2" sx={{ color: colors.muted }}>
                Balance Score: {meal.ai_analysis.meal_analysis.balance_score}/10
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* Nutrition Overview */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 3,
              mb: 3,
              border: `1px solid ${colors.border}`,
              borderRadius: '16px',
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                color: colors.text,
                fontWeight: 500,
                mb: 3,
              }}
            >
              Nutrition Overview
            </Typography>
            <Grid container spacing={2}>
              {[
                { label: 'Calories', value: meal.calories || 0, unit: 'kcal' },
                { label: 'Protein', value: meal.protein || 0, unit: 'g' },
                { label: 'Carbs', value: meal.carbs || 0, unit: 'g' },
                { label: 'Fat', value: meal.fat || 0, unit: 'g' }
              ].map((item) => (
                <Grid item xs={6} sm={3} key={item.label}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: colors.subtle,
                      borderRadius: '12px',
                      height: '100%',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: colors.muted, mb: 1 }}>
                      {item.label}
                    </Typography>
                    <Typography variant="h5" sx={{ color: colors.text, fontWeight: 600 }}>
                      {item.value}
                    </Typography>
                    <Typography variant="caption" sx={{ color: colors.muted }}>
                      {item.unit}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>

          {/* Ingredients List */}
          {meal.foods && meal.foods.length > 0 && (
            <Paper 
              elevation={0}
              sx={{ 
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ p: 3, borderBottom: `1px solid ${colors.border}` }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: colors.text,
                    fontWeight: 500,
                  }}
                >
                  Ingredients
                </Typography>
              </Box>
              <List>
                {meal.foods.map((food, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemIcon>
                        <Restaurant sx={{ color: colors.accent }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={food.name}
                        secondary={`${food.serving_size || ''} ${food.serving_unit || ''} ${food.calories ? `- ${food.calories} calories` : ''}`}
                        primaryTypographyProps={{ 
                          sx: { 
                            color: colors.text,
                            fontWeight: 500,
                          }
                        }}
                        secondaryTypographyProps={{
                          sx: { color: colors.muted }
                        }}
                      />
                    </ListItem>
                    {index < meal.foods.length - 1 && (
                      <Divider sx={{ borderColor: colors.border }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          )}
        </Grid>

        {/* Health Analysis Sidebar */}
        <Grid item xs={12} md={4}>
          {meal.ai_analysis && (
            <Paper
              elevation={0}
              sx={{
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ p: 3, borderBottom: `1px solid ${colors.border}` }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: colors.text,
                    fontWeight: 500,
                  }}
                >
                  Health Analysis
                </Typography>
              </Box>
              
              <Box sx={{ p: 3 }}>
                {/* Balance Score */}
                <Box sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" sx={{ color: colors.muted }}>
                      Balance Score
                    </Typography>
                    <Typography variant="body2" sx={{ color: colors.text, fontWeight: 500 }}>
                      {meal.ai_analysis.meal_analysis.balance_score}/10
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={meal.ai_analysis.meal_analysis.balance_score * 10}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      bgcolor: colors.subtle,
                      '& .MuiLinearProgress-bar': {
                        bgcolor: colors.accent,
                      },
                    }}
                  />
                </Box>

                {/* Macro Distribution */}
                <Box 
                  sx={{ 
                    mb: 3,
                    p: 2,
                    bgcolor: colors.subtle,
                    borderRadius: '12px',
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: colors.text,
                      fontWeight: 500,
                      mb: 2,
                    }}
                  >
                    Macro Distribution
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      { label: 'Protein', value: meal.ai_analysis.meal_analysis.protein_ratio },
                      { label: 'Carbs', value: meal.ai_analysis.meal_analysis.carb_ratio },
                      { label: 'Fat', value: meal.ai_analysis.meal_analysis.fat_ratio }
                    ].map((macro) => (
                      <Grid item xs={4} key={macro.label}>
                        <Typography variant="body2" sx={{ color: colors.muted, mb: 0.5 }}>
                          {macro.label}
                        </Typography>
                        <Typography variant="h6" sx={{ color: colors.text, fontWeight: 600 }}>
                          {macro.value}%
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Health Insights */}
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    color: colors.text,
                    fontWeight: 500,
                    mb: 2,
                  }}
                >
                  Health Insights
                </Typography>
                <List sx={{ bgcolor: colors.subtle, borderRadius: '12px' }}>
                  {meal.ai_analysis.health_insights.positive_aspects.map((aspect, index) => (
                    <ListItem key={`positive-${index}`}>
                      <ListItemIcon>
                        <CheckCircle sx={{ color: colors.accent }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={aspect}
                        primaryTypographyProps={{ 
                          variant: 'body2',
                          sx: { color: colors.text }
                        }}
                      />
                    </ListItem>
                  ))}
                  {meal.ai_analysis.health_insights.suggestions.map((suggestion, index) => (
                    <ListItem key={`suggestion-${index}`}>
                      <ListItemIcon>
                        <Info sx={{ color: colors.accent }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={suggestion}
                        primaryTypographyProps={{ 
                          variant: 'body2',
                          sx: { color: colors.text }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardNutritionDetails; 