import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Alert,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { FitnessCenter, CheckCircleOutline } from '@mui/icons-material';

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

function Register() {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Pre-fill email if redirected from sign-in
  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting || !acceptedTerms) return;
    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.detail || 'Registration failed');
      }

      // Show window alert first
      window.alert(`Registration successful! Please check your inbox at ${email}. If you don't see the email, please check your spam folder.`);

      // Then show snackbar
      setSnackbar({
        open: true,
        message: result.message || 'Registration successful! Please check your email to verify your account.',
        severity: 'success'
      });
      
      // Clear form
      setEmail('');
      setAcceptedTerms(false);
      
    } catch (error) {
      console.error('Registration error:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Registration failed. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: colors.primary,
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          {/* Header */}
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Box
              sx={{
                width: 64,
                height: 64,
                borderRadius: '16px',
                bgcolor: colors.subtle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 'auto',
                mb: 2,
              }}
            >
              <FitnessCenter sx={{ fontSize: 32, color: colors.accent }} />
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: colors.text,
                fontWeight: 600,
                fontSize: { xs: '1.75rem', md: '2rem' },
                mb: 1,
                letterSpacing: '-0.02em',
              }}
            >
              Start Your Journey
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.muted,
                fontWeight: 400,
                maxWidth: '400px',
                mx: 'auto',
              }}
            >
              3-day free trial – No credit card required
            </Typography>
          </Box>

          {/* Trial Benefits */}
          <Box sx={{ mb: 4 }}>
            {[
              {
                title: 'Real-Time Active Workout Logging',
                description: 'Track workouts set-by-set using voice or manual input in seconds.'
              },
              {
                title: 'AI Meal Logging',
                description: 'Log meals using voice, text, or photo—AI instantly logs macros and nutritional details.'
              },
              {
                title: 'Progress Tracking',
                description: 'Access your full workout and meal history anytime.'
              }
            ].map((benefit, index) => (
              <Box 
                key={index}
                sx={{ 
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                  mb: 2,
                }}
              >
                <CheckCircleOutline sx={{ color: colors.accent, mt: 0.5 }} />
                <Box>
                  <Typography 
                    variant="subtitle1"
                    sx={{ 
                      color: colors.text,
                      fontWeight: 500,
                      mb: 0.5,
                    }}
                  >
                    {benefit.title}
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{ 
                      color: colors.muted,
                      lineHeight: 1.6,
                    }}
                  >
                    {benefit.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Divider sx={{ my: 4 }} />

          {/* Registration Form */}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  height: 48,
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                  sx={{
                    color: colors.accent,
                    '&.Mui-checked': {
                      color: colors.accent,
                    },
                  }}
                />
              }
              label={
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: colors.muted,
                    lineHeight: 1.6,
                  }}
                >
                  I understand that I'm starting a 3-day free trial with full access. 
                  After the trial, I'll need a subscription to continue ($9.99/month).
                </Typography>
              }
              sx={{ mb: 3 }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting || !acceptedTerms}
              sx={{
                height: 48,
                bgcolor: colors.accent,
                color: colors.primary,
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '1rem',
                '&:hover': {
                  bgcolor: colors.accent,
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                },
                transition: 'all 0.2s ease',
                '&:disabled': {
                  bgcolor: 'rgba(0, 163, 255, 0.12)',
                  color: 'rgba(0, 163, 255, 0.4)',
                },
              }}
            >
              {isSubmitting ? 'Creating Account...' : 'Start Free Trial'}
            </Button>

            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography
                variant="body2"
                sx={{ 
                  color: colors.muted,
                }}
              >
                Already have an account?{' '}
                <Link 
                  to="/signin"
                  style={{ 
                    color: colors.accent,
                    textDecoration: 'none',
                    fontWeight: 500,
                  }}
                >
                  Sign in
                </Link>
              </Typography>
            </Box>
          </form>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
              elevation={0}
              sx={{ 
                width: '100%',
                minWidth: '300px',
                borderRadius: '12px',
                border: `1px solid ${colors.border}`,
                fontSize: '0.875rem',
                alignItems: 'center',
              }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Paper>
      </Container>
    </Box>
  );
}


export default Register;
