import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Fab,
  Chip,
  Divider,
  useTheme,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Snackbar,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import {
  Mic,
  MicOff,
  PlayArrow,
  Pause,
  Stop,
  Add,
  Edit,
  Save,
  Timer,
  FitnessCenter,
  DirectionsRun,
  Send
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';


// Update the BACKEND_URL definition
const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;


// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};


// Styled components
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const VoiceButton = styled(Button)(({ theme, islistening }) => ({
  height: islistening ? '120px' : '48px',
  transition: 'all 0.3s ease',
  background: islistening ? theme.palette.error.main : colors.accent,
  color: colors.primary,
  borderRadius: '12px',
  '&:hover': {
    background: islistening ? theme.palette.error.dark : colors.accent,
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
  },
}));

const TimerDisplay = styled(Typography)(({ theme }) => ({
  fontFamily: 'monospace',
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  margin: theme.spacing(2, 0),
}));

const ActiveWorkoutLogger = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useUser();

  // Add unit toggle state
  const [weightUnit, setWeightUnit] = useState('lbs');

  // Basic states
  const workoutStateRef = useRef({
    id: null,
    status: 'ready',
    sets: [],
    exercises: []  // Add exercises array to initial state
  });

  const [workoutState, setWorkoutState] = useState(workoutStateRef.current);
  const [editableSet, setEditableSet] = useState(null);

  // Update ref when state changes
  useEffect(() => {
    workoutStateRef.current = workoutState;
  }, [workoutState]);

  // Voice recognition state
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const recognitionRef = useRef(null);

  // UI state
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [processedData, setProcessedData] = useState(null);

  // Add state for workout summary
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [workoutSummary, setWorkoutSummary] = useState({
    name: 'Strength Workout',
    date: new Date().toISOString().split('T')[0],
    notes: '',
    split: '',  // Push, Pull, Legs, etc.
    primaryMuscleGroups: [],  // Back, Chest, Legs, etc.
    trainingPhase: 'strength'
  });

  // Add state for manual input
  const [inputText, setInputText] = useState('');

  // Add workout type state
  const [workoutType, setWorkoutType] = useState('strength');

  // Add distance unit state for cardio
  const [distanceUnit, setDistanceUnit] = useState('meters');

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window && !recognitionRef.current) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let currentTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            currentTranscript = event.results[i][0].transcript;
            console.log('Final transcript:', currentTranscript);
          } else {
            currentTranscript = event.results[i][0].transcript;
            console.log('Interim transcript:', currentTranscript);
          }
          setTranscript(currentTranscript);
        }
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setError('Speech recognition error: ' + event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        console.log('Speech recognition ended');
        setIsListening(false);
      };

      recognitionRef.current = recognition;
    }
  }, []);

  const handleAuthError = (error) => {
    if (error.status === 401) {
      logout(); // This will clear auth tokens and redirect to signin
    }
  };

  // Process voice command with auth handling
  const processVoiceCommand = async (command) => {
    if (!command.trim()) return;
    
    console.log('Processing voice input:', command);
    console.log('Current workout state:', workoutStateRef.current);
    
    try {
      setLoading(true);
      setError('');

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      if (!workoutStateRef.current.id) {
        console.error('No workout ID found in state:', workoutStateRef.current);
        setError('No active workout. Please start a workout first.');
        return;
      }

      const response = await fetch(`${BACKEND_URL}/active-workout/process-input`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          input_text: command,
          workout_id: workoutStateRef.current.id
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to process input');
      }

      const data = await response.json();
      console.log('Processed voice input:', data);
      
      setProcessedData(data);
      setShowConfirmDialog(true);
      setTranscript('');
    } catch (err) {
      console.error('Error processing voice input:', err);
      setError(err.message);
      if (err.status === 401) {
        handleAuthError({ status: 401 });
      }
    } finally {
      setLoading(false);
    }
  };

  // Start workout with auth handling
  const startWorkout = async () => {
    try {
      setLoading(true);
      setError('');

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const response = await fetch(`${BACKEND_URL}/start-active-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          workout_type: workoutType,
          name: `${workoutType.charAt(0).toUpperCase() + workoutType.slice(1)} Workout`
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to start workout');
      }
      
      const data = await response.json();
      setWorkoutState({
        id: data.id,
        status: 'active',
        sets: [],
        exercises: []  // Add exercises array to initial state
      });
      setSuccess('Workout started!');
    } catch (err) {
      console.error('Error starting workout:', err);
      setError(err.message);
      if (err.status === 401) {
        handleAuthError({ status: 401 });
      }
    } finally {
      setLoading(false);
    }
  };

  // Add 1RM calculation function
  const calculateOneRepMax = (weight, reps) => {
    if (!weight || !reps) return null;
    // Brzycki Formula: 1RM = weight × (36 / (37 - reps))
    return Math.round(weight * (36 / (37 - reps)));
  };

  // Update confirmSet to include 1RM calculation
  const confirmSet = async (set) => {
    if (!set || !set.exercise_name) return;
    
    try {
      setLoading(true);

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }
      
      const oneRepMax = calculateOneRepMax(set.weight, set.reps);
      
      const exerciseResponse = await fetch(`${BACKEND_URL}/active-workout/exercise`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          workout_id: workoutState.id,
          name: set.exercise_name
        })
      });

      if (!exerciseResponse.ok) {
        if (exerciseResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to create exercise');
      }

      const exercise = await exerciseResponse.json();

      const setResponse = await fetch(`${BACKEND_URL}/active-workout/confirm-set`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          exercise_id: exercise.id,
          processed_data: {
            ...set,
            one_rep_max: oneRepMax
          }
        })
      });

      if (!setResponse.ok) {
        if (setResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to log set');
      }

      setWorkoutState(prev => ({
        ...prev,
        sets: [...prev.sets, { ...set, one_rep_max: oneRepMax }],
        exercises: Array.isArray(prev.exercises) ? [...prev.exercises, { ...set, one_rep_max: oneRepMax }] : [{ ...set, one_rep_max: oneRepMax }]
      }));

      setShowConfirmDialog(false);
      setEditableSet(null);
      setSuccess('Set logged successfully!');
    } catch (err) {
      setError(err.message);
      console.error('Error logging set:', err);
      if (err.status === 401) {
        handleAuthError({ status: 401 });
      }
    } finally {
      setLoading(false);
    }
  };

  // Toggle voice recognition
  const toggleVoiceRecognition = () => {
    if (isListening) {
      recognitionRef.current?.stop();
      // Process the current transcript when manually stopping
      if (transcript.trim()) {
        processVoiceCommand(transcript);
      }
    } else {
      try {
        recognitionRef.current?.start();
        setIsListening(true);
        setTranscript('');
        setError('');
      } catch (err) {
        setError('Could not start speech recognition');
      }
    }
  };

  // Calculate workout stats
  const calculateWorkoutStats = () => {
    let totalSets = 0;
    let totalVolume = 0;
    
    workoutState.sets.forEach(set => {
      totalSets++;
      if (set.weight && set.reps) {
        totalVolume += set.weight * set.reps;
      }
    });

    return { totalSets, totalVolume };
  };

  // Handle showing summary before ending workout
  const handleEndWorkoutClick = () => {
    const stats = calculateWorkoutStats();
    setWorkoutSummary(prev => ({
        ...prev,
      totalSets: stats.totalSets,
      totalVolume: stats.totalVolume
    }));
    setShowSummaryDialog(true);
  };

  // End workout with auth handling
  const endWorkout = async () => {
    try {
      setLoading(true);
      
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      console.log('Starting workout end process...');
      console.log('Current workout state:', workoutState);
      console.log('Workout summary before save:', workoutSummary);
      
      const completeResponse = await fetch(`${BACKEND_URL}/active-workout/${workoutState.id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ status: 'completed' })
      });

      if (!completeResponse.ok) {
        if (completeResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to end workout');
      }

      const summaryData = {
        name: workoutSummary.name,
        notes: workoutSummary.notes,
        workout_split: workoutSummary.split,
        primary_muscle_groups: workoutSummary.primaryMuscleGroups,
        total_sets: calculateWorkoutStats().totalSets,
        total_volume: calculateWorkoutStats().totalVolume,
        type: workoutType,
        status: 'completed',
        training_phase: workoutSummary.trainingPhase
      };

      console.log('Sending summary data to backend:', summaryData);

      const saveResponse = await fetch(`${BACKEND_URL}/active-workout/${workoutState.id}/save-to-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(summaryData)
      });

      if (!saveResponse.ok) {
        if (saveResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        const errorData = await saveResponse.json();
        console.error('Error response from save endpoint:', errorData);
        throw new Error(`Failed to save workout: ${JSON.stringify(errorData)}`);
      }

      const saveResult = await saveResponse.json();
      console.log('Save response:', saveResult);
      
      setWorkoutState(prev => ({
        ...prev,
        status: 'completed'
      }));
      setShowSummaryDialog(false);
      setSuccess('Workout completed and saved!');
    } catch (err) {
      console.error('Error in endWorkout:', err);
      setError(err.message);
      if (err.status === 401) {
        handleAuthError({ status: 401 });
      }
    } finally {
      setLoading(false);
    }
  };

  // Convert weight between units
  const convertWeight = (value, fromUnit, toUnit) => {
    if (!value) return null;
    const val = parseFloat(value);
    if (fromUnit === toUnit) return val;
    return toUnit === 'kg' ? val * 0.453592 : val / 0.453592;
  };

  // Handle unit toggle
  const handleUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setWeightUnit(newUnit);
      if (editableSet) {
        setEditableSet({
          ...editableSet,
          weight: convertWeight(editableSet.weight, weightUnit, newUnit),
          unit: newUnit
        });
      }
    }
  };

  // When processed data is received, set it as editable
  useEffect(() => {
    if (processedData) {
      setEditableSet({
        ...processedData,
        unit: weightUnit, // Use current unit preference
        // Ensure we have numbers, not nulls
        reps: processedData.reps || '',
        weight: processedData.weight || ''
      });
    }
  }, [processedData, weightUnit]);

  // Handle manual input
  const handleManualInput = () => {
    if (inputText.trim()) {
      processVoiceCommand(inputText);
      setInputText('');
    }
  };

  // Update handleConfirm function
  const handleConfirm = async () => {
    try {
      if (!editableSet) {
        setError('No data to confirm');
        return;
      }

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError('No auth token found');
        return;
      }

      if (workoutType === 'cardio') {
        // Create a new exercise for cardio activity
        const exerciseResponse = await fetch(`${BACKEND_URL}/active-workout/exercise`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({
            workout_id: workoutState.id,
            name: 'Cardio Activity',
            exercise_type: 'cardio'
          })
        });

        if (!exerciseResponse.ok) {
          throw new Error('Failed to create cardio exercise');
        }

        const exercise = await exerciseResponse.json();

        // Prepare cardio-specific data
        const processedData = {
          distance: editableSet.distance,
          distance_unit: distanceUnit,
          duration: editableSet.duration,
          description: `${editableSet.distance}${distanceUnit} cardio`,
          pace: editableSet.duration / 60 / editableSet.distance
        };

        // Send the confirmation request
        const response = await fetch(`${BACKEND_URL}/active-workout/confirm-set`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            exercise_id: exercise.id,
            processed_data: processedData
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to confirm cardio activity');
        }

        const updatedExercise = await response.json();
        setWorkoutState(prev => ({
          ...prev,
          exercises: Array.isArray(prev.exercises) ? [...prev.exercises, updatedExercise] : [updatedExercise]
        }));
      } else {
        // Handle strength workout
        await confirmSet(editableSet);
      }

      // Clear the editable set and close dialog
      setEditableSet(null);
      setShowConfirmDialog(false);
      setSuccess(workoutType === 'strength' ? 'Set confirmed!' : 'Cardio activity logged!');

    } catch (error) {
      console.error('Error confirming activity:', error);
      setError(error.message);
    }
  };

  // Add these helper functions at the top level
  const convertToMilesPace = (duration, distance, fromUnit) => {
    // Convert distance to miles first
    let distanceInMiles;
    switch (fromUnit) {
      case 'meters':
        distanceInMiles = distance / 1609.34;
        break;
      case 'kilometers':
        distanceInMiles = distance / 1.60934;
        break;
      case 'miles':
        distanceInMiles = distance;
        break;
      default:
        distanceInMiles = distance;
    }
    
    // Calculate pace in seconds per mile
    const paceInSeconds = duration / distanceInMiles;
    
    // Convert to minutes and seconds
    const minutes = Math.floor(paceInSeconds / 60);
    const seconds = Math.round(paceInSeconds % 60);
    
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatDuration = (totalSeconds) => {
    if (!totalSeconds) return '';
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Update the cardio section in renderConfirmationContent
  const renderConfirmationContent = () => {
    if (!editableSet) return null;

    return (
      <DialogContent>
        <Typography variant="h6" gutterBottom sx={{ color: colors.text, fontWeight: 500 }}>
          Confirm {workoutType === 'strength' ? 'Set' : 'Activity'}
        </Typography>
        {workoutType === 'strength' ? (
          // Strength workout confirmation
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={weightUnit}
                exclusive
                onChange={handleUnitChange}
                size="small"
                sx={{
                  '& .MuiToggleButton-root': {
                    border: `1px solid ${colors.border}`,
                    color: colors.text,
                    '&.Mui-selected': {
                      bgcolor: colors.accent,
                      color: colors.primary,
                      '&:hover': {
                        bgcolor: colors.accent,
                      },
                    },
                  },
                }}
              >
                <ToggleButton value="lbs">lbs</ToggleButton>
                <ToggleButton value="kg">kg</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <TextField
              fullWidth
              label="Exercise"
              value={editableSet?.exercise_name || ''}
              onChange={(e) => setEditableSet(prev => ({
                ...prev,
                exercise_name: e.target.value
              }))}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />
            <TextField
              fullWidth
              label={`Weight (${weightUnit})`}
              type="number"
              value={editableSet?.weight || ''}
              onChange={(e) => setEditableSet(prev => ({
                ...prev,
                weight: e.target.value ? Number(e.target.value) : null
              }))}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />
            <TextField
              fullWidth
              label="Reps"
              type="number"
              value={editableSet?.reps || ''}
              onChange={(e) => setEditableSet(prev => ({
                ...prev,
                reps: e.target.value ? Number(e.target.value) : null
              }))}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />
          </Box>
        ) : (
          // Cardio workout confirmation
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={distanceUnit}
                exclusive
                onChange={(e, newUnit) => newUnit && setDistanceUnit(newUnit)}
                size="small"
                sx={{
                  '& .MuiToggleButton-root': {
                    border: `1px solid ${colors.border}`,
                    color: colors.text,
                    '&.Mui-selected': {
                      bgcolor: colors.accent,
                      color: colors.primary,
                      '&:hover': {
                        bgcolor: colors.accent,
                      },
                    },
                  },
                }}
              >
                <ToggleButton value="meters">meters</ToggleButton>
                <ToggleButton value="kilometers">km</ToggleButton>
                <ToggleButton value="miles">miles</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* Quick Distance Presets */}
            <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {[
                { distance: 200, unit: 'meters' },
                { distance: 400, unit: 'meters' },
                { distance: 1, unit: 'kilometers' },
                { distance: 1, unit: 'miles' }
              ].map((preset) => (
                <Chip
                  key={`${preset.distance}${preset.unit}`}
                  label={`${preset.distance} ${preset.unit}`}
                  onClick={() => {
                    setEditableSet(prev => ({
                      ...prev,
                      distance: preset.distance
                    }));
                    setDistanceUnit(preset.unit);
                  }}
                  sx={{
                    cursor: 'pointer',
                    bgcolor: editableSet?.distance === preset.distance && distanceUnit === preset.unit ? colors.accent : 'transparent',
                    color: editableSet?.distance === preset.distance && distanceUnit === preset.unit ? colors.primary : colors.text,
                    border: `1px solid ${colors.border}`,
                    '&:hover': {
                      bgcolor: colors.subtle,
                    }
                  }}
                />
              ))}
            </Box>

            <TextField
              fullWidth
              label={`Distance (${distanceUnit})`}
              type="number"
              value={editableSet?.distance || ''}
              onChange={(e) => setEditableSet(prev => ({
                ...prev,
                distance: e.target.value ? Number(e.target.value) : null
              }))}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />

            {/* Duration Input with Minutes and Seconds */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Minutes"
                  type="number"
                  value={editableSet?.duration ? Math.floor(editableSet.duration / 60) : ''}
                  onChange={(e) => {
                    const minutes = parseInt(e.target.value) || 0;
                    const currentSeconds = editableSet?.duration ? editableSet.duration % 60 : 0;
                    setEditableSet(prev => ({
                      ...prev,
                      duration: minutes * 60 + currentSeconds
                    }));
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover fieldset': {
                        borderColor: colors.accent,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.accent,
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Seconds"
                  type="number"
                  value={editableSet?.duration ? editableSet.duration % 60 : ''}
                  onChange={(e) => {
                    const seconds = parseInt(e.target.value) || 0;
                    const currentMinutes = editableSet?.duration ? Math.floor(editableSet.duration / 60) : 0;
                    setEditableSet(prev => ({
                      ...prev,
                      duration: currentMinutes * 60 + seconds
                    }));
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover fieldset': {
                        borderColor: colors.accent,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.accent,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            {editableSet?.distance && editableSet?.duration && (
              <Box sx={{ mt: 2, p: 2, bgcolor: colors.subtle, borderRadius: '12px' }}>
                <Typography sx={{ color: colors.text, fontWeight: 500 }}>
                  Pace: {convertToMilesPace(editableSet.duration, editableSet.distance, distanceUnit)} min/mile
                </Typography>
                <Typography variant="body2" sx={{ color: colors.muted, mt: 1 }}>
                  Duration: {formatDuration(editableSet.duration)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    );
  };

    return (
    <Container maxWidth="md">
      {/* Explanation section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          mb: 4, 
          bgcolor: colors.subtle,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{
            fontWeight: 600,
            color: colors.text,
            mb: 2,
          }}
        >
          Real-Time Workout Tracking
        </Typography>
        <Typography 
          variant="body1"
          sx={{ 
            color: colors.muted,
            lineHeight: 1.6,
          }}
        >
          Track your workout as you go! Perfect for:
          • Recording sets immediately after completion
          • Using voice commands between sets
          • Typing in exercises as you perform them
        </Typography>
      </Paper>

      {/* Status and Controls */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          mb: 4,
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography 
              variant="h5"
              sx={{
                fontWeight: 600,
                color: colors.text,
              }}
            >
              {workoutState.status === 'ready' ? 'Ready to Start' :
               workoutState.status === 'active' ? 'Workout in Progress' :
               'Workout Complete'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
              {workoutState.status === 'ready' && (
                <>
                  <ToggleButtonGroup
                    value={workoutType}
                    exclusive
                    onChange={(e, newType) => newType && setWorkoutType(newType)}
                    size="small"
                    sx={{
                      '& .MuiToggleButton-root': {
                        border: `1px solid ${colors.border}`,
                        color: colors.text,
                        '&.Mui-selected': {
                          bgcolor: colors.accent,
                          color: colors.primary,
                          '&:hover': {
                            bgcolor: colors.accent,
                          },
                        },
                      },
                    }}
                  >
                    <ToggleButton value="strength" aria-label="strength">
                      <FitnessCenter sx={{ mr: 1 }} />
                      Strength
                    </ToggleButton>
                    <ToggleButton value="cardio" aria-label="cardio">
                      <DirectionsRun sx={{ mr: 1 }} />
                      Cardio
                    </ToggleButton>
                  </ToggleButtonGroup>
                <Button
                  variant="contained"
                  onClick={startWorkout}
                  disabled={loading}
                  sx={{
                    bgcolor: colors.accent,
                    color: colors.primary,
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    px: 4,
                    fontWeight: 500,
                    '&:hover': {
                      bgcolor: colors.accent,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                    },
                  }}
                >
                  Start Workout
                </Button>
                </>
              )}
              {workoutState.status === 'active' && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleEndWorkoutClick}
                  disabled={workoutState.status === 'completed'}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    px: 4,
                    fontWeight: 500,
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(255, 0, 0, 0.15)',
                    },
                  }}
                >
                  End Workout
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Voice Input Section */}
      {workoutState.status === 'active' && (
        <Grid container spacing={3}>
          {/* Voice Input Card */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                mb: 3, 
                bgcolor: isListening ? colors.accent : colors.primary,
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
                transition: 'all 0.3s ease',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography 
                  variant="h6" 
                  sx={{
                    color: isListening ? colors.primary : colors.text,
                    fontWeight: 500,
                  }}
                >
                  Voice Input
                </Typography>
                <Button
                  variant="contained"
                  onClick={toggleVoiceRecognition}
                  sx={{
                    bgcolor: isListening ? colors.primary : colors.accent,
                    color: isListening ? colors.accent : colors.primary,
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    px: 3,
                    '&:hover': {
                      bgcolor: isListening ? colors.primary : colors.accent,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                    },
                  }}
                  startIcon={isListening ? <MicOff /> : <Mic />}
                >
                  {isListening ? 'Stop' : 'Start'} Recording
                </Button>
              </Box>
              {isListening && (
                <Typography 
                  sx={{ 
                    color: colors.primary,
                    fontWeight: 300,
                  }}
                >
                  {transcript || 'Listening...'}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Manual Input Card */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                mb: 3,
                border: `1px solid ${colors.border}`,
                borderRadius: '16px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography 
                  variant="h6"
                  sx={{
                    color: colors.text,
                    fontWeight: 500,
                  }}
                >
                  Manual Input
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder={workoutType === 'strength' 
                    ? "e.g., '8 reps bench press 225 lbs'" 
                    : "e.g., 'ran 200 meters in 45 seconds'"}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      borderColor: colors.border,
                      '&:hover fieldset': {
                        borderColor: colors.accent,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.accent,
                      },
                    },
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleManualInput}
                  disabled={!inputText.trim()}
                  startIcon={<Send />}
                  sx={{
                    bgcolor: colors.accent,
                    color: colors.primary,
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    '&:hover': {
                      bgcolor: colors.accent,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                    },
                    '&.Mui-disabled': {
                      bgcolor: 'rgba(0, 0, 0, 0.12)',
                    },
                  }}
                >
                  Process Set
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Exercise History */}
      {(workoutState.sets.length > 0 || (workoutState.exercises && workoutState.exercises.length > 0)) && (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 3,
            mt: 3,
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: colors.text }}>
            {workoutType === 'strength' ? 'Sets' : 'Activities'} History
          </Typography>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {workoutType === 'strength' ? (
              // Strength workout history
              workoutState.sets.map((set, index) => (
                <Box
                  key={index}
            sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2,
                    p: 2,
                    borderRadius: '12px',
                    bgcolor: colors.subtle,
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <FitnessCenter sx={{ color: colors.accent }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: colors.text, fontWeight: 500 }}>
                      {set.exercise_name}
          </Typography>
                    <Typography variant="body2" sx={{ color: colors.muted }}>
                      {set.reps} reps × {set.weight} {set.unit}
                      {set.estimated_1rm && ` • Est. 1RM: ${set.estimated_1rm} ${set.unit}`}
                    </Typography>
                  </Box>
              <Chip
                    label={`Set ${index + 1}`}
                    size="small"
                sx={{ 
                      bgcolor: 'transparent',
                      border: `1px solid ${colors.accent}`,
                      color: colors.accent,
                    }}
                  />
                </Box>
              ))
            ) : (
              // Cardio workout history
              workoutState.exercises && workoutState.exercises.map((exercise, index) => {
                // Parse completed_sets if it's a string
                let completedSets = {};
                try {
                  completedSets = typeof exercise.completed_sets === 'string' 
                    ? JSON.parse(exercise.completed_sets) 
                    : exercise.completed_sets;
                } catch (e) {
                  console.error('Error parsing completed sets:', e);
                }

                return Object.entries(completedSets).map(([setNumber, setData]) => (
                  <Box
                    key={`${exercise.id}-${setNumber}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 2,
                      p: 2,
                      borderRadius: '12px',
                  bgcolor: colors.subtle,
                  border: `1px solid ${colors.border}`,
                    }}
                  >
                    <DirectionsRun sx={{ color: colors.accent }} />
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" sx={{ color: colors.text, fontWeight: 500 }}>
                        {exercise.name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: colors.muted }}>
                        {setData.distance} {setData.distance_unit} • {formatDuration(setData.duration)}
                        {setData.pace && ` • ${convertToMilesPace(setData.duration, setData.distance, setData.distance_unit)} min/mile`}
                      </Typography>
                    </Box>
                    <Chip 
                      label={`Activity ${index + 1}`}
                      size="small"
                      sx={{
                        bgcolor: 'transparent',
                        border: `1px solid ${colors.accent}`,
                        color: colors.accent,
                      }}
                    />
                  </Box>
                ));
              })
            )}
          </Box>
        </Paper>
      )}

      {/* Loading Dialog */}
      <Dialog 
        open={loading}
        PaperProps={{
          sx: {
            bgcolor: 'transparent',
            boxShadow: 'none',
          }
        }}
      >
        <DialogContent sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          py: 4, 
          px: 6,
          bgcolor: colors.primary,
          borderRadius: '16px',
          border: `1px solid ${colors.border}`,
        }}>
          <CircularProgress sx={{ color: colors.accent }} />
          <Typography sx={{ color: colors.text }}>
            Processing voice input...
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            bgcolor: colors.primary,
          }
        }}
      >
        {renderConfirmationContent()}
        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={() => setShowConfirmDialog(false)}
            sx={{
              color: colors.muted,
              textTransform: 'none',
              '&:hover': {
                color: colors.text,
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            variant="contained"
            sx={{
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              py: 1.5,
              px: 3,
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Workout Summary Dialog */}
      <Dialog
        open={showSummaryDialog}
        onClose={() => setShowSummaryDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            bgcolor: colors.primary,
          }
        }}
      >
        <DialogTitle sx={{ 
          color: colors.text,
          fontWeight: 500,
          borderBottom: `1px solid ${colors.border}`,
          pb: 2,
        }}>
          Workout Summary
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          <Grid container spacing={3}>
            {/* Basic Info */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Workout Name"
                value={workoutSummary.name}
                onChange={(e) => setWorkoutSummary(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Date"
                value={workoutSummary.date}
                onChange={(e) => setWorkoutSummary(prev => ({
                  ...prev,
                  date: e.target.value
                }))}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>

            {workoutType === 'strength' ? (
              // Strength-specific fields
              <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Workout Split (Optional)"
                value={workoutSummary.split}
                onChange={(e) => setWorkoutSummary(prev => ({
                  ...prev,
                  split: e.target.value
                }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              >
                {['push', 'pull', 'legs', 'upper', 'lower', 'full'].map((split) => (
                  <MenuItem 
                    key={split} 
                    value={split}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {split}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Training Phase"
                value={workoutSummary.trainingPhase}
                onChange={(e) => setWorkoutSummary(prev => ({
                  ...prev,
                  trainingPhase: e.target.value
                }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              >
                {['strength', 'hypertrophy', 'power', 'endurance'].map((phase) => (
                  <MenuItem 
                    key={phase} 
                    value={phase}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {phase}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

                {/* Strength Stats */}
            <Grid item xs={12}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: colors.subtle,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '16px',
                }}
              >
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: colors.text,
                    fontWeight: 500,
                  }}
                >
                  Workout Stats
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: colors.text }}>
                      Total Sets: {calculateWorkoutStats().totalSets}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: colors.text }}>
                      Total Volume: {calculateWorkoutStats().totalVolume} {weightUnit}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

                {/* Exercise List for Strength */}
            <Grid item xs={12}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '16px',
                }}
              >
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: colors.text,
                    fontWeight: 500,
                  }}
                >
                  Exercises
                </Typography>
                <List>
                  {workoutState.sets.map((set, index) => (
                    <ListItem 
                      key={index}
                      sx={{
                        borderBottom: index < workoutState.sets.length - 1 ? `1px solid ${colors.border}` : 'none',
                      }}
                    >
                      <ListItemText
                        primary={set.exercise_name}
                        secondary={`${set.reps} reps @ ${set.weight}${set.unit}`}
                        primaryTypographyProps={{
                          sx: { color: colors.text }
                        }}
                        secondaryTypographyProps={{
                          sx: { color: colors.muted }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
              </>
            ) : (
              // Cardio-specific fields
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Cardio Type"
                    value={workoutSummary.cardioType || 'running'}
                    onChange={(e) => setWorkoutSummary(prev => ({
                      ...prev,
                      cardioType: e.target.value
                    }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  >
                    {['running', 'cycling', 'swimming', 'rowing', 'other'].map((type) => (
                      <MenuItem 
                        key={type} 
                        value={type}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
            </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Intensity Level"
                    value={workoutSummary.intensityLevel || 'moderate'}
                    onChange={(e) => setWorkoutSummary(prev => ({
                      ...prev,
                      intensityLevel: e.target.value
                    }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  >
                    {['light', 'moderate', 'vigorous', 'high-intensity'].map((level) => (
                      <MenuItem 
                        key={level} 
                        value={level}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {level}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Cardio Stats */}
                <Grid item xs={12}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 3,
                      bgcolor: colors.subtle,
                      border: `1px solid ${colors.border}`,
                      borderRadius: '16px',
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{
                        color: colors.text,
                        fontWeight: 500,
                      }}
                    >
                      Workout Stats
                    </Typography>
                    <Grid container spacing={2}>
                      {workoutState.exercises && workoutState.exercises.map(exercise => {
                        let totalDistance = 0;
                        let totalDuration = 0;
                        let completedSets = {};

                        try {
                          completedSets = typeof exercise.completed_sets === 'string' 
                            ? JSON.parse(exercise.completed_sets) 
                            : exercise.completed_sets;

                          Object.values(completedSets).forEach(set => {
                            totalDistance += parseFloat(set.distance) || 0;
                            totalDuration += parseInt(set.duration) || 0;
                          });
                        } catch (e) {
                          console.error('Error parsing completed sets:', e);
                        }

                        return (
                          <React.Fragment key={exercise.id}>
                            <Grid item xs={4}>
                              <Typography sx={{ color: colors.muted }}>Total Distance</Typography>
                              <Typography sx={{ color: colors.text, fontWeight: 500 }}>
                                {totalDistance.toFixed(2)} {exercise.distance_unit || 'meters'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography sx={{ color: colors.muted }}>Total Duration</Typography>
                              <Typography sx={{ color: colors.text, fontWeight: 500 }}>
                                {formatDuration(totalDuration)}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography sx={{ color: colors.muted }}>Avg Pace</Typography>
                              <Typography sx={{ color: colors.text, fontWeight: 500 }}>
                                {convertToMilesPace(totalDuration, totalDistance, exercise.distance_unit || 'meters')} /mile
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Paper>
                </Grid>

                {/* Activity List for Cardio */}
                <Grid item xs={12}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 3,
                      border: `1px solid ${colors.border}`,
                      borderRadius: '16px',
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{
                        color: colors.text,
                        fontWeight: 500,
                      }}
                    >
                      Activities
                    </Typography>
                    <List>
                      {workoutState.exercises && workoutState.exercises.map(exercise => {
                        let completedSets = {};
                        try {
                          completedSets = typeof exercise.completed_sets === 'string' 
                            ? JSON.parse(exercise.completed_sets) 
                            : exercise.completed_sets;
                        } catch (e) {
                          console.error('Error parsing completed sets:', e);
                          return null;
                        }

                        return Object.entries(completedSets).map(([setNumber, setData]) => (
                          <ListItem 
                            key={`${exercise.id}-${setNumber}`}
                            sx={{
                              borderBottom: `1px solid ${colors.border}`,
                            }}
                          >
                            <ListItemText
                              primary={`${setData.distance} ${setData.distance_unit}`}
                              secondary={`Duration: ${formatDuration(setData.duration)} • Pace: ${convertToMilesPace(setData.duration, setData.distance, setData.distance_unit)} /mile`}
                              primaryTypographyProps={{
                                sx: { color: colors.text }
                              }}
                              secondaryTypographyProps={{
                                sx: { color: colors.muted }
                              }}
                            />
                          </ListItem>
                        ));
                      })}
                    </List>
                  </Paper>
                </Grid>
              </>
            )}

            {/* Notes - Common for both types */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Workout Notes"
                value={workoutSummary.notes}
                onChange={(e) => setWorkoutSummary(prev => ({
                  ...prev,
                  notes: e.target.value
                }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: `1px solid ${colors.border}` }}>
          <Button 
            onClick={() => setShowSummaryDialog(false)}
            sx={{
              color: colors.muted,
              textTransform: 'none',
              '&:hover': {
                color: colors.text,
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={endWorkout} 
            variant="contained"
            sx={{
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              py: 1.5,
              px: 3,
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
            }}
          >
            Save & End Workout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error/Success Messages */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mt: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }} 
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mt: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
          }} 
          onClose={() => setSuccess('')}
        >
          {success}
        </Alert>
      )}
    </Container>
  );
};

export default ActiveWorkoutLogger; 