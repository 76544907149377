import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Alert,
  Grid,
  OutlinedInput,
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const BACKEND_URL = 'localhost:8000';

const FITNESS_LEVELS = [
  'Beginner',
  'Intermediate',
  'Advanced'
];

const WORKOUT_PREFERENCES = [
  'HIIT',
  'Yoga',
  'Strength Training',
  'Cardio',
  'Pilates',
  'Bodyweight',
  'Weight Training',
  'CrossFit',
  'Calisthenics'
];

const HEALTH_CONDITIONS = [
  'None',
  'Lower back pain',
  'Knee pain',
  'Shoulder injury',
  'High blood pressure',
  'Arthritis',
  'Limited mobility',
  'Pregnancy',
  'Heart condition'
];

const GOALS = [
  'Improve overall fitness',
  'Build muscle',
  'Lose weight',
  'Increase flexibility',
  'Improve strength',
  'Enhance endurance',
  'Rehabilitation'
];

const GenerateAWorkoutPlan = () => {
  const [formData, setFormData] = useState({
    goal: '',
    customGoalText: '',
    health_conditions: [],
    customHealthCondition: '',
    fitness_level: '',
    preferences: [],
    schedule: {
      days_per_week: 3,
      session_duration: 30
    },
    plan_duration_weeks: 4,
    custom_goals: [],
    customGoalsText: '',
    lang: 'en'
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [workoutPlan, setWorkoutPlan] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleScheduleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        [name]: parseInt(value)
      }
    }));
  };

  const handleMultiSelectChange = (event, field) => {
    const { value } = event.target;
    setFormData(prev => ({
      ...prev,
      [field]: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const handleCustomGoalsChange = (event) => {
    const text = event.target.value;
    const goals = text.split('\n').filter(goal => goal.length > 0);
    
    setFormData(prev => ({
      ...prev,
      custom_goals: goals,
      customGoalsText: text
    }));
  };

  const handleCustomHealthCondition = (event) => {
    const value = event.target.value.trim();
    if (value && !formData.health_conditions.includes(value)) {
      setFormData(prev => ({
        ...prev,
        health_conditions: [...prev.health_conditions, value],
        customHealthCondition: ''
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setWorkoutPlan(null);

    try {
      const response = await fetch(`http://${BACKEND_URL}/generateWorkoutPlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate workout plan');
      }

      const data = await response.json();
      setWorkoutPlan(data.result);
    } catch (err) {
      setError(err.message);
      console.error('Error generating workout plan:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderWorkoutPlan = () => {
    if (!workoutPlan) return null;

    return (
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          {workoutPlan.seo_title}
        </Typography>
        
        <Typography variant="body1" paragraph>
          {workoutPlan.seo_content}
        </Typography>

        <Grid container spacing={3}>
          {workoutPlan.exercises.map((day, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {day.day}
                </Typography>
                {day.exercises.map((exercise, exIndex) => (
                  <Paper 
                    key={exIndex} 
                    elevation={1} 
                    sx={{ p: 1, mb: 1, bgcolor: 'background.default' }}
                  >
                    <Typography variant="subtitle1">
                      {exercise.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Duration: {exercise.duration}<br />
                      Sets: {exercise.sets}<br />
                      Reps: {exercise.repetitions}<br />
                      Equipment: {exercise.equipment || 'None'}
                    </Typography>
                  </Paper>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FitnessCenterIcon fontSize="large" />
        Generate Workout Plan
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Main Goal</InputLabel>
                <Select
                  name="goal"
                  value={formData.goal}
                  onChange={handleChange}
                  label="Main Goal"
                  required
                >
                  <MenuItem value="">
                    <em>Select a goal or type below</em>
                  </MenuItem>
                  {GOALS.map(goal => (
                    <MenuItem key={goal} value={goal}>
                      {goal}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Or type your own goal"
                name="customGoalText"
                value={formData.customGoalText}
                onChange={(e) => {
                  setFormData(prev => ({
                    ...prev,
                    customGoalText: e.target.value,
                    goal: e.target.value
                  }));
                }}
                sx={{ mt: 1 }}
                helperText="If your goal isn't listed above, type it here"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Fitness Level</InputLabel>
                <Select
                  name="fitness_level"
                  value={formData.fitness_level}
                  onChange={handleChange}
                  label="Fitness Level"
                  required
                >
                  {FITNESS_LEVELS.map(level => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Workout Preferences</InputLabel>
                <Select
                  multiple
                  name="preferences"
                  value={formData.preferences}
                  onChange={(e) => handleMultiSelectChange(e, 'preferences')}
                  input={<OutlinedInput label="Workout Preferences" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {WORKOUT_PREFERENCES.map(pref => (
                    <MenuItem key={pref} value={pref}>
                      {pref}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Health Conditions</InputLabel>
                <Select
                  multiple
                  name="health_conditions"
                  value={formData.health_conditions}
                  onChange={(e) => handleMultiSelectChange(e, 'health_conditions')}
                  input={<OutlinedInput label="Health Conditions" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value}
                          onDelete={() => {
                            setFormData(prev => ({
                              ...prev,
                              health_conditions: prev.health_conditions.filter(h => h !== value)
                            }));
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {HEALTH_CONDITIONS.map(condition => (
                    <MenuItem key={condition} value={condition}>
                      {condition}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  label="Add custom health condition"
                  value={formData.customHealthCondition}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    customHealthCondition: e.target.value
                  }))}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && formData.customHealthCondition) {
                      e.preventDefault();
                      handleCustomHealthCondition(e);
                    }
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomHealthCondition}
                  disabled={!formData.customHealthCondition}
                  sx={{ minWidth: '120px' }}
                >
                  Add
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Days per Week"
                name="days_per_week"
                value={formData.schedule.days_per_week}
                onChange={handleScheduleChange}
                inputProps={{ min: 1, max: 7 }}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Session Duration (minutes)"
                name="session_duration"
                value={formData.schedule.session_duration}
                onChange={handleScheduleChange}
                inputProps={{ min: 15, max: 120, step: 5 }}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Plan Duration (weeks)"
                name="plan_duration_weeks"
                value={formData.plan_duration_weeks}
                onChange={handleChange}
                inputProps={{ min: 1, max: 12 }}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Custom Goals"
                placeholder="Enter each goal on a new line (e.g.&#10;Increase flexibility&#10;Improve core strength&#10;Better posture)"
                value={formData.customGoalsText}
                onChange={handleCustomGoalsChange}
                helperText="Enter each specific goal on a new line"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? 'Generating Plan...' : 'Generate Workout Plan'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {renderWorkoutPlan()}
    </Box>
  );
};

export default GenerateAWorkoutPlan;
