import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Layout from './components/Layout';
import Register from "./components/Register";
import SignIn from "./components/Signin";
import VerifyLogin from "./components/VerifyLogin";
import VerifyRegister from "./components/VerifyRegister";
import { UserProvider } from "./components/UserContext";
import Dashboard from "./components/Dashboard";
import ExerciseDB from './components/ExerciseDB';
import DietPlanner from './components/DietPlanner';
import WorkoutPlanner from './components/WorkoutPlanner';
import NutritionCalculator from './components/NutritionCalculator';
import NutritionAdvice from './components/NutritionAdvice';
import Profile from './components/Profile';
import LogMeal from './components/LogMeal';
import LogWorkoutCardio from './components/LogWorkoutCardio';
import LogWorkoutGym from './components/LogWorkoutGym';
import UserProfile from './components/UserProfile';
import CreateMeal from './components/CreateMeal';
import CreateWorkout from './components/CreateWorkout';
import UserCalendar from './components/UserCalendar';
import Jamiro from './components/Jamiro';
import Goals from './components/Goals';
import Workouts from './components/Workouts'; // Import the new WorkoutList component
import AnalyzeFood from './components/AnalyzeFood';
import RecipeGenerator from './components/RecipeGenerator';
import GenerateAWorkoutPlan from './components/GenerateAWorkoutPlan';
import MealPlan from './components/MealPlan';
import ClientDashboard from './components/ClientDashboard';
import AdminDashboard from './components/AdminDashboard';
import WorkoutLogger from './components/WorkoutLogger';
import MealLogger from './components/MealLogger';
import MealPlanner from './components/MealPlanner';
import DashboardWorkouts from './components/DashboardWorkouts';
import DashboardWorkoutDetails from './components/DashboardWorkoutDetails';
import DashboardNutrition from './components/DashboardNutrition';
import DashboardProgress from './components/DashboardProgress';
import DashboardAnalytics from './components/DashboardAnalytics';
import DashboardExercises from './components/DashboardExercises';
import DashboardNutritionDetails from './components/DashboardNutritionDetails';
import Feedback from './components/Feedback';
import ActiveWorkoutLogger from './components/ActiveWorkoutLogger';
import Home from './components/Home';


function App() {
  const [filters, setFilters] = useState({});

  const handleSearch = (filterData) => {
    setFilters(filterData);
  };

  return (
    <Router>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-login/:token" element={<VerifyLogin />} />
            <Route path="/verify-email/:token" element={<VerifyRegister />} />
            <Route path="/exercises" element={<ExerciseDB />} />
            <Route path="/workout-planner" element={<WorkoutPlanner />} />
            <Route path="/diet-planner" element={<DietPlanner />} />
            <Route path="/nutrition-calculator" element={<NutritionCalculator />} />
            <Route path="/nutrition-advice" element={<NutritionAdvice />} />
            <Route path="/generate-a-workout-plan" element={<GenerateAWorkoutPlan />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/log-meal" element={<LogMeal />} />
            <Route path="/log-workout/cardio" element={<LogWorkoutCardio />} />
            <Route path="/log-workout/gym" element={<LogWorkoutGym />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/goals" element={<Goals />} />
            <Route path="/workouts" element={<Workouts />} />
            <Route path="/create-meal" element={<CreateMeal />} />
            <Route path="/create-workout" element={<CreateWorkout />} />
            <Route path="/calendar" element={<UserCalendar />} />
            <Route path="/jamiroperpageurl" element={<Jamiro />} />
            <Route path="/analyze-food" element={<AnalyzeFood />} />
            <Route path="/recipe-generator" element={<RecipeGenerator />} />
            <Route path="/meal-plan" element={<MealPlan />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/dashboard-workouts" element={<DashboardWorkouts />} />
            <Route path="/dashboard-workout-details/:id" element={<DashboardWorkoutDetails />} />
            <Route path="/dashboard-nutrition" element={<DashboardNutrition />} />
            <Route path="/dashboard-nutrition-details/:id" element={<DashboardNutritionDetails />} />
            <Route path="/dashboard-progress" element={<DashboardProgress />} />
            <Route path="/dashboard-analytics" element={<DashboardAnalytics />} />
            <Route path="/dashboard-exercises" element={<DashboardExercises />} />
            <Route path="/client-dashboard" element={<ClientDashboard />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/workout-logger" element={<WorkoutLogger />} />
            <Route path="/meal-logger" element={<MealLogger />} />
            <Route path="/meal-planner" element={<MealPlanner />} />
            <Route path="/active-workout" element={<ActiveWorkoutLogger />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Layout>
      </UserProvider>
    </Router>
  );
}




export default App;