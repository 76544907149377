import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import { useParams, useNavigate } from 'react-router-dom';

function VerifyLogin() {
    const { setUser } = useUser();
    const { token } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyLogin = async () => {
            try {
                console.log("Verifying login with token:", token);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/verify-login/${token}`);
                const result = await response.json();
                console.log("Verification response:", result);

                if (response.ok && result.success) {
                    console.log("Login verified successfully");
                    
                    // Store tokens
                    localStorage.setItem('authToken', result.access_token);
                    localStorage.setItem('sessionToken', result.session_token);
                    
                    // Update user context
                    setUser({ token: result.access_token });
                    
                    console.log("Navigating to dashboard");
                    navigate('/client-dashboard');
                } else {
                    console.error("Verification failed:", result.detail || 'Unknown error');
                    setError(result.detail || 'Verification failed');
                    setTimeout(() => {
                        console.log("Redirecting to login due to verification failure");
                        navigate('/signin');
                    }, 3000);
                }
            } catch (error) {
                console.error('Verification error:', error);
                setError('An unexpected error occurred');
                setTimeout(() => {
                    console.log("Redirecting to login due to unexpected error");
                    navigate('/signin');
                }, 3000);
            }
        };

        verifyLogin();
    }, [token, setUser, navigate]);

    if (error) {
        return <div>Error: {error}. Redirecting to login...</div>;
    }

    return <div>Verifying login...</div>;
}

export default VerifyLogin;