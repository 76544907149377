import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Divider,
} from '@mui/material';
import { FitnessCenter } from '@mui/icons-material';

// Add theme colors
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

function Login() {
  const [email, setEmail] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Attempting to login with:', email);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/request-magic-link/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Magic link sent:', result);
        setLoginStatus('Magic link sent. Please check your inbox.');
        window.alert(`Magic link sent. Please check your inbox at ${email}`);
      } else {
        console.error('Login failed:', response.status);

        if (response.status === 404 && result.code === 'USER_NOT_REGISTERED') {
          setLoginStatus('Account not found. Redirecting to registration...');
          setTimeout(() => {
            navigate('/register', {
              state: { email }
            });
          }, 2000);
          return;
        }

        if (response.status === 400 && result.code === 'EMAIL_NOT_VERIFIED') {
          setLoginStatus('Please verify your email first. Check your inbox for the verification link.');
          return;
        }

        setLoginStatus(result.message || 'Failed to send magic link');
        window.alert(result.message || 'Failed to send magic link');
      }
    } catch (error) {
      console.error('Error processing login:', error);
      setLoginStatus('Error processing login');
      window.alert('Error processing login');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: colors.primary,
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          {/* Header */}
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Box
              sx={{
                width: 64,
                height: 64,
                borderRadius: '16px',
                bgcolor: colors.subtle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 'auto',
                mb: 2,
              }}
            >
              <FitnessCenter sx={{ fontSize: 32, color: colors.accent }} />
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: colors.text,
                fontWeight: 600,
                fontSize: { xs: '1.75rem', md: '2rem' },
                mb: 1,
                letterSpacing: '-0.02em',
              }}
            >
              Welcome Back
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.muted,
                fontWeight: 400,
                maxWidth: '400px',
                mx: 'auto',
              }}
            >
              Sign in to continue to your dashboard
            </Typography>
          </Box>

          {/* Login Form */}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  height: 48,
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                height: 48,
                bgcolor: colors.accent,
                color: colors.primary,
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '1rem',
                '&:hover': {
                  bgcolor: colors.accent,
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              Sign In
            </Button>
          </form>

          {loginStatus && (
            <Alert
              severity={loginStatus.includes('Error') ? 'error' : 'info'}
              sx={{
                mt: 3,
                borderRadius: '12px',
              }}
            >
              {loginStatus}
            </Alert>
          )}

          <Divider sx={{ my: 4 }} />

          {/* Register Link */}
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="body1"
              sx={{
                color: colors.muted,
                mb: 2,
              }}
            >
              Don't Have an Account?
            </Typography>
            <Button
              component={Link}
              to="/register"
              variant="outlined"
              fullWidth
              sx={{
                height: 48,
                borderColor: colors.border,
                color: colors.text,
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '1rem',
                '&:hover': {
                  borderColor: colors.accent,
                  bgcolor: colors.subtle,
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              Create Account
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default Login;