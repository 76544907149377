import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  IconButton,
  Chip,
  Tooltip,
  Grid,
} from '@mui/material';
import {
  Search,
  Add as AddIcon,
  Notifications,
  FilterList,
  FitnessCenter,
  Timer,
} from '@mui/icons-material';

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const DashboardExercises = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [exercises, setExercises] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [timeFilter, setTimeFilter] = useState('last_7_days');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

  // Remove hardcoded URL and use environment variable
  const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

  // Fetch exercises when time filter changes
  useEffect(() => {
    fetchExercises();
  }, [timeFilter]);

  // Fetch exercises when search query changes (with debounce)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchExercises();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const fetchExercises = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/signin');
        return;
      }

      setLoading(true);
      let url = `${BACKEND_URL}/exercises?time_period=${timeFilter}`;
      if (searchQuery) {
        url += `&search=${encodeURIComponent(searchQuery)}`;
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/signin');
          return;
        }
        throw new Error('Failed to fetch exercises');
      }

      const data = await response.json();
      console.log('Fetched exercises data:', data);
      
      // Sort the data immediately after fetching
      const sortedData = sortData(data);
      setExercises(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching exercises:', error);
      setLoading(false);
    }
  };

  // Enhanced sorting function to handle both types of exercises
  const sortData = (data) => {
    return [...data].sort((a, b) => {
      if (sortConfig.key === 'date') {
        const dateA = a.date ? new Date(a.date) : new Date(0);
        const dateB = b.date ? new Date(b.date) : new Date(0);
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (sortConfig.key === 'weight') {
        const weightA = parseFloat(a.weight) || 0;
        const weightB = parseFloat(b.weight) || 0;
        return sortConfig.direction === 'asc' ? weightA - weightB : weightB - weightA;
      }
      if (sortConfig.key === 'name') {
        return sortConfig.direction === 'asc' 
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      const newConfig = {
        key,
        direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
      };
      const sortedData = sortData([...exercises]);
      setExercises(sortedData);
      return newConfig;
    });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderExerciseSets = (exercise) => {
    const sets = Array.isArray(exercise.sets) ? exercise.sets : [];
    
    return sets.map((set, index) => (
      <TableRow
        key={`${exercise.id}-set-${set.set_number}`}
        sx={{
          bgcolor: index === 0 ? colors.primary : colors.subtle,
          transition: 'all 0.2s ease',
          '&:hover': { 
            bgcolor: colors.cardHover,
            transform: 'translateY(-1px)',
          }
        }}
      >
        {index === 0 ? (
          <>
            <TableCell 
              rowSpan={sets.length}
              sx={{
                color: colors.text,
                fontWeight: 500,
                borderBottom: `1px solid ${colors.border}`,
                fontSize: '0.875rem',
              }}
            >
              {exercise.date ? new Date(exercise.date).toLocaleDateString() : '-'}
            </TableCell>
            <TableCell 
              rowSpan={sets.length}
              sx={{
                borderBottom: `1px solid ${colors.border}`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title={getExerciseDetails(exercise)}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                  }}>
                    {exercise.source === 'active' ? (
                      <Timer sx={{ 
                        fontSize: 20,
                        color: colors.accent,
                      }} />
                    ) : (
                      <FitnessCenter sx={{ 
                        fontSize: 20,
                        color: colors.accent,
                      }} />
                    )}
                    <Typography sx={{ 
                      fontWeight: 500,
                      color: colors.text,
                      fontSize: '0.875rem',
                    }}>
                      {exercise.name}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </TableCell>
          </>
        ) : null}
        <TableCell 
          align="center"
          sx={{
            borderBottom: `1px solid ${colors.border}`,
            fontSize: '0.875rem',
            color: colors.text,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            {set.reps}
          </Typography>
        </TableCell>
        <TableCell 
          align="center"
          sx={{
            borderBottom: `1px solid ${colors.border}`,
            fontSize: '0.875rem',
            color: colors.text,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            {set.weight}
          </Typography>
          <Typography 
            component="span" 
            sx={{ 
              color: colors.muted,
              fontSize: '0.75rem',
              ml: 0.5,
            }}
          >
            {set.unit || 'lbs'}
          </Typography>
        </TableCell>
        <TableCell 
          align="center"
          sx={{
            borderBottom: `1px solid ${colors.border}`,
            fontSize: '0.875rem',
            color: colors.muted,
          }}
        >
          {set.set_number}
        </TableCell>
        {index === 0 ? (
          <TableCell 
            align="center" 
            rowSpan={sets.length}
            sx={{
              borderBottom: `1px solid ${colors.border}`,
            }}
          >
            <Chip 
              label={exercise.source === 'active' ? 'Real-time' : 'Logged'} 
              size="small"
              sx={{
                bgcolor: exercise.source === 'active' ? colors.accent : 'transparent',
                color: exercise.source === 'active' ? colors.primary : colors.accent,
                border: `1px solid ${colors.accent}`,
                fontWeight: 500,
                fontSize: '0.75rem',
                height: 24,
                '& .MuiChip-label': {
                  px: 1,
                },
              }}
            />
          </TableCell>
        ) : null}
      </TableRow>
    ));
  };

  // Get exercise details for tooltip
  const getExerciseDetails = (exercise) => {
    const details = [];
    
    if (exercise.source === 'active') {
      details.push('Real-time tracked exercise');
      if (exercise.sets && exercise.sets.length > 0) {
        details.push(`Completed ${exercise.sets.length} sets`);
      }
    } else {
      if (exercise.target_muscle_group) details.push(`Target: ${exercise.target_muscle_group}`);
      if (exercise.equipment) details.push(`Equipment: ${exercise.equipment}`);
      if (exercise.one_rep_max) details.push(`1RM: ${exercise.one_rep_max}${exercise.sets[0]?.unit || 'lbs'}`);
    }
    
    if (exercise.notes) details.push(exercise.notes);
    
    return details.join(' • ');
  };

  if (loading) {
    return (
      <Box 
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <CircularProgress sx={{ color: colors.accent }} />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      p: { xs: 3, md: 4 },
      bgcolor: colors.primary,
      maxWidth: '1200px',
      mx: 'auto'
    }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: { xs: 3, md: 4 }
      }}>
        <Box>
          <Typography 
            variant="h4" 
            sx={{ 
              fontSize: { xs: '1.5rem', md: '2rem' },
              fontWeight: 600,
              color: colors.text,
              mb: 1,
              letterSpacing: '-0.02em',
            }}
          >
            Exercise History
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: colors.muted,
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            Track your progress and analyze your performance
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon sx={{ fontSize: 24 }} />}
          onClick={() => navigate('/workout')}
          sx={{
            height: 48,
            px: 3,
            bgcolor: colors.accent,
            color: colors.primary,
            borderRadius: '12px',
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              bgcolor: colors.accent,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
            },
            transition: 'all 0.2s ease',
          }}
        >
          Log Exercise
        </Button>
      </Box>

      {/* Search and Filter Bar */}
      <Paper 
        elevation={0}
        sx={{ 
          p: { xs: 2, md: 3 },
          mb: { xs: 3, md: 4 },
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <TextField
              placeholder="Search exercises..."
              value={searchQuery}
              onChange={handleSearch}
              fullWidth
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  height: 48,
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: colors.muted, fontSize: 24 }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              fullWidth
              sx={{ 
                height: 48,
                borderRadius: '12px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.border,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.accent,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.accent,
                },
              }}
            >
              <MenuItem value="last_7_days">Last 7 Days</MenuItem>
              <MenuItem value="last_30_days">Last 30 Days</MenuItem>
              <MenuItem value="all_time">All Time</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Paper>

      {/* Exercise Table */}
      <Paper 
        elevation={0}
        sx={{ 
          border: `1px solid ${colors.border}`,
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { label: 'Date', key: 'date' },
                  { label: 'Exercise', key: 'name' },
                  { label: 'Reps', key: null },
                  { label: 'Weight', key: 'weight' },
                  { label: 'Set', key: null },
                  { label: 'Type', key: null }
                ].map((column) => (
                  <TableCell 
                    key={column.label}
                    align={column.label === 'Date' || column.label === 'Exercise' ? 'left' : 'center'}
                    onClick={() => column.key && handleSort(column.key)}
                    sx={{ 
                      color: colors.text,
                      fontWeight: 600,
                      cursor: column.key ? 'pointer' : 'default',
                      bgcolor: colors.subtle,
                      borderBottom: `1px solid ${colors.border}`,
                      py: 2,
                      fontSize: '0.875rem',
                      transition: 'all 0.2s ease',
                      '&:hover': column.key ? {
                        color: colors.accent,
                        bgcolor: `${colors.subtle}`,
                      } : {},
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: column.label === 'Date' || column.label === 'Exercise' ? 'flex-start' : 'center',
                      gap: 1
                    }}>
                      {column.label}
                      {column.key === sortConfig.key && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: colors.accent,
                            fontWeight: 600,
                          }}
                        >
                          {sortConfig.direction === 'asc' ? '↑' : '↓'}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {exercises.length > 0 ? (
                exercises.map((exercise) => renderExerciseSets(exercise))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box sx={{ 
                      textAlign: 'center', 
                      py: 4 
                    }}>
                      <Typography 
                        variant="h3" 
                        sx={{ 
                          fontSize: '2rem',
                          fontWeight: 600,
                          color: colors.text,
                          mb: 1,
                        }}
                      >
                        0
                        <Typography 
                          component="span" 
                          variant="h6" 
                          sx={{ 
                            color: colors.muted,
                            ml: 1,
                            fontWeight: 400,
                          }}
                        >
                          Exercises
                        </Typography>
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: colors.muted,
                          fontWeight: 300,
                          lineHeight: 1.6,
                        }}
                      >
                        Start logging your workouts to see them here
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default DashboardExercises; 