import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Alert,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const Profile = () => {
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;
  const [personalDetails, setPersonalDetails] = useState({
    name: '',
    height: '',
    weight: '',
    weight_unit: 'kg'
  });
  const [oneRepMaxes, setOneRepMaxes] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMax, setCurrentMax] = useState({
    exercise_name: '',
    weight: '',
    reps: '',
    notes: '',
  });

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/signin');
        return;
      }

      const [profileResponse, maxesResponse] = await Promise.all([
        fetch(`${BACKEND_URL}/profile`, {
          headers: { 'Authorization': `Bearer ${authToken}` }
        }),
        fetch(`${BACKEND_URL}/one-rep-maxes`, {
          headers: { 'Authorization': `Bearer ${authToken}` }
        })
      ]);

      if (!profileResponse.ok || !maxesResponse.ok) {
        throw new Error('Failed to fetch profile data');
      }

      const profileData = await profileResponse.json();
      const maxesData = await maxesResponse.json();

      setPersonalDetails(profileData);
      setOneRepMaxes(maxesData);
    } catch (error) {
      setError('Failed to load profile data');
      console.error('Error:', error);
    }
  };

  const handlePersonalDetailsSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BACKEND_URL}/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(personalDetails),
      });

      if (!response.ok) throw new Error('Failed to update profile');
      
      setSuccess('Profile updated successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      setError('Failed to update profile');
      console.error('Error:', error);
    }
  };

  const handleOneRepMaxSubmit = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/one-rep-maxes`, {
        method: currentMax.id ? 'PUT' : 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentMax),
      });

      if (!response.ok) throw new Error('Failed to save one rep max');

      setOpenDialog(false);
      fetchProfileData();
      setSuccess('One rep max saved successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      setError('Failed to save one rep max');
      console.error('Error:', error);
    }
  };

  const handleDeleteMax = async (id) => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;

    try {
      const response = await fetch(`${BACKEND_URL}/one-rep-maxes/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to delete one rep max');

      fetchProfileData();
      setSuccess('Record deleted successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      setError('Failed to delete record');
      console.error('Error:', error);
    }
  };

  // Add unit conversion helpers
  const convertWeight = (value, fromUnit, toUnit) => {
    if (!value) return '';
    value = parseFloat(value);
    if (fromUnit === toUnit) return value;
    return toUnit === 'kg' ? value * 0.453592 : value * 2.20462;
  };

  const handleUnitChange = (newUnit) => {
    if (newUnit !== personalDetails.weight_unit) {
      setPersonalDetails(prev => ({
        ...prev,
        weight_unit: newUnit,
        weight: prev.weight ? convertWeight(prev.weight, prev.weight_unit, newUnit).toFixed(2) : ''
      }));
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
          }}
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mb: 2,
            borderRadius: '12px',
          }}
        >
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Personal Details Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3,
              border: `1px solid ${colors.border}`,
              borderRadius: '16px',
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3,
                color: colors.text,
                fontWeight: 600,
                fontSize: '1.25rem',
              }}
            >
              Personal Details
            </Typography>
            <form onSubmit={handlePersonalDetailsSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={personalDetails.name || ''}
                    onChange={(e) => setPersonalDetails({ ...personalDetails, name: e.target.value })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Height (cm)"
                    type="number"
                    value={personalDetails.height || ''}
                    onChange={(e) => setPersonalDetails({ ...personalDetails, height: e.target.value })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={`Weight (${personalDetails.weight_unit})`}
                    type="number"
                    value={personalDetails.weight || ''}
                    onChange={(e) => setPersonalDetails({ ...personalDetails, weight: e.target.value })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: colors.border,
                        },
                        '&:hover fieldset': {
                          borderColor: colors.accent,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.accent,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Button
                      variant={personalDetails.weight_unit === 'kg' ? 'contained' : 'outlined'}
                      onClick={() => handleUnitChange('kg')}
                      sx={{
                        borderRadius: '12px',
                        textTransform: 'none',
                        px: 3,
                        bgcolor: personalDetails.weight_unit === 'kg' ? colors.accent : 'transparent',
                        borderColor: colors.accent,
                        color: personalDetails.weight_unit === 'kg' ? colors.primary : colors.accent,
                        '&:hover': {
                          bgcolor: personalDetails.weight_unit === 'kg' ? colors.accent : colors.subtle,
                          borderColor: colors.accent,
                        },
                      }}
                    >
                      kg
                    </Button>
                    <Button
                      variant={personalDetails.weight_unit === 'lbs' ? 'contained' : 'outlined'}
                      onClick={() => handleUnitChange('lbs')}
                      sx={{
                        borderRadius: '12px',
                        textTransform: 'none',
                        px: 3,
                        bgcolor: personalDetails.weight_unit === 'lbs' ? colors.accent : 'transparent',
                        borderColor: colors.accent,
                        color: personalDetails.weight_unit === 'lbs' ? colors.primary : colors.accent,
                        '&:hover': {
                          bgcolor: personalDetails.weight_unit === 'lbs' ? colors.accent : colors.subtle,
                          borderColor: colors.accent,
                        },
                      }}
                    >
                      lbs
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    type="submit" 
                    variant="contained"
                    sx={{
                      height: 48,
                      px: 3,
                      bgcolor: colors.accent,
                      color: colors.primary,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontWeight: 500,
                      '&:hover': {
                        bgcolor: colors.accent,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                      },
                      transition: 'all 0.2s ease',
                    }}
                  >
                    Save Details
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* One Rep Max Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3,
              border: `1px solid ${colors.border}`,
              borderRadius: '16px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography 
                variant="h6"
                sx={{ 
                  color: colors.text,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                One Rep Maxes
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setCurrentMax({ exercise_name: '', weight: '', reps: '', notes: '' });
                  setOpenDialog(true);
                }}
                sx={{
                  height: 48,
                  px: 3,
                  bgcolor: colors.accent,
                  color: colors.primary,
                  borderRadius: '12px',
                  textTransform: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    bgcolor: colors.accent,
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                  },
                  transition: 'all 0.2s ease',
                }}
              >
                Add New
              </Button>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        color: colors.text,
                        fontWeight: 600,
                        borderBottom: `1px solid ${colors.border}`,
                        fontSize: '0.875rem',
                      }}
                    >
                      Exercise
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: colors.text,
                        fontWeight: 600,
                        borderBottom: `1px solid ${colors.border}`,
                        fontSize: '0.875rem',
                      }}
                    >
                      Weight ({personalDetails.weight_unit})
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: colors.text,
                        fontWeight: 600,
                        borderBottom: `1px solid ${colors.border}`,
                        fontSize: '0.875rem',
                      }}
                    >
                      Reps
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: colors.text,
                        fontWeight: 600,
                        borderBottom: `1px solid ${colors.border}`,
                        fontSize: '0.875rem',
                      }}
                    >
                      One Rep Max ({personalDetails.weight_unit})
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: colors.text,
                        fontWeight: 600,
                        borderBottom: `1px solid ${colors.border}`,
                        fontSize: '0.875rem',
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oneRepMaxes.map((max) => (
                    <TableRow 
                      key={max.id}
                      sx={{
                        transition: 'all 0.2s ease',
                        '&:hover': { 
                          bgcolor: colors.cardHover,
                          transform: 'translateY(-1px)',
                        }
                      }}
                    >
                      <TableCell 
                        sx={{ 
                          color: colors.text,
                          borderBottom: `1px solid ${colors.border}`,
                          fontSize: '0.875rem',
                        }}
                      >
                        {max.exercise_name}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: colors.text,
                          borderBottom: `1px solid ${colors.border}`,
                          fontSize: '0.875rem',
                        }}
                      >
                        {Number(max.weight).toFixed(1)}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: colors.text,
                          borderBottom: `1px solid ${colors.border}`,
                          fontSize: '0.875rem',
                        }}
                      >
                        {max.reps}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: colors.text,
                          borderBottom: `1px solid ${colors.border}`,
                          fontSize: '0.875rem',
                          fontWeight: 500,
                        }}
                      >
                        {Number(max.calculated_max).toFixed(1)}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          borderBottom: `1px solid ${colors.border}`,
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            setCurrentMax(max);
                            setOpenDialog(true);
                          }}
                          sx={{
                            minWidth: 'auto',
                            p: 1,
                            color: colors.accent,
                            '&:hover': {
                              bgcolor: colors.subtle,
                            },
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          size="small"
                          onClick={() => handleDeleteMax(max.id)}
                          sx={{
                            minWidth: 'auto',
                            p: 1,
                            color: '#FF4842',
                            '&:hover': {
                              bgcolor: 'rgba(255, 72, 66, 0.08)',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* One Rep Max Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: '100%',
            maxWidth: '500px',
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            color: colors.text,
            fontWeight: 600,
          }}
        >
          {currentMax.id ? 'Edit One Rep Max' : 'Add One Rep Max'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Exercise Name"
                value={currentMax.exercise_name}
                onChange={(e) => setCurrentMax({ ...currentMax, exercise_name: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={`Weight (${personalDetails.weight_unit})`}
                type="number"
                value={currentMax.weight}
                onChange={(e) => setCurrentMax({ ...currentMax, weight: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Reps"
                type="number"
                value={currentMax.reps}
                onChange={(e) => setCurrentMax({ ...currentMax, reps: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={2}
                value={currentMax.notes || ''}
                onChange={(e) => setCurrentMax({ ...currentMax, notes: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: colors.border,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.accent,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.accent,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{
              borderRadius: '12px',
              textTransform: 'none',
              color: colors.text,
              '&:hover': {
                bgcolor: colors.subtle,
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleOneRepMaxSubmit} 
            variant="contained"
            sx={{
              height: 48,
              px: 3,
              bgcolor: colors.accent,
              color: colors.primary,
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                bgcolor: colors.accent,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
              },
              transition: 'all 0.2s ease',
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profile;






