import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import { Send as SendIcon, FeedbackOutlined } from '@mui/icons-material';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const Feedback = () => {
  const [feedback, setFeedback] = useState('');
  const [category, setCategory] = useState('general');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const categories = [
    { value: 'general', label: 'General Feedback' },
    { value: 'bug', label: 'Bug Report' },
    { value: 'feature_request', label: 'Feature Request' },
    { value: 'improvement', label: 'Improvement Suggestion' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`${BACKEND_URL}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          feedback_text: feedback,
          category: category
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }

      const data = await response.json();
      
      setSnackbar({
        open: true,
        message: 'Thank you for your feedback! We appreciate your input.',
        severity: 'success'
      });
      
      // Clear form
      setFeedback('');
      setCategory('general');
      
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to submit feedback. Please try again.',
        severity: 'error'
      });
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        bgcolor: colors.primary,
        py: 4,
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          {/* Header */}
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Box
              sx={{
                width: 64,
                height: 64,
                borderRadius: '16px',
                bgcolor: colors.subtle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 'auto',
                mb: 2,
              }}
            >
              <FeedbackOutlined sx={{ fontSize: 32, color: colors.accent }} />
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: colors.text,
                fontWeight: 600,
                fontSize: { xs: '1.75rem', md: '2rem' },
                mb: 1,
                letterSpacing: '-0.02em',
              }}
            >
              Help Us Improve
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.muted,
                fontWeight: 400,
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.6,
              }}
            >
              We value your feedback! Let us know how we can make MiroFitness better for you.
              Your input helps us prioritize new features and improvements.
            </Typography>
          </Box>

          {/* Feedback Form */}
          <form onSubmit={handleSubmit}>
            <TextField
              select
              fullWidth
              label="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  height: 48,
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              multiline
              rows={6}
              label="Your Feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Share your thoughts, suggestions, or report any issues..."
              required
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.accent,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.accent,
                  },
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              disabled={!feedback.trim()}
              sx={{
                height: 48,
                px: 4,
                bgcolor: colors.accent,
                color: colors.primary,
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '1rem',
                '&:hover': {
                  bgcolor: colors.accent,
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
                },
                transition: 'all 0.2s ease',
                '&:disabled': {
                  bgcolor: 'rgba(0, 163, 255, 0.12)',
                  color: 'rgba(0, 163, 255, 0.4)',
                },
              }}
            >
              Submit Feedback
            </Button>
          </form>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            elevation={0}
            sx={{ 
              width: '100%',
              minWidth: '300px',
              borderRadius: '12px',
              border: `1px solid ${colors.border}`,
              fontSize: '0.875rem',
              alignItems: 'center',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Feedback; 