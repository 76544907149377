import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useUser } from './UserContext';
import { Card, CardContent, Grid, Typography, LinearProgress, Box, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const COLORS = ['#0088FE', '#000000', '#4A25E1', '#4A25E1'];

// Define specific goal types and their configurations
const GOAL_TYPES = {
  STRENGTH: {
    id: 'strength',
    label: 'Strength',
    exercises: [
      'Bench Press',
      'Squat',
      'Deadlift',
      'Clean & Jerk',
      'Snatch',
      'Overhead Press',
      'Power Clean',
      'Front Squat',
    ],
    units: ['lbs', 'kg'],
    defaultUnit: 'lbs'
  },
  CARDIO: {
    id: 'cardio',
    label: 'Cardio',
    types: [
      '5K Run',
      '10K Run',
      'Half Marathon',
      'Marathon',
      '400m Sprint',
      '1 Mile Run',
      'Swimming',
      'Cycling'
    ],
    units: ['time', 'distance'],
    timeFormats: ['mm:ss', 'hh:mm:ss'],
    distanceUnits: ['miles', 'km', 'meters']
  },
  NUTRITION: {
    id: 'nutrition',
    label: 'Nutrition',
    types: [
      'Daily Calories',
      'Daily Protein (g)',
      'Daily Carbs (g)', 
      'Daily Fats (g)',
      'Protein per Meal (g)',
      'Carbs per Meal (g)',
      'Fats per Meal (g)',
      'Protein/Body Weight Ratio',
      'Water Intake (L)',
      'Meal Count',
      'Fasting Window',
      'Custom Macro Goal'
    ],
    units: {
      'Daily Calories': ['calories'],
      'Daily Protein (g)': ['grams'],
      'Daily Carbs (g)': ['grams'],
      'Daily Fats (g)': ['grams'],
      'Protein per Meal (g)': ['grams'],
      'Carbs per Meal (g)': ['grams'],
      'Fats per Meal (g)': ['grams'],
      'Protein/Body Weight Ratio': ['g/lb', 'g/kg'],
      'Water Intake (L)': ['liters', 'oz'],
      'Meal Count': ['meals/day'],
      'Fasting Window': ['hours'],
      'Custom Macro Goal': ['grams', 'calories', '%']
    },
    defaultUnit: 'grams',
    frequency: ['daily', 'weekly'],
    autoUpdate: true, // Flag to indicate this should update from meal tracking
    trackingSource: 'meals' // Indicates where to pull the data from
  },
  WEIGHT: {
    id: 'weight',
    label: 'Body Weight',
    types: [
      'Target Weight',
      'Weight Loss',
      'Weight Gain',
      'Body Fat Percentage'
    ],
    units: ['lbs', 'kg', '%'],
    defaultUnit: 'lbs'
  },
  HABIT: {
    id: 'habit',
    label: 'Habit',
    types: [
      'Weekly Workouts',
      'Daily Steps',
      'Sleep Hours',
      'Water Intake',
      'Protein Intake'
    ],
    units: ['count', 'steps', 'hours', 'liters', 'grams'],
    frequency: ['daily', 'weekly', 'monthly']
  }
};

const Goals = () => {
  const { user, goals, goalsLoading, goalsError, createGoal, updateGoal, deleteGoal } = useUser();
  const [newGoal, setNewGoal] = useState({
    goal_type: GOAL_TYPES.STRENGTH.id,
    exercise: '',
    target_value: '',
    current_value: '',
    unit: GOAL_TYPES.STRENGTH.defaultUnit,
    target_date: '',
    end_date: '',
    frequency: 'weekly',
    notes: '',
    subtype: '',
    customExercise: '',
    customHabit: '',
    customUnit: '',
    is_ongoing: false
  });
  const [editingGoal, setEditingGoal] = useState(null);
  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [selectedType, setSelectedType] = useState(GOAL_TYPES.STRENGTH.id);
  const [dailyNutrition, setDailyNutrition] = useState({
    calories: 0,
    protein: 0,
    carbs: 0,
    fats: 0,
    waterIntake: 0,
    mealCount: 0
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      fetchDailyNutrition();
    }
  }, [user?.id]);

  const fetchDailyNutrition = async () => {
    if (!user?.id) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/client/${user.id}/meals/summary`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch nutrition data');
      
      const data = await response.json();
      const today = new Date().toISOString().split('T')[0];
      const todayData = data[today];

      if (todayData) {
        setDailyNutrition({
          calories: todayData.calories || 0,
          protein: todayData.protein || 0,
          carbs: todayData.carbs || 0,
          fats: todayData.fat || 0,
          waterIntake: todayData.water_intake || 0,
          mealCount: todayData.meals?.length || 0
        });

        updateNutritionGoals(todayData);
      }
    } catch (error) {
      console.error('Error fetching daily nutrition:', error);
    }
  };

  const updateNutritionGoals = async (nutritionData) => {
    const nutritionGoals = goals.filter(goal => goal.goal_type === 'nutrition');
    
    for (const goal of nutritionGoals) {
      let currentValue = 0;
      
      switch (goal.subtype) {
        case 'Daily Calories':
          currentValue = nutritionData.calories || 0;
          break;
        case 'Daily Protein (g)':
          currentValue = nutritionData.protein || 0;
          break;
        case 'Daily Carbs (g)':
          currentValue = nutritionData.carbs || 0;
          break;
        case 'Daily Fats (g)':
          currentValue = nutritionData.fat || 0;
          break;
        case 'Water Intake (L)':
          currentValue = nutritionData.water_intake || 0;
          break;
        case 'Meal Count':
          currentValue = nutritionData.meals?.length || 0;
          break;
      }

      if (currentValue !== goal.current_value) {
        try {
          await updateGoal(goal.id, {
            ...goal,
            current_value: currentValue
          });
        } catch (error) {
          console.error(`Error updating nutrition goal ${goal.subtype}:`, error);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoal(prev => ({ ...prev, [name]: value }));
    
    // Handle special cases for goal type changes
    if (name === 'goal_type') {
      const selectedGoalType = Object.values(GOAL_TYPES).find(type => type.id === value);
      setNewGoal(prev => ({
        ...prev,
        goal_type: value,
        unit: selectedGoalType?.defaultUnit || '',  // Explicitly set default unit
        subtype: '',
        target_value: '',
        current_value: '',
        customExercise: '',
        customHabit: '',
        customUnit: '',
        is_ongoing: false
      }));
      setSelectedType(value);
    }
  };

  const getGoalTypeFields = () => {
    const type = Object.values(GOAL_TYPES).find(t => t.id === selectedType);
    if (!type) return null;

    switch (selectedType) {
      case GOAL_TYPES.STRENGTH.id:
        return (
          <>
            <FormGroup>
              <Label>Exercise</Label>
              <Select 
                name="subtype" 
                value={newGoal.subtype === 'custom' ? 'custom' : newGoal.subtype} 
                onChange={(e) => {
                  handleInputChange(e);
                  if (e.target.value !== 'custom') {
                    setNewGoal(prev => ({ ...prev, customExercise: '' }));
                  }
                }}
              >
                <option value="">Select Exercise</option>
                {GOAL_TYPES.STRENGTH.exercises.map(exercise => (
                  <option key={exercise} value={exercise}>{exercise}</option>
                ))}
                <option value="custom">Custom Exercise</option>
              </Select>
              {newGoal.subtype === 'custom' && (
                <Input
                  type="text"
                  name="customExercise"
                  value={newGoal.customExercise || ''}
                  onChange={handleInputChange}
                  placeholder="Enter custom exercise name"
                  required
                  sx={{ mt: 1 }}
                />
              )}
            </FormGroup>
            <FormGroup>
              <Label>Unit</Label>
              <Select name="unit" value={newGoal.unit} onChange={handleInputChange}>
                {GOAL_TYPES.STRENGTH.units.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </Select>
            </FormGroup>
          </>
        );

      case GOAL_TYPES.CARDIO.id:
        return (
          <>
            <FormGroup>
              <Label>Type</Label>
              <Select name="subtype" value={newGoal.subtype} onChange={handleInputChange}>
                <option value="">Select Type</option>
                {GOAL_TYPES.CARDIO.types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Goal Format</Label>
              <Select name="unit" value={newGoal.unit} onChange={handleInputChange}>
                <option value="time">Time (mm:ss)</option>
                <option value="distance">Distance</option>
              </Select>
            </FormGroup>
          </>
        );

      case GOAL_TYPES.NUTRITION.id:
        return (
          <>
            <FormGroup>
              <Label>Nutrition Goal Type</Label>
              <Select 
                name="subtype" 
                value={newGoal.subtype} 
                onChange={(e) => {
                  handleInputChange(e);
                  // Reset unit when type changes
                  const selectedType = e.target.value;
                  const availableUnits = GOAL_TYPES.NUTRITION.units[selectedType] || [];
                  setNewGoal(prev => ({
                    ...prev,
                    unit: availableUnits[0] || ''
                  }));
                }}
              >
                <option value="">Select Type</option>
                {GOAL_TYPES.NUTRITION.types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
            </FormGroup>
            {newGoal.subtype && (
              <FormGroup>
                <Label>Unit</Label>
                <Select 
                  name="unit" 
                  value={newGoal.unit} 
                  onChange={handleInputChange}
                >
                  {GOAL_TYPES.NUTRITION.units[newGoal.subtype]?.map(unit => (
                    <option key={unit} value={unit}>{unit}</option>
                  ))}
                </Select>
              </FormGroup>
            )}
            <FormGroup>
              <Label>Frequency</Label>
              <Select name="frequency" value={newGoal.frequency} onChange={handleInputChange}>
                {GOAL_TYPES.NUTRITION.frequency.map(freq => (
                  <option key={freq} value={freq}>{freq.charAt(0).toUpperCase() + freq.slice(1)}</option>
                ))}
              </Select>
            </FormGroup>
          </>
        );

      case GOAL_TYPES.WEIGHT.id:
        return (
          <>
            <FormGroup>
              <Label>Goal Type</Label>
              <Select name="subtype" value={newGoal.subtype} onChange={handleInputChange}>
                <option value="">Select Type</option>
                {GOAL_TYPES.WEIGHT.types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Unit</Label>
              <Select name="unit" value={newGoal.unit} onChange={handleInputChange}>
                {GOAL_TYPES.WEIGHT.units.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </Select>
            </FormGroup>
          </>
        );

      case GOAL_TYPES.HABIT.id:
        return (
          <>
            <FormGroup>
              <Label>Habit Type</Label>
              <Select 
                name="subtype" 
                value={newGoal.subtype === 'custom' ? 'custom' : newGoal.subtype} 
                onChange={(e) => {
                  handleInputChange(e);
                  if (e.target.value !== 'custom') {
                    setNewGoal(prev => ({ 
                      ...prev, 
                      customHabit: '',
                      customUnit: ''
                    }));
                  }
                }}
              >
                <option value="">Select Type</option>
                {GOAL_TYPES.HABIT.types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
                <option value="custom">Custom Habit</option>
              </Select>
              {newGoal.subtype === 'custom' && (
                <>
                  <Input
                    type="text"
                    name="customHabit"
                    value={newGoal.customHabit || ''}
                    onChange={handleInputChange}
                    placeholder="Enter custom habit name"
                    required
                    sx={{ mt: 1 }}
                  />
                  <Input
                    type="text"
                    name="customUnit"
                    value={newGoal.customUnit || ''}
                    onChange={handleInputChange}
                    placeholder="Enter unit of measurement (e.g., minutes, reps)"
                    required
                    sx={{ mt: 1 }}
                  />
                </>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Frequency</Label>
              <Select name="frequency" value={newGoal.frequency} onChange={handleInputChange}>
                {GOAL_TYPES.HABIT.frequency.map(freq => (
                  <option key={freq} value={freq}>{freq.charAt(0).toUpperCase() + freq.slice(1)}</option>
                ))}
              </Select>
            </FormGroup>
          </>
        );

      default:
        return null;
    }
  };

  const formatGoalDisplay = (goal) => {
    const type = Object.values(GOAL_TYPES).find(t => t.id === goal.goal_type);
    if (!type) return '';

    let display = '';
    
    // Special handling for nutrition goals
    if (goal.goal_type === 'nutrition' && GOAL_TYPES.NUTRITION.autoUpdate) {
      display = `${goal.subtype}: ${goal.current_value} / ${goal.target_value} ${goal.unit}`;
      if (goal.frequency === 'daily') {
        display += ' (Updates automatically from meals)';
      }
      return display;
    }

    // Existing display logic for other goals
    if (goal.goal_type === GOAL_TYPES.STRENGTH.id) {
      display = `${goal.subtype === 'custom' ? goal.customExercise : goal.subtype}: `;
    } else if (goal.goal_type === GOAL_TYPES.HABIT.id && goal.subtype === 'custom') {
      display = `${goal.customHabit}: `;
    } else {
      display = `${goal.subtype}: `;
    }
    
    if (goal.goal_type === GOAL_TYPES.CARDIO.id && goal.unit === 'time') {
      display += `${goal.current_value} → ${goal.target_value} (mm:ss)`;
    } else if (goal.goal_type === GOAL_TYPES.HABIT.id && goal.subtype === 'custom') {
      display += `${goal.current_value} → ${goal.target_value} ${goal.customUnit}`;
    } else {
      display += `${goal.current_value} → ${goal.target_value} ${goal.unit}`;
    }

    if (goal.frequency) {
      display += ` (${goal.frequency})`;
    }

    return display;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createGoal(newGoal);
      setNewGoal({
        goal_type: GOAL_TYPES.STRENGTH.id,
        exercise: '',
        target_value: '',
        current_value: '',
        unit: GOAL_TYPES.STRENGTH.defaultUnit,
        target_date: '',
        end_date: '',
        frequency: 'weekly',
        notes: '',
        subtype: '',
        customExercise: '',
        customHabit: '',
        customUnit: '',
        is_ongoing: false
      });
      setIsAddingGoal(false);
    } catch (error) {
      console.error('Error creating goal:', error);
    }
  };

  const handleEdit = async (id, updatedGoal) => {
    try {
      await updateGoal(id, updatedGoal);
      setEditingGoal(null);
    } catch (error) {
      console.error('Error updating goal:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteGoal(id);
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  const renderDateFields = () => {
    // Skip date fields entirely for daily nutrition goals since they auto-update
    if (newGoal.goal_type === 'nutrition' && GOAL_TYPES.NUTRITION.autoUpdate) {
      return null;
    }
    
    // For non-nutrition daily goals or non-daily goals, keep existing date logic
    if (newGoal.frequency === 'daily' && newGoal.goal_type !== 'nutrition') {
      return (
        <>
          <FormGroup>
            <Label>Is this an ongoing goal?</Label>
            <Select
              name="is_ongoing"
              value={newGoal.is_ongoing}
              onChange={(e) => {
                handleInputChange({
                  target: {
                    name: 'is_ongoing',
                    value: e.target.value === 'true'
                  }
                });
              }}
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </Select>
          </FormGroup>
          {!newGoal.is_ongoing && (
            <FormGroup>
              <Label>End Date</Label>
              <Input
                type="date"
                name="end_date"
                value={newGoal.end_date}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          )}
        </>
      );
    } else if (newGoal.frequency !== 'daily') {
      return (
        <FormGroup>
          <Label>Target Date</Label>
          <Input
            type="date"
            name="target_date"
            value={newGoal.target_date}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
      );
    }
    return null;
  };

  const GoalsOverview = () => {
    if (goalsLoading) {
      return (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography>Loading goals...</Typography>
          </CardContent>
        </Card>
      );
    }

    if (goalsError) {
      return (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography color="error">Error: {goalsError}</Typography>
            <Button onClick={fetchDailyNutrition}>Retry</Button>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Active Goals</Typography>
            <Button 
              variant="outlined" 
              startIcon={<FaPlus />}
              onClick={() => navigate('/goals')}
            >
              Add Goal
            </Button>
          </Box>
          <Grid container spacing={2}>
            {Array.isArray(goals) && goals.length > 0 ? (
              goals.map((goal) => (
                <Grid item xs={12} md={6} key={goal.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        {goal.goal_type.charAt(0).toUpperCase() + goal.goal_type.slice(1)}
                        {goal.subtype && ` - ${goal.subtype}`}
                      </Typography>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        <Typography variant="body2" gutterBottom>
                          Progress: {goal.current_value} / {goal.target_value} {goal.unit}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={goal.progress_percentage}
                          sx={{ height: 8, borderRadius: 4, mb: 1 }}
                        />
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Target Date: {new Date(goal.target_date).toLocaleDateString()}
                      </Typography>
                      {goal.notes && (
                        <Typography variant="body2" color="text.secondary">
                          Notes: {goal.notes}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  No goals set yet. Start by adding a new goal!
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <GoalsContainer>
      <Header>
        <h1>Your Fitness Goals</h1>
        <AddButton onClick={() => setIsAddingGoal(true)}>
          <FaPlus /> Add New Goal
        </AddButton>
      </Header>

      {goalsError && (
        <Alert severity="error" onClose={() => {}}>
          {goalsError}
        </Alert>
      )}

      <GoalsOverview />

      {isAddingGoal && (
        <GoalForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Goal Category</Label>
            <Select name="goal_type" value={newGoal.goal_type} onChange={handleInputChange}>
              {Object.values(GOAL_TYPES).map(type => (
                <option key={type.id} value={type.id}>{type.label}</option>
              ))}
            </Select>
          </FormGroup>

          {getGoalTypeFields()}

          <FormGroup>
            <Label>Current Value</Label>
            <Input
              type={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'text' : 'number'}
              name="current_value"
              value={newGoal.current_value}
              onChange={handleInputChange}
              placeholder={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'mm:ss' : 'Enter current value'}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Target Value</Label>
            <Input
              type={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'text' : 'number'}
              name="target_value"
              value={newGoal.target_value}
              onChange={handleInputChange}
              placeholder={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'mm:ss' : 'Enter target value'}
              required
            />
          </FormGroup>

          {renderDateFields()}

          <FormGroup>
            <Label>Notes</Label>
            <Textarea
              name="notes"
              value={newGoal.notes}
              onChange={handleInputChange}
              placeholder="Additional notes or motivation"
            />
          </FormGroup>

          <ButtonGroup>
            <SubmitButton type="submit">Save Goal</SubmitButton>
            <CancelButton type="button" onClick={() => setIsAddingGoal(false)}>Cancel</CancelButton>
          </ButtonGroup>
        </GoalForm>
      )}

      <GoalsList>
        {goals.length > 0 ? (
          goals.map((goal) => (
            <GoalItem key={goal.id}>
              {editingGoal === goal.id ? (
                <GoalForm onSubmit={(e) => {
                  e.preventDefault();
                  handleEdit(goal.id, newGoal);
                }}>
                  <FormGroup>
                    <Label>Goal Category</Label>
                    <Select name="goal_type" value={newGoal.goal_type} onChange={handleInputChange}>
                      {Object.values(GOAL_TYPES).map(type => (
                        <option key={type.id} value={type.id}>{type.label}</option>
                      ))}
                    </Select>
                  </FormGroup>

                  {getGoalTypeFields()}

                  <FormGroup>
                    <Label>Current Value</Label>
                    <Input
                      type={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'text' : 'number'}
                      name="current_value"
                      value={newGoal.current_value}
                      onChange={handleInputChange}
                      placeholder={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'mm:ss' : 'Enter current value'}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Target Value</Label>
                    <Input
                      type={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'text' : 'number'}
                      name="target_value"
                      value={newGoal.target_value}
                      onChange={handleInputChange}
                      placeholder={selectedType === GOAL_TYPES.CARDIO.id && newGoal.unit === 'time' ? 'mm:ss' : 'Enter target value'}
                      required
                    />
                  </FormGroup>

                  {renderDateFields()}

                  <FormGroup>
                    <Label>Notes</Label>
                    <Textarea
                      name="notes"
                      value={newGoal.notes}
                      onChange={handleInputChange}
                      placeholder="Additional notes or motivation"
                    />
                  </FormGroup>

                  <ButtonGroup>
                    <SubmitButton type="submit">Save Changes</SubmitButton>
                    <CancelButton type="button" onClick={() => setEditingGoal(null)}>Cancel</CancelButton>
                  </ButtonGroup>
                </GoalForm>
              ) : (
                <>
                  <GoalType>{goal.goal_type}</GoalType>
                  <GoalDetails>
                    <p><strong>{formatGoalDisplay(goal)}</strong></p>
                    {!(goal.goal_type === 'nutrition' && GOAL_TYPES.NUTRITION.autoUpdate) && (
                      <>
                        {goal.target_date && (
                          <p><strong>Target Date:</strong> {new Date(goal.target_date).toLocaleDateString()}</p>
                        )}
                        {goal.end_date && (
                          <p><strong>End Date:</strong> {new Date(goal.end_date).toLocaleDateString()}</p>
                        )}
                      </>
                    )}
                    {goal.goal_type === 'nutrition' && (
                      <ProgressBar>
                        <Progress 
                          width={Math.min((goal.current_value / goal.target_value) * 100, 100)}
                          color={goal.current_value >= goal.target_value ? '#4caf50' : '#2196f3'}
                        />
                      </ProgressBar>
                    )}
                    {goal.notes && <p><strong>Notes:</strong> {goal.notes}</p>}
                  </GoalDetails>
                  <ButtonGroup>
                    <EditButton onClick={() => {
                      const goalType = Object.values(GOAL_TYPES).find(t => t.id === goal.goal_type);
                      setEditingGoal(goal.id);
                      setNewGoal({
                        ...goal,
                        unit: goal.unit || goalType?.defaultUnit || ''
                      });
                      setSelectedType(goal.goal_type);
                    }}><FaEdit /> Edit</EditButton>
                    <DeleteButton onClick={() => handleDelete(goal.id)}><FaTrash /> Delete</DeleteButton>
                  </ButtonGroup>
                </>
              )}
            </GoalItem>
          ))
        ) : (
          <NoGoals>No goals set yet. Start by adding a new goal!</NoGoals>
        )}
      </GoalsList>
    </GoalsContainer>
  );
};

const GoalsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    font-size: 2.5rem;
    color: ${COLORS[0]};
  }
`;

const AddButton = styled(Button)`
  background-color: ${COLORS[1]};
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${COLORS[2]};
  }
`;

const GoalForm = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${COLORS[0]};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 100px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const SubmitButton = styled(Button)`
  background-color: ${COLORS[1]};
  color: white;

  &:hover {
    background-color: ${COLORS[2]};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${COLORS[3]};
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const EditButton = styled(Button)`
  background-color: ${COLORS[0]};
  color: white;

  &:hover {
    background-color: #1976D2;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${COLORS[3]};
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const GoalsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const GoalItem = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const GoalType = styled.h3`
  font-size: 1.2rem;
  color: ${COLORS[0]};
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const GoalDetails = styled.div`
  margin-bottom: 15px;

  p {
    margin: 5px 0;
    color: #666;
  }

  strong {
    color: ${COLORS[2]};
  }
`;

const NoGoals = styled.p`
  text-align: center;
  color: #666;
  font-style: italic;
  grid-column: 1 / -1;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 8px 0;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props => props.color};
  transition: width 0.3s ease;
`;

export default Goals;