import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BACKEND_URL = 'localhost:8000';

const DIETARY_RESTRICTIONS = [
  'None',
  'Vegetarian',
  'Vegan',
  'Gluten-free',
  'Dairy-free',
  'Keto',
  'Low-carb',
  'Low-fat',
  'Paleo',
  'Mediterranean'
];

const MealPlan = () => {
  const [mealName, setMealName] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ingredients, setIngredients] = useState(null);
  const [recipe, setRecipe] = useState(null);

  const handleDietaryChange = (event) => {
    const {
      target: { value },
    } = event;
    setDietaryRestrictions(typeof value === 'string' ? value.split(',') : value);
  };

  const extractIngredients = async () => {
    if (!mealName.trim()) {
      setError('Please enter a meal name');
      return;
    }

    setLoading(true);
    setError('');
    setIngredients(null);
    setRecipe(null);

    try {
      const response = await fetch(`http://${BACKEND_URL}/extract-meal-ingredients`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          meal_name: mealName,
          dietary_restrictions: dietaryRestrictions.filter(r => r !== 'None'),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to extract ingredients');
      }

      const data = await response.json();
      setIngredients(data);
      
      // Automatically generate recipe after getting ingredients
      await generateRecipe(data);

    } catch (err) {
      setError(err.message);
      console.error('Error extracting ingredients:', err);
    } finally {
      setLoading(false);
    }
  };

  const generateRecipe = async (ingredientData) => {
    try {
      const ingredientsList = ingredientData.ingredients.map(i => i.name).join(", ");
      
      const response = await fetch(`http://${BACKEND_URL}/generateRecipe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ingredients: ingredientsList,
          diet: dietaryRestrictions[0],
          strict: 1
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate recipe');
      }

      const data = await response.json();
      setRecipe(data);

    } catch (err) {
      setError(err.message);
      console.error('Error generating recipe:', err);
    }
  };

  const cleanRecipeText = (text) => {
    return text
      .replace(/#{1,6}\s/g, '') // Remove heading hashtags
      .replace(/\*\*/g, '')      // Remove bold markers
      .replace(/\n{3,}/g, '\n\n'); // Replace multiple newlines with double newlines
  };

  const renderIngredients = () => {
    if (!ingredients) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Ingredients Analysis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Ingredients List:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {ingredients.ingredients.map((ingredient, index) => (
                  <Chip
                    key={index}
                    label={`${ingredient.name} (${ingredient.amount})`}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Meal Details:
              </Typography>
              <Typography variant="body2">
                Type: {ingredients.meal_type}<br />
                Cuisine: {ingredients.cuisine_type}<br />
                Estimated Calories: {ingredients.estimated_calories}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Dietary Notes:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {ingredients.dietary_notes.map((note, index) => (
                  <Chip key={index} label={note} size="small" />
                ))}
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderRecipe = () => {
    if (!recipe) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Generated Recipe</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            component="div"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {cleanRecipeText(recipe.recipe)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <RestaurantIcon fontSize="large" />
        Meal Planner
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Enter Meal Name"
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
              placeholder="e.g., Grilled Salmon Salad"
              helperText="Enter a meal name to get ingredients and recipe"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Dietary Restrictions</InputLabel>
              <Select
                multiple
                value={dietaryRestrictions}
                onChange={handleDietaryChange}
                input={<OutlinedInput label="Dietary Restrictions" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {DIETARY_RESTRICTIONS.map((restriction) => (
                  <MenuItem key={restriction} value={restriction}>
                    {restriction}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={extractIngredients}
              disabled={loading}
              fullWidth
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? 'Processing...' : 'Analyze Meal & Generate Recipe'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {renderIngredients()}
      {renderRecipe()}
    </Box>
  );
};

export default MealPlan; 