import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  TextField,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Badge,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Message,
  Person,
  TrendingUp,
  EmojiEvents,
  Search,
  Edit,
  Notifications,
  Warning,
  Mail,
  CheckCircle,
} from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
} from 'recharts';

// Enhanced mock data
const mockClients = [
  {
    id: 1,
    name: 'John Doe',
    avatar: 'https://via.placeholder.com/40',
    caloriesLogged: 1800,
    weeklyAdherence: 85,
    lastNote: 'Missed dinner yesterday',
    progress: 'on-track',
    lastActive: '2 days ago',
    status: 'warning', // warning, danger, active
    metrics: {
      streakDays: 5,
      mealsLogged: 15,
      workoutsCompleted: 8,
    }
  },
  {
    id: 2,
    name: 'Jane Smith',
    avatar: 'https://via.placeholder.com/40',
    caloriesLogged: 2100,
    weeklyAdherence: 92,
    lastNote: 'Great progress this week',
    progress: 'exceeding',
    lastActive: '1 day ago',
    status: 'active',
    metrics: {
      streakDays: 7,
      mealsLogged: 20,
      workoutsCompleted: 10,
    }
  },
  // Add more clients as needed
];

const mockPerformanceData = {
  weeklyStats: [
    { day: 'Mon', adherence: 85, clients: 12 },
    { day: 'Tue', adherence: 88, clients: 15 },
    { day: 'Wed', adherence: 92, clients: 14 },
    { day: 'Thu', adherence: 87, clients: 13 },
    { day: 'Fri', adherence: 90, clients: 15 },
  ],
  mealDistribution: [
    { name: 'Breakfast', value: 25 },
    { name: 'Lunch', value: 35 },
    { name: 'Dinner', value: 30 },
    { name: 'Snacks', value: 10 },
  ],
};

const mockNotifications = [
  {
    id: 1,
    type: 'inactivity',
    message: 'John Doe hasn\'t logged meals for 2 days',
    timestamp: '2h ago',
    severity: 'warning',
  },
  {
    id: 2,
    type: 'milestone',
    message: 'Jane Smith completed 30-day streak!',
    timestamp: '5h ago',
    severity: 'success',
  },
];

const AdminDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [bulkMessageOpen, setBulkMessageOpen] = useState(false);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [messageText, setMessageText] = useState('');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
  };

  const handleSendMessage = () => {
    // Implement message sending logic
    setSnackbar({
      open: true,
      message: 'Message sent successfully!',
      severity: 'success'
    });
    setMessageDialogOpen(false);
    setMessageText('');
  };

  const handleBulkMessage = () => {
    // Implement bulk message sending logic
    setSnackbar({
      open: true,
      message: 'Bulk message sent to all clients!',
      severity: 'success'
    });
    setBulkMessageOpen(false);
    setMessageText('');
  };

  const renderClientOverview = () => (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<Mail />}
          onClick={() => setBulkMessageOpen(true)}
        >
          Send Bulk Message
        </Button>
        <Box>
          <IconButton onClick={(e) => setNotificationAnchor(e.currentTarget)}>
            <Badge badgeContent={mockNotifications.length} color="error">
              <Notifications />
            </Badge>
          </IconButton>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell align="right">Today's Calories</TableCell>
              <TableCell align="right">Weekly Adherence</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Active</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockClients.map((client) => (
              <TableRow
                key={client.id}
                sx={{
                  backgroundColor:
                    client.status === 'danger'
                      ? 'rgba(239, 83, 80, 0.1)'
                      : client.status === 'warning'
                      ? 'rgba(255, 152, 0, 0.1)'
                      : 'inherit',
                }}
              >
                <TableCell component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar src={client.avatar} />
                    <Typography>{client.name}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{client.caloriesLogged}</TableCell>
                <TableCell align="right">
                  <Chip
                    label={`${client.weeklyAdherence}%`}
                    color={client.weeklyAdherence >= 90 ? 'success' : 'primary'}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    icon={client.status === 'danger' ? <Warning /> : <CheckCircle />}
                    label={client.status === 'danger' ? 'Inactive' : 'Active'}
                    color={client.status === 'danger' ? 'error' : 'success'}
                    size="small"
                  />
                </TableCell>
                <TableCell>{client.lastActive}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => setMessageDialogOpen(true)}
                    color="primary"
                  >
                    <Message />
                  </IconButton>
                  <IconButton
                    onClick={() => handleClientSelect(client)}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderPerformanceMetrics = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Weekly Adherence Trends
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={mockPerformanceData.weeklyStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="adherence"
                stroke="#8884d8"
                name="Adherence %"
              />
              <Line
                type="monotone"
                dataKey="clients"
                stroke="#82ca9d"
                name="Active Clients"
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Meal Distribution
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={mockPerformanceData.mealDistribution}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Top Performing Clients
          </Typography>
          <List>
            {mockClients
              .sort((a, b) => b.weeklyAdherence - a.weeklyAdherence)
              .slice(0, 5)
              .map((client, index) => (
                <ListItem key={client.id}>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <EmojiEvents
                          color={index === 0 ? 'primary' : 'action'}
                        />
                        {client.name}
                      </Box>
                    }
                    secondary={`${client.weeklyAdherence}% adherence`}
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant="h4">Admin Dashboard</Typography>
            <TextField
              size="small"
              placeholder="Search clients..."
              InputProps={{
                startAdornment: <Search />,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab
              icon={<Person />}
              label="Client Overview"
              iconPosition="start"
            />
            <Tab
              icon={<TrendingUp />}
              label="Performance Metrics"
              iconPosition="start"
            />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {selectedTab === 0 ? renderClientOverview() : renderPerformanceMetrics()}
        </Grid>
      </Grid>

      {/* Message Dialog */}
      <Dialog open={messageDialogOpen} onClose={() => setMessageDialogOpen(false)}>
        <DialogTitle>Send Message to Client</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMessageDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSendMessage} variant="contained">Send</Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Message Dialog */}
      <Dialog open={bulkMessageOpen} onClose={() => setBulkMessageOpen(false)}>
        <DialogTitle>Send Bulk Message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message to all clients"
            fullWidth
            multiline
            rows={4}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkMessageOpen(false)}>Cancel</Button>
          <Button onClick={handleBulkMessage} variant="contained">Send to All</Button>
        </DialogActions>
      </Dialog>

      {/* Notifications Menu */}
      <Menu
        anchorEl={notificationAnchor}
        open={Boolean(notificationAnchor)}
        onClose={() => setNotificationAnchor(null)}
      >
        {mockNotifications.map((notification) => (
          <MenuItem key={notification.id}>
            <ListItemText
              primary={notification.message}
              secondary={notification.timestamp}
            />
          </MenuItem>
        ))}
      </Menu>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminDashboard; 