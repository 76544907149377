import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  Grid,
  Chip,
  CircularProgress,
  Alert,
} from '@mui/material';

const BACKEND_URL = 'localhost:8000';
const ACTIVITY_LEVELS = [
  'Sedentary',
  'Light',
  'Moderate',
  'Active',
  'Very Active'
];

const GOALS = [
  'Lose weight',
  'Maintain weight',
  'Gain weight',
  'Build muscle',
  'Improve overall health'
];

const DIETARY_RESTRICTIONS = [
  'None',
  'Vegetarian',
  'Vegan',
  'Gluten-free',
  'Dairy-free',
  'Keto',
  'Paleo'
];

const NutritionAdvice = () => {
  const [formData, setFormData] = useState({
    goal: '',
    dietary_restrictions: [],
    current_weight: '',
    target_weight: '',
    daily_activity_level: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [advice, setAdvice] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDietaryRestrictions = (event) => {
    const { value } = event.target;
    setFormData(prev => ({
      ...prev,
      dietary_restrictions: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`http://${BACKEND_URL}/nutritionAdvice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          current_weight: parseFloat(formData.current_weight),
          target_weight: parseFloat(formData.target_weight),
          lang: 'en'
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to fetch nutrition advice');
      }

      const data = await response.json();
      setAdvice(data.result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderAdvice = () => {
    if (!advice) return null;

    return (
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Nutrition Recommendations
        </Typography>
        <Typography variant="body1" gutterBottom>
          Daily Calories: {advice.calories_per_day}
        </Typography>
        
        <Typography variant="h6" sx={{ mt: 2 }}>
          Macronutrient Distribution
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(advice.macronutrients).map(([macro, value]) => (
            <Grid item xs={4} key={macro}>
              <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                  {macro}
                </Typography>
                <Typography variant="h6">{value}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Meal Suggestions
        </Typography>
        {advice.meal_suggestions?.map((mealTime, index) => (
          <Box key={index} sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {mealTime.meal}
            </Typography>
            {mealTime.suggestions.map((meal, idx) => (
              <Paper key={idx} sx={{ p: 2, mt: 1 }}>
                <Typography variant="subtitle2">{meal.name} ({meal.calories} cal)</Typography>
                <Box sx={{ mt: 1 }}>
                  {meal.ingredients.map((ingredient, i) => (
                    <Chip
                      key={i}
                      label={ingredient}
                      size="small"
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Box>
              </Paper>
            ))}
          </Box>
        ))}
      </Paper>
    );
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Nutrition Advice
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Goal</InputLabel>
              <Select
                name="goal"
                value={formData.goal}
                onChange={handleChange}
                required
              >
                {GOALS.map(goal => (
                  <MenuItem key={goal} value={goal}>
                    {goal}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Dietary Restrictions</InputLabel>
              <Select
                multiple
                name="dietary_restrictions"
                value={formData.dietary_restrictions}
                onChange={handleDietaryRestrictions}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {DIETARY_RESTRICTIONS.map(restriction => (
                  <MenuItem key={restriction} value={restriction}>
                    {restriction}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Current Weight (kg)"
              name="current_weight"
              value={formData.current_weight}
              onChange={handleChange}
              required
              inputProps={{ step: "0.1" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Target Weight (kg)"
              name="target_weight"
              value={formData.target_weight}
              onChange={handleChange}
              required
              inputProps={{ step: "0.1" }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Daily Activity Level</InputLabel>
              <Select
                name="daily_activity_level"
                value={formData.daily_activity_level}
                onChange={handleChange}
                required
              >
                {ACTIVITY_LEVELS.map(level => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Get Nutrition Advice'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {renderAdvice()}
    </Box>
  );
};

export default NutritionAdvice;
