import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDumbbell, FaSearch, FaChevronDown, FaPlus } from 'react-icons/fa';
import { useMediaQuery, useTheme } from '@mui/material';

const ExerciseDB = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [exercises, setExercises] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [targets, setTargets] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('bodyParts');
  const [selectedItem, setSelectedItem] = useState(null);
  const [limit, setLimit] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWorkout, setSelectedWorkout] = useState('');
  const [newWorkoutName, setNewWorkoutName] = useState('');
  const [success, setSuccess] = useState('');

  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

  const fetchExercises = async (endpoint, params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}${endpoint}`, { params });
      return response.data;
    } catch (err) {
      console.error('Error fetching exercises:', err);
      setError(`Error fetching data: ${err.message}`);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/workouts`);
      setWorkouts(response.data || []);
    } catch (err) {
      console.error('Error fetching workouts:', err);
      setError(`Error fetching workouts: ${err.message}`);
      setWorkouts([]);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [bodyPartsData, equipmentData, targetsData] = await Promise.all([
          fetchExercises('/exercises/bodyPartList'),
          fetchExercises('/exercises/equipmentList'),
          fetchExercises('/exercises/targetList')
        ]);

        setBodyParts(bodyPartsData || []);
        setEquipment(equipmentData || []);
        setTargets(targetsData || []);

        await fetchWorkouts();
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(`Error loading initial data: ${err.message}`);
      }
    };

    loadInitialData();
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedItem(null);
    setExercises([]);
  };

  const handleItemSelect = async (item) => {
    setSelectedItem(item);
    let endpoint;
    switch (selectedCategory) {
      case 'bodyParts':
        endpoint = `/exercises/bodyPart/${item}`;
        break;
      case 'equipment':
        endpoint = `/exercises/equipment/${item}`;
        break;
      case 'targets':
        endpoint = `/exercises/target/${item}`;
        break;
      default:
        return;
    }
    const data = await fetchExercises(endpoint, { limit });
    setExercises(data || []);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    if (selectedItem) {
      handleItemSelect(selectedItem);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const addExerciseToWorkout = async (exercise) => {
    if (!selectedWorkout) {
      setError('Please select a workout.');
      return;
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/workouts/${selectedWorkout}/exercises`, {
        name: exercise.name,
        sets: 3,
        reps: 10,
        weight: 0,
      });
  
      if (response.status === 200) {
        setSuccess(`${exercise.name} added to workout successfully!`);
        setTimeout(() => setSuccess(null), 3000); // Hide the success message after 3 seconds
        // Refresh the workouts list
        await fetchWorkouts();
      } else {
        setError('Failed to add exercise to workout. Please try again.');
      }
    } catch (err) {
      console.error('Error adding exercise to workout:', err);
      setError(`An error occurred while adding the exercise to the workout: ${err.message}`);
    }
  };

  const createWorkout = async () => {
    if (!newWorkoutName) {
      setError('Please provide a workout name.');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/workouts`, {
        name: newWorkoutName,
        description: '',
        duration: 0,
        type: 'strength',
        exercises: []
      });

      if (response.status === 201) {
        setSuccess('Workout created successfully!');
        setNewWorkoutName('');
        await fetchWorkouts();
      } else {
        setError('Failed to create workout. Please try again.');
      }
    } catch (err) {
      console.error('Error creating workout:', err);
      setError(`An error occurred while creating the workout: ${err.message}`);
    }
  };

  const getCategoryItems = () => {
    switch (selectedCategory) {
      case 'bodyParts':
        return bodyParts;
      case 'equipment':
        return equipment;
      case 'targets':
        return targets;
      default:
        return [];
    }
  };

  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container isMobile={isMobile}>
      <Header isMobile={isMobile}>
        <Title isMobile={isMobile}>Exercise Database</Title>
        <Subtitle isMobile={isMobile}>Select or create a workout to start adding exercises</Subtitle>
      </Header>

      <WorkoutSelectionPanel isMobile={isMobile}>
        <WorkoutSelectorContainer isMobile={isMobile}>
          <WorkoutSelectorLabel isMobile={isMobile}>Select Existing Workout:</WorkoutSelectorLabel>
          <WorkoutSelect
            value={selectedWorkout}
            onChange={(e) => setSelectedWorkout(e.target.value)}
            isMobile={isMobile}
          >
            <option value="">Choose a workout...</option>
            {workouts.map((workout) => (
              <option key={workout.id} value={workout.id}>
                {workout.name}
              </option>
            ))}
          </WorkoutSelect>
        </WorkoutSelectorContainer>

        <OrDivider isMobile={isMobile}>
          <span>OR</span>
        </OrDivider>

        <CreateWorkoutContainer isMobile={isMobile}>
          <WorkoutSelectorLabel isMobile={isMobile}>Create New Workout:</WorkoutSelectorLabel>
          <CreateWorkoutForm isMobile={isMobile}>
            <WorkoutInput
            type="text"
              placeholder="Enter workout name"
            value={newWorkoutName}
            onChange={(e) => setNewWorkoutName(e.target.value)}
              isMobile={isMobile}
            />
            <CreateWorkoutButton onClick={createWorkout} isMobile={isMobile}>
              Create Workout
            </CreateWorkoutButton>
          </CreateWorkoutForm>
        </CreateWorkoutContainer>
      </WorkoutSelectionPanel>

      {!selectedWorkout && !newWorkoutName && (
        <NoWorkoutMessage isMobile={isMobile}>
          Please select an existing workout or create a new one to start adding exercises
        </NoWorkoutMessage>
      )}

      {(selectedWorkout || newWorkoutName) && (
        <>
          <ControlsWrapper isMobile={isMobile}>
            <CategorySelector isMobile={isMobile}>
          {['bodyParts', 'equipment', 'targets'].map((category) => (
            <CategoryButton
              key={category}
              onClick={() => handleCategorySelect(category)}
              selected={selectedCategory === category}
                  isMobile={isMobile}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryButton>
          ))}
        </CategorySelector>
            <SearchBar isMobile={isMobile}>
          <FaSearch />
          <input
            type="text"
            placeholder="Search exercises..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBar>
      </ControlsWrapper>

      <ContentWrapper>
        <Sidebar>
          <SidebarTitle>{selectedCategory}</SidebarTitle>
          <ItemList>
            <AnimatePresence>
              {getCategoryItems().map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <Item
                    onClick={() => handleItemSelect(item)}
                    selected={selectedItem === item}
                  >
                    {item}
                  </Item>
                </motion.li>
              ))}
            </AnimatePresence>
          </ItemList>
        </Sidebar>

        <ExerciseList>
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            <AnimatePresence>
              {filteredExercises.map((exercise, index) => (
                <ExerciseCard
                  key={exercise.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <ExerciseImage src={exercise.gifUrl} alt={exercise.name} />
                  <ExerciseDetails>
                    <ExerciseName>{exercise.name}</ExerciseName>
                    <ExerciseInfo>
                      <InfoItem>
                        <strong>Target:</strong> {exercise.target}
                      </InfoItem>
                      <InfoItem>
                        <strong>Equipment:</strong> {exercise.equipment}
                      </InfoItem>
                    </ExerciseInfo>
                    <AddButton onClick={() => addExerciseToWorkout(exercise)}>
                      <FaPlus /> Add to Workout
                    </AddButton>
                  </ExerciseDetails>
                </ExerciseCard>
              ))}
            </AnimatePresence>
          )}
        </ExerciseList>
      </ContentWrapper>
        </>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
    </Container>
  );
};

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${props => props.isMobile ? '1rem' : '2rem'};
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: ${props => props.isMobile ? '1.5rem' : '2rem'};
`;

const Title = styled.h1`
  font-size: ${props => props.isMobile ? '1.75rem' : '2.5rem'};
  color: #4A25E1;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  color: #666;
`;

const WorkoutSelectionPanel = styled.div`
  background: white;
  border-radius: 12px;
  padding: ${props => props.isMobile ? '1rem' : '2rem'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  display: ${props => props.isMobile ? 'block' : 'flex'};
  align-items: center;
  gap: 2rem;
`;

const WorkoutSelectorContainer = styled.div`
  flex: 1;
  min-width: ${props => props.isMobile ? '100%' : '200px'};
  margin-bottom: ${props => props.isMobile ? '1.5rem' : '0'};
`;

const WorkoutSelectorLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
`;

const WorkoutSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:focus {
    border-color: #4A25E1;
    outline: none;
  }
`;

const OrDivider = styled.div`
  position: relative;
  text-align: center;
  margin: ${props => props.isMobile ? '1.5rem 0' : '0 2rem'};
  
  span {
    background: white;
    padding: 0.5rem 1rem;
    color: #666;
    font-weight: 600;
    font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: ${props => props.isMobile ? '0' : '50%'};
    right: ${props => props.isMobile ? '0' : 'auto'};
    width: ${props => props.isMobile ? '100%' : '1px'};
    height: ${props => props.isMobile ? '1px' : '100%'};
    background: #e1e1e1;
    z-index: -1;
  }
`;

const CreateWorkoutContainer = styled.div`
  flex: 1;
  min-width: ${props => props.isMobile ? '100%' : '200px'};
`;

const CreateWorkoutForm = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
`;

const WorkoutInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  transition: all 0.3s ease;

  &:focus {
    border-color: #4A25E1;
    outline: none;
  }
`;

const CreateWorkoutButton = styled.button`
  padding: ${props => props.isMobile ? '0.75rem' : '0.75rem 1.5rem'};
  background: #4A25E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  width: ${props => props.isMobile ? '100%' : 'auto'};

  &:hover {
    background: #3a1db1;
    transform: translateY(-2px);
  }
`;

const NoWorkoutMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
  background: #f8f8f8;
  border-radius: 12px;
  margin: 2rem 0;
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: ${props => props.isMobile ? 'stretch' : 'center'};
`;

const CategorySelector = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: ${props => props.isMobile ? 'wrap' : 'nowrap'};
`;

const CategoryButton = styled.button`
  padding: 0.75rem 1rem;
  background: ${props => props.selected ? '#4A25E1' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  border: 2px solid ${props => props.selected ? '#4A25E1' : '#e1e1e1'};
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: ${props => props.isMobile ? '1' : 'none'};
  font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};

  &:hover {
    border-color: #4A25E1;
    transform: translateY(-2px);
  }
`;

const SearchBar = styled.div`
  position: relative;
  flex: ${props => props.isMobile ? '1' : '0 0 300px'};
  margin-top: ${props => props.isMobile ? '1rem' : '0'};

  input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border: 2px solid #e1e1e1;
    border-radius: 8px;
    font-size: ${props => props.isMobile ? '0.9rem' : '1rem'};
    transition: all 0.3s ease;

    &:focus {
      border-color: #4A25E1;
    outline: none;
    }
  }

  svg {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  height: fit-content;
`;

const SidebarTitle = styled.h2`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
`;

const Item = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: ${props => props.selected ? '#3498db' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: ${props => props.selected ? 'bold' : 'normal'};

  &:hover {
    background-color: ${props => props.selected ? '#2980b9' : '#e0e0e0'};
    transform: translateY(-2px);
  }
`;

const ExerciseList = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
`;

const ExerciseCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ExerciseImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ExerciseDetails = styled.div`
  padding: 1rem;
`;

const ExerciseName = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const ExerciseInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const InfoItem = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin: 0;

  strong {
    color: #3498db;
  }
`;

const AddButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0.5rem;

  &:hover {
    background-color: #2980b9;
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #3498db;
  font-size: 1.2rem;
  gap: 1rem;
`;

const ErrorMessage = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #ff4d4d;
  background-color: #ffe6e6;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

const SuccessMessage = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #28a745;
  background-color: #e6ffe6;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

export default ExerciseDB;