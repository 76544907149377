import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
  Badge,
  Tooltip,
  IconButton,
  Drawer,
  useTheme,
  useMediaQuery,
  Collapse,
  Popover,
  Stack,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  Grid,
  Paper,
  CircularProgress,
  ListItemButton,
  ListItemTextPrimary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  FitnessCenter,
  Restaurant,
  Timeline,
  Assessment,
  Notifications,
  Settings,
  KeyboardArrowRight,
  Menu as MenuIcon,
  TrendingUp,
  Star,
  EmojiEvents,
  Whatshot,
  LocalFireDepartment,
  DirectionsRun,
  Timer,
  Close,
  Scale,
  FastfoodOutlined,
  MonitorHeart,
  CalendarToday,
  CheckCircle,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  PieChart, 
  Pie, 
  Cell, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { format, startOfDay, startOfWeek } from 'date-fns';
import { useUser } from './UserContext';

// Add theme colors at the top
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

const ClientDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { user, logout, goals, goalsLoading, goalsError } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // State
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(getCurrentSection());
  const [workouts, setWorkouts] = useState([]);
  const [notifications, setNotifications] = useState([
    { id: 1, type: 'achievement', message: 'New personal record!' },
    { id: 2, type: 'reminder', message: 'Time for your workout' },
    { id: 3, type: 'goal', message: 'Close to reaching your goal' }
  ]);
  const [workoutStats, setWorkoutStats] = useState({});
  const [todaysMeals, setTodaysMeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Fetch workouts from backend
  useEffect(() => {
    if (user?.id) {
      fetchWorkouts();
      fetchMealsSummary();
    }
  }, [user?.id]);

  const fetchWorkouts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/workout-history`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch workouts');
      const data = await response.json();
      setWorkouts(data);
    } catch (error) {
      console.error('Error fetching workouts:', error);
    }
  };

  const fetchMealsSummary = async () => {
    if (!user?.id) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/client/${user.id}/meals/summary`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch meals summary');
      const data = await response.json();
      setTodaysMeals(data);
    } catch (error) {
      console.error('Error fetching meals summary:', error);
    }
  };

  // Update timezone conversion utility - simplified version
  const formatToEST = (utcDate) => {
    try {
      const date = new Date(utcDate);
      return format(date, 'h:mm a') + ' EST';
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Time not available';
    }
  };

  // Helper function to format pace from seconds to MM:SS
  const formatPace = (paceInSeconds) => {
    if (!paceInSeconds) return '';
    const minutes = Math.floor(paceInSeconds / 60);
    const seconds = paceInSeconds % 60;
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
  };

  const calculateStreak = (sortedWorkouts) => {
    if (!sortedWorkouts || sortedWorkouts.length === 0) return 0;
    
    let streak = 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Check if there's a workout today
    const latestWorkoutDate = new Date(sortedWorkouts[0].date);
    latestWorkoutDate.setHours(0, 0, 0, 0);
    
    if (latestWorkoutDate < today) {
      // If no workout today, streak is broken
      return 0;
    }
    
    // Count consecutive days with workouts
    let currentDate = today;
    for (const workout of sortedWorkouts) {
      const workoutDate = new Date(workout.date);
      workoutDate.setHours(0, 0, 0, 0);
      
      if (workoutDate.getTime() === currentDate.getTime()) {
        streak++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (workoutDate < currentDate) {
        // Break in the streak
        break;
      }
    }
    
    return streak;
  };

  const calculateWorkoutStats = (workouts) => {
    if (!workouts || workouts.length === 0) {
      return {
        latestWorkout: null,
        completed: 0,
        total: 0,
        streak: 0
      };
    }

    // Sort workouts by date in descending order
    const sortedWorkouts = [...workouts].sort((a, b) => new Date(b.date) - new Date(a.date));
    
    // Get today's date at start of day
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Find today's workout or latest workout
    const latestWorkout = sortedWorkouts[0];

    // Calculate weekly stats
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    weekStart.setHours(0, 0, 0, 0);
    
    const weeklyWorkouts = sortedWorkouts.filter(workout => {
      const workoutDate = new Date(workout.date);
      return workoutDate >= weekStart && workoutDate <= today;
    });

    return {
      latestWorkout,
      completed: weeklyWorkouts.length,
      total: 5, // Target workouts per week
      streak: calculateStreak(sortedWorkouts)
    };
  };

  const handleAuthError = (error) => {
    if (error.status === 401) {
      logout();
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [logout]);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        handleAuthError({ status: 401 });
        return;
      }

      const headers = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      };

      // Fetch user profile data
      const profileResponse = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/profile`, {
        headers
      });
      if (!profileResponse.ok) {
        if (profileResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to fetch profile');
      }
      const profileData = await profileResponse.json();
      
      // Update userData with the actual name
      setUserData(prevData => ({
        ...prevData,
        name: profileData.name
      }));

      // Fetch today's meals
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      console.log('Fetching meals for date:', currentDate.toISOString());
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/client/1/meals/summary`, {
        headers
      });
      if (!response.ok) throw new Error('Failed to fetch meals');
      const mealsData = await response.json();
      const todayStr = currentDate.toISOString().split('T')[0];
      const todayData = mealsData[todayStr];

      if (todayData) {
        // Add the nutrition data to each meal
        const enrichedMeals = todayData.meals.map(meal => ({
          ...meal,
          protein: todayData.protein,
          carbs: todayData.carbs,
          fat: todayData.fat,
          meal_type: meal.type // Fix the type field name
        }));
        setTodaysMeals(enrichedMeals);
      } else {
        setTodaysMeals([]);
      }

      // Fetch workout stats with correct URL
      const workoutsResponse = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/workout-history`, {
        headers
      });
      if (!workoutsResponse.ok) {
        if (workoutsResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to fetch workouts');
      }
      const workoutsData = await workoutsResponse.json();
      
      // Sort workouts by date in descending order
      const sortedWorkouts = workoutsData.sort((a, b) => new Date(b.date) - new Date(a.date));
      
      // Filter workouts for today
      const today = startOfDay(new Date());
      const todaysWorkouts = sortedWorkouts.filter(workout => {
        const workoutDate = startOfDay(new Date(workout.date));
        return workoutDate.getTime() === today.getTime();
      });

      // Fetch today's exercises directly using the exercises endpoint
      const exercisesResponse = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/exercises?time_period=today`, {
        headers
      });
      if (!exercisesResponse.ok) {
        if (exercisesResponse.status === 401) {
          handleAuthError({ status: 401 });
          return;
        }
        throw new Error('Failed to fetch exercises');
      }
      
      const exercisesData = await exercisesResponse.json();
      
      // Map exercises to their workouts
      const workoutsWithExercises = todaysWorkouts.map(workout => ({
        ...workout,
        exercises: exercisesData.filter(ex => ex.workout_id === workout.id)
      }));

      setWorkoutStats({
        todaysWorkouts: workoutsWithExercises,
        completed: sortedWorkouts.length,
        total: 5,
        streak: calculateStreak(sortedWorkouts)
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (error.status === 401) {
        handleAuthError({ status: 401 });
      }
    } finally {
      setLoading(false);
    }
  };

  // Update userData with real workout data
  const [userData, setUserData] = useState({
    name: '',
    weeklyWorkouts: {
      completed: 0,
      total: 5,
      streak: 0
    },
    calories: {
      consumed: 1842,
      target: 2200,
      macros: {
        protein: 35,
        carbs: 45,
        fats: 20
      }
    },
    progress: {
      currentWeight: 180,
      targetWeight: 175,
      weightHistory: [
        { date: '2024-01-01', weight: 185 },
        { date: '2024-01-08', weight: 183 },
        { date: '2024-01-15', weight: 181 },
        { date: '2024-01-22', weight: 180 },
      ],
      steps: {
        current: 6000,
        target: 10000
      }
    },
    achievements: [
      { id: 1, title: 'Workout Warrior', description: '10 workouts completed', date: '2024-01-20' },
      { id: 2, title: 'Nutrition Master', description: 'Hit calorie goals 7 days in a row', date: '2024-01-18' }
    ],
    todaysPlan: {
      workout: {
        name: workoutStats.latestWorkout ? workoutStats.latestWorkout.name : 'No workout logged',
        time: workoutStats.latestWorkout ? new Date(workoutStats.latestWorkout.date).toLocaleTimeString() : ''
      },
      meals: [
        { type: 'Breakfast', calories: 400, completed: true },
        { type: 'Lunch', calories: 600, completed: true },
        { type: 'Dinner', calories: 700, completed: false },
        { type: 'Snacks', calories: 300, completed: false }
      ]
    }
  });

  // Update userData when workouts change
  useEffect(() => {
    setUserData(prevData => ({
      ...prevData,
      weeklyWorkouts: {
        completed: workoutStats.completed,
        total: workoutStats.total,
        streak: workoutStats.streak
      },
      todaysPlan: {
        ...prevData.todaysPlan,
        workout: {
          name: workoutStats.latestWorkout ? workoutStats.latestWorkout.name : 'No workout logged',
          time: workoutStats.latestWorkout ? new Date(workoutStats.latestWorkout.date).toLocaleTimeString() : ''
        }
      }
    }));
  }, [workouts]);

  // Progress Chart Data
  const weightProgressData = userData.progress.weightHistory;
  const macroData = [
    { name: 'Protein', value: userData.calories.macros.protein },
    { name: 'Carbs', value: userData.calories.macros.carbs },
    { name: 'Fats', value: userData.calories.macros.fats }
  ];

  // Colors for charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  // Get current section from URL
  function getCurrentSection() {
    const path = location.pathname.split('-')[1];
    return path || 'exercises';
  }

  const navigationItems = [
    
    {
      text: 'Workouts',
      icon: <FitnessCenter />,
      id: 'workouts',
      notifications: 1,
      stats: ''
    },
    ,{
      text: 'Exercises',
      icon: <FitnessCenter />,
      id: 'exercises',
      notifications: 1,
      highlight: true,
      stats: ''
    },
    {
      text: 'Nutrition',
      icon: <Restaurant />,
      id: 'nutrition',
      notifications: 1,
      stats: ''
    },
    // Progress and Analytics items hidden for now
    /*
    {
      text: 'Progress',
      icon: <Timeline />,
      id: 'progress',
      badge: '↑ 2.3%'
    },
    {
      text: 'Analytics',
      icon: <Assessment />,
      id: 'analytics',
      badge: 'New'
    }
    */
  ];

  // Notification Panel Component
  const NotificationPanel = () => (
    <Popover
      open={Boolean(notificationAnchor)}
      anchorEl={notificationAnchor}
      onClose={() => setNotificationAnchor(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ width: 320, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Notifications
          </Typography>
          <IconButton size="small" onClick={() => setNotificationAnchor(null)}>
            <Close />
          </IconButton>
        </Box>
        <Stack spacing={1}>
          {notifications.map((notification) => (
            <Card key={notification.id} variant="outlined">
              <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  {notification.type === 'achievement' && <EmojiEvents color="primary" sx={{ mr: 1 }} />}
                  {notification.type === 'reminder' && <Timer color="warning" sx={{ mr: 1 }} />}
                  {notification.type === 'goal' && <TrendingUp color="success" sx={{ mr: 1 }} />}
                  <Typography variant="subtitle2">
                    {notification.message}
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.secondary">
                  2 hours ago
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box>
    </Popover>
  );

  // Goals Overview Component
  const GoalsOverview = () => (
    <Paper 
      elevation={0}
      sx={{ 
        p: 3,
        mb: 4,
        border: `1px solid ${colors.border}`,
        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography 
          variant="h6" 
          sx={{ 
            color: colors.text,
            fontWeight: 500,
          }}
        >
          Active Goals
        </Typography>
        <Button 
          variant="outlined"
          startIcon={<FaPlus />}
          onClick={() => navigate('/goals')}
          sx={{
            borderColor: colors.accent,
            color: colors.accent,
            borderRadius: '12px',
            textTransform: 'none',
            '&:hover': {
              borderColor: colors.accent,
              bgcolor: colors.subtle,
            },
          }}
        >
          Add Goal
        </Button>
      </Box>
      <Grid container spacing={2}>
        {goals.length > 0 ? (
          goals.map((goal) => (
            <Grid item xs={12} md={6} key={goal.id}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '12px',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
                  },
                }}
              >
                <Typography 
                  variant="h3" 
                  sx={{ 
                    mb: 1,
                    color: colors.text,
                    fontWeight: 600,
                    fontSize: '2rem',
                  }}
                >
                  {goal.current_value}
                  <Typography 
                    component="span" 
                    variant="h6" 
                    sx={{ 
                      color: colors.muted,
                      ml: 1,
                      fontWeight: 400,
                    }}
                  >
                    / {goal.target_value} {goal.unit}
                  </Typography>
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TrendingUp sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: colors.muted,
                      fontWeight: 400,
                    }}
                  >
                    {goal.goal_type.charAt(0).toUpperCase() + goal.goal_type.slice(1)}
                    {goal.subtype && ` - ${goal.subtype}`}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={goal.progress_percentage}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    bgcolor: colors.subtle,
                    mb: 2,
                    '& .MuiLinearProgress-bar': {
                      bgcolor: colors.accent,
                    },
                  }}
                />
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: colors.muted,
                    fontWeight: 300,
                  }}
                >
                  Target Date: {new Date(goal.target_date).toLocaleDateString()}
                </Typography>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box 
              sx={{ 
                textAlign: 'center',
                py: 4,
                color: colors.muted,
              }}
            >
              <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600, mb: 1 }}>
                0
                <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
                  Active Goals
                </Typography>
              </Typography>
              <Typography variant="body1" sx={{ color: colors.muted, mb: 2 }}>
                Set your first goal to start tracking progress
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  // Update the QuickStats component
  const QuickStats = () => (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={6}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 3,
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FitnessCenter sx={{ color: colors.accent, mr: 1 }} />
            <Typography variant="h6" sx={{ color: colors.text, fontWeight: 500 }}>
              Weekly Workouts
            </Typography>
          </Box>
          <Typography variant="h3" sx={{ mb: 1, color: colors.text, fontWeight: 600 }}>
            {workoutStats.completed || 0}
            <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
              / {workoutStats.total}
            </Typography>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalFireDepartment sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
            <Typography variant="body1" sx={{ color: colors.muted }}>
              {workoutStats.streak || 0} day streak
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 3,
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.08)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Restaurant sx={{ color: colors.accent, mr: 1 }} />
            <Typography variant="h6" sx={{ color: colors.text, fontWeight: 500 }}>
              Today's Nutrition
            </Typography>
          </Box>
          <Typography variant="h3" sx={{ mb: 1, color: colors.text, fontWeight: 600 }}>
            {todaysMeals.reduce((sum, meal) => sum + meal.calories, 0)}
            <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
              cal
            </Typography>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TrendingUp sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
            <Typography variant="body1" sx={{ color: colors.muted }}>
              {Math.round((todaysMeals.reduce((sum, meal) => sum + meal.calories, 0) / userData.calories.target) * 100)}% of daily goal
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  // Main Content Component
  const MainContent = () => {
    return (
      <Box sx={{ 
        flexGrow: 1, 
        p: 4,
        ml: { xs: 0, sm: '280px' },
        width: { xs: '100%', sm: 'auto' }
      }}>
        {/* Welcome Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ 
            color: colors.text,
            fontWeight: 600,
            mb: 1,
          }}>
            Welcome back, {userData.name}!
          </Typography>
          <Typography variant="subtitle1" sx={{ color: colors.muted }}>
            Here's your fitness journey overview
          </Typography>
        </Box>

        {/* Goals Overview */}
        <GoalsOverview />

        {/* Today's Activity */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3,
            mb: 4,
            border: `1px solid ${colors.border}`,
            borderRadius: '16px',
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              color: colors.text,
              fontWeight: 500,
              mb: 3,
            }}
          >
            Today's Activity
          </Typography>
          <Grid container spacing={3}>
            {/* Latest Workout */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: colors.subtle,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '12px',
                }}
              >
                {workoutStats.todaysWorkouts && workoutStats.todaysWorkouts.length > 0 ? (
                  <Box>
                    <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600, mb: 1 }}>
                      {workoutStats.todaysWorkouts.length}
                      <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
                        Workouts
                      </Typography>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FitnessCenter sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
                      <Typography variant="body1" sx={{ color: colors.muted }}>
                        {workoutStats.todaysWorkouts[0].name}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600, mb: 1 }}>
                      0
                      <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
                        Workouts
                      </Typography>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FitnessCenter sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
                      <Typography variant="body1" sx={{ color: colors.muted }}>
                        No workouts logged today
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>

            {/* Today's Meals */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: colors.subtle,
                  border: `1px solid ${colors.border}`,
                  borderRadius: '12px',
                }}
              >
                {todaysMeals && todaysMeals.length > 0 ? (
                  <Box>
                    <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600, mb: 1 }}>
                      {todaysMeals.reduce((sum, meal) => sum + meal.calories, 0)}
                      <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
                        cal
                      </Typography>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Restaurant sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
                      <Typography variant="body1" sx={{ color: colors.muted }}>
                        {todaysMeals.length} meals logged
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600, mb: 1 }}>
                      0
                      <Typography component="span" variant="h6" sx={{ color: colors.muted, ml: 1 }}>
                        cal
                      </Typography>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Restaurant sx={{ color: colors.accent, mr: 1, fontSize: '1.2rem' }} />
                      <Typography variant="body1" sx={{ color: colors.muted }}>
                        No meals logged today
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Paper>

        {/* Quick Stats */}
        <QuickStats />
      </Box>
    );
  };

  // Navigation Handler
  const handleNavigation = (path) => {
    setSelectedSection(path);
    navigate(`/dashboard-${path}`);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  // Update the SideNav component
  const SideNav = () => (
    <Box
      sx={{
        width: 280,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: colors.primary,
        borderRight: `1px solid ${colors.border}`,
        pt: 3,
      }}
    >
      {/* Dashboard Header */}
      <Box sx={{ px: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" sx={{ 
            flexGrow: 1, 
            fontWeight: 600,
            color: colors.text,
          }}>
            Dashboard
          </Typography>
          <IconButton 
            onClick={(e) => setNotificationAnchor(e.currentTarget)}
            sx={{ 
              color: colors.muted,
              '&:hover': { color: colors.accent }
            }}
          >
            <Badge badgeContent={3} color="error">
              <Notifications sx={{ fontSize: 24 }} />
            </Badge>
          </IconButton>
        </Box>
        <Button
          fullWidth
          variant="contained"
          startIcon={<EmojiEvents />}
          sx={{
            bgcolor: colors.accent,
            color: colors.primary,
            textTransform: 'none',
            borderRadius: '12px',
            py: 1.5,
            fontWeight: 500,
            '&:hover': {
              bgcolor: colors.accent,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
            },
          }}
        >
          View Achievements
        </Button>
      </Box>

      {/* Navigation Items */}
      <List sx={{ px: 2, py: 0 }}>
        {navigationItems.map((item) => (
          <ListItem
            button
            key={item.id}
            selected={selectedSection === item.id}
            onClick={() => handleNavigation(item.id)}
            sx={{
              mb: 1.5,
              borderRadius: '12px',
              '&.Mui-selected': {
                bgcolor: colors.accent,
                color: colors.primary,
                '&:hover': {
                  bgcolor: colors.accent,
                },
                '& .MuiListItemIcon-root': {
                  color: colors.primary,
                },
              },
              '&:hover': {
                bgcolor: colors.subtle,
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 40,
                color: selectedSection === item.id ? 'inherit' : colors.muted,
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontSize: '1rem',
                fontWeight: selectedSection === item.id ? 500 : 400,
              }}
            />
            {item.notifications && (
              <Chip
                size="small"
                label={item.notifications}
                sx={{
                  bgcolor: selectedSection === item.id ? colors.primary : colors.accent,
                  color: selectedSection === item.id ? colors.accent : colors.primary,
                  height: 24,
                  '& .MuiChip-label': {
                    px: 1,
                    fontSize: '0.75rem',
                    fontWeight: 500,
                  },
                }}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* User Profile */}
      <Box sx={{ mt: 'auto', p: 2 }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            borderRadius: '12px',
            border: `1px solid ${colors.border}`,
            '&:hover': {
              borderColor: colors.accent,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                mr: 2,
                bgcolor: colors.accent,
              }}
            >
              {userData.name?.charAt(0)}
            </Avatar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ 
                fontWeight: 500,
                color: colors.text,
              }}>
                {userData.name}
              </Typography>
              <Typography variant="body2" sx={{ 
                color: colors.accent,
                fontWeight: 500,
              }}>
                Premium Member
              </Typography>
            </Box>
            <IconButton 
              size="small"
              onClick={() => navigate('/settings')}
              sx={{ 
                color: colors.muted,
                '&:hover': { color: colors.accent }
              }}
            >
              <Settings fontSize="small" />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      {isMobile ? (
        <>
          {/* Mobile Menu Button for Exercises/Nutrition */}
          <IconButton
            color="primary"
            sx={{
              position: 'fixed',
              left: 16,
              top: 85, // Position below the main header
              zIndex: 1199,
              bgcolor: 'primary.main',
              color: 'white',
              width: 45,
              height: 45,
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              }
            }}
            onClick={() => setMobileOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Left Drawer for Exercises/Nutrition */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
              sx: {
                width: '85%',
                maxWidth: 280,
                mt: 0,
                height: '100%',
                boxShadow: 3
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              p: 2,
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Menu
              </Typography>
              <IconButton onClick={() => setMobileOpen(false)}>
                <Close />
              </IconButton>
            </Box>
            <SideNav />
          </Drawer>
        </>
      ) : (
        <SideNav />
      )}
      <NotificationPanel />
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          width: '100%',
          mt: isMobile ? 2 : 0,
          pl: isMobile ? 7 : 0 // Add padding to prevent content from being hidden under the button
        }}
      >
        <MainContent />
      </Box>
    </Box>
  );
};

export default ClientDashboard; 