import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import workoutLoggerImage from '../images/Miro Fitness home workout image.jpg';
import mealTrackerImage from '../images/Miro Fitness meal workout image.jpg';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  alpha,
  Fade,
  Slide,
} from '@mui/material';
import {
  Speed,
  Timer,
  TrendingUp,
  Restaurant,
  FitnessCenter,
  VolumeUp,
  BarChart,
  MenuBook,
  Check,
} from '@mui/icons-material';

// Updated minimalist color palette
const colors = {
  primary: '#ffffff',
  secondary: '#ffffff',
  accent: '#00A3FF', // Capri blue
  text: '#000000',
  muted: 'rgba(0, 0, 0, 0.6)',
  subtle: 'rgba(0, 163, 255, 0.03)', // Even more subtle Capri blue
  border: 'rgba(0, 0, 0, 0.08)',
  cardHover: 'rgba(0, 163, 255, 0.03)',
};

// Subtle gradient for sections
const gradients = {
  subtle: `linear-gradient(180deg, 
    ${colors.primary} 0%,
    rgba(0, 163, 255, 0.02) 100%
  )`,
};

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const features = [
    {
      title: 'Real-Time Active Workout Logging',
      description: `Track your sets and reps as you go - log instantly through voice or manual input. Stay in flow. No distractions.`,
      icon: <VolumeUp sx={{ fontSize: 40 }} />,
      delay: 200,
      highlight: 'Voice-Enabled',
    },
    {
      title: 'Completed Workout Tracking',
      description: `For gym and cardio sessions. Track your performance when you finish. Stay accountable.`,
      icon: <FitnessCenter sx={{ fontSize: 40 }} />,
      delay: 300,
      highlight: 'Track Everything',
    },
    {
      title: 'AI-Powered Meal Tracking',
      description: `Take a photo or log meals by voice. Miro analyzes your macros, calories, and nutrition in seconds. Max results. Zero guesswork.`,
      icon: <Restaurant sx={{ fontSize: 40 }} />,
      delay: 400,
      highlight: 'AI-Powered',
    },
    {
      title: 'Progress Management',
      description: `Track every win. Review your progress whenever you want. Always know where you stand - and where you're going next.`,
      icon: <BarChart sx={{ fontSize: 40 }} />,
      delay: 500,
      highlight: 'Data-Driven',
    },
  ];

  const steps = [
    {
      title: 'Log It Fast',
      description: `Enter your workouts and meals in seconds. No delays, no interruptions.`,
      icon: <Speed sx={{ fontSize: 48 }} />,
      delay: 200,
    },
    {
      title: 'See Your Progress',
      description: `Analyze your workout and meal history anytime. Know exactly how far you've come.`,
      icon: <TrendingUp sx={{ fontSize: 48 }} />,
      delay: 400,
    },
    {
      title: 'Keep Winning',
      description: `Miro gives you more time to train and focus on results. Everything you need is in one app.`,
      icon: <Timer sx={{ fontSize: 48 }} />,
      delay: 600,
    },
  ];

  // Clean button styles
  const MinimalButton = ({ children, ...props }) => (
    <Button
      {...props}
      sx={{
        py: { xs: 2, md: 2.5 },
        px: { xs: 4, md: 5 },
        fontSize: { xs: '1rem', md: '1.1rem' },
        fontWeight: 500,
        borderRadius: '12px',
        background: colors.accent,
        color: colors.primary,
        textTransform: 'none',
        transition: 'all 0.2s ease',
        boxShadow: 'none',
        width: { xs: '100%', sm: 'auto' },
        '&:hover': {
          background: colors.accent,
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 20px rgba(0, 163, 255, 0.15)',
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );

  return (
    <Box sx={{ bgcolor: colors.primary, color: colors.text }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          minHeight: { xs: 'auto', md: '92vh' },
          pt: { xs: 6, md: 0 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: colors.primary,
          overflow: 'hidden',
          pb: { xs: 8, md: 12 },
        }}
      >
        <Container maxWidth="lg">
          <Grid 
            container 
            spacing={{ xs: 4, md: 8 }} 
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={5}>
              <Fade in timeout={800}>
                <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                  <Typography 
                    variant="h1" 
                    gutterBottom 
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '2.25rem', sm: '3rem', md: '4rem' },
                      lineHeight: 1.1,
                      letterSpacing: '-0.02em',
                      color: colors.text,
                      mb: { xs: 3, md: 4 },
                    }}
                  >
                    Track your workouts with just your voice.
                  </Typography>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      mb: { xs: 4, md: 5 },
                      color: colors.muted,
                      fontSize: { xs: '1.1rem', md: '1.3rem' },
                      lineHeight: 1.6,
                      maxWidth: { xs: '100%', md: '90%' },
                      fontWeight: 400,
                    }}
                  >
                    Log exercises, track meals, analyze progress—all hands-free—and push forward faster than ever before.
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'stretch', sm: 'center' },
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    gap: 2,
                    mb: 4,
                  }}>
                    <MinimalButton 
                      onClick={() => navigate('/register')}
                      sx={{
                        minWidth: { sm: '220px' },
                      }}
                    >
                      Start 3-Day Free Trial
                    </MinimalButton>
                  </Box>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: colors.muted,
                      textAlign: { xs: 'center', md: 'left' },
                      fontWeight: 300,
                    }}
                  >
                    No credit card required
                  </Typography>
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} md={7}>
              <Fade in timeout={1000}>
                <Box sx={{ 
                  position: 'relative',
                  maxWidth: { xs: '100%', md: '800px' },
                  margin: '0 auto',
                  transform: { xs: 'none', md: 'scale(1.1)' },
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    borderRadius: '24px',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    }
                  }
                }}>
                  <img src={workoutLoggerImage} alt="Miro Fitness Workout Logger" />
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Meal Tracking Section */}
      <Box sx={{ 
        py: { xs: 8, md: 12 },
        background: gradients.subtle,
      }}>
        <Container maxWidth="lg">
          <Grid 
            container 
            spacing={{ xs: 4, md: 8 }} 
            alignItems="center" 
            direction={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item xs={12} md={7}>
              <Fade in timeout={1000}>
                <Box sx={{ 
                  position: 'relative',
                  maxWidth: { xs: '100%', md: '800px' },
                  margin: '0 auto',
                  transform: { xs: 'none', md: 'scale(1.1)' },
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    borderRadius: '24px',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    }
                  }
                }}>
                  <img src={mealTrackerImage} alt="Miro Fitness Meal Tracker" />
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} md={5}>
              <Fade in timeout={800}>
                <Box sx={{ 
                  pl: { md: 6 },
                  textAlign: { xs: 'center', md: 'left' }
                }}>
                  <Typography 
                    variant="h2" 
                    gutterBottom 
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '2rem', sm: '3rem', md: '3.5rem' },
                      lineHeight: 1.1,
                      letterSpacing: '-0.02em',
                      color: colors.text,
                      mb: 4,
                    }}
                  >
                    Track your nutrition with AI precision.
                  </Typography>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      mb: 3,
                      color: colors.muted,
                      fontSize: { xs: '1.2rem', md: '1.4rem' },
                      lineHeight: 1.6,
                      maxWidth: '600px',
                      fontWeight: 400,
                    }}
                  >
                    Take a photo or use voice commands to log your meals. Our AI instantly calculates calories and macros.
                  </Typography>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Enhanced Features Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 } }}>
        <Typography 
          variant="h4" 
          sx={{ 
            textAlign: 'center', 
            mb: 2, 
            fontWeight: 700,
            color: colors.text,
          }}
        >
          What You Get
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            textAlign: 'center', 
            mb: 8, 
            color: colors.muted,
            fontWeight: 400,
            maxWidth: '600px',
            mx: 'auto',
          }}
        >
          Everything you need to maximize your fitness journey, powered by cutting-edge technology
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Fade in timeout={800} style={{ transitionDelay: `${feature.delay}ms` }}>
                <Box 
                  sx={{ 
                    position: 'relative',
                    p: { xs: 3, md: 4 },
                    borderRadius: '16px',
                    background: colors.primary,
                    transition: 'all 0.3s ease',
                    border: `1px solid ${colors.border}`,
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 12px 40px rgba(0, 163, 255, 0.06)',
                      borderColor: colors.accent,
                      background: colors.cardHover,
                      '& .feature-highlight': {
                        transform: 'translateY(0)',
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {/* Feature Highlight Tag */}
                  <Box
                    className="feature-highlight"
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      background: colors.accent,
                      color: colors.primary,
                      px: 2,
                      py: 0.5,
                      borderRadius: '20px',
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      transform: 'translateY(-40px)',
                      opacity: 0,
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {feature.highlight}
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box 
                      sx={{ 
                        color: colors.accent,
                        mr: 3,
                        p: 1.5,
                        borderRadius: '12px',
                        background: colors.subtle,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontWeight: 600,
                          color: colors.text,
                          mb: 1,
                          fontSize: { xs: '1.1rem', md: '1.25rem' },
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: colors.muted,
                          lineHeight: 1.6,
                          fontSize: { xs: '0.9rem', md: '1rem' },
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Why Miro Section */}
      <Box sx={{ 
        background: gradients.subtle,
        py: { xs: 8, md: 12 },
        px: { xs: 2, md: 0 },
      }}>
        <Container maxWidth="md">
          <Fade in timeout={800}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h3" 
                gutterBottom 
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2rem', md: '2.75rem' },
                  mb: 3,
                  color: colors.text,
                }}
              >
                Why Miro Fitness?
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: colors.muted,
                  fontSize: { xs: '1.1rem', md: '1.3rem' },
                  lineHeight: 1.6,
                  maxWidth: '800px',
                  mx: 'auto',
                  mb: 3,
                }}
              >
                Because time is your most valuable resource, and progress doesn't wait.
                Miro Fitness is designed for speed, convenience, and results—so you can stay consistent,
                build momentum, and reach your goals without wasting time.
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: colors.accent,
                  fontWeight: 600,
                }}
              >
                This is how winners work.
              </Typography>
            </Box>
          </Fade>
        </Container>
      </Box>

      {/* How It Works Section - Optimized for mobile */}
      <Box sx={{ 
        py: { xs: 8, md: 12 },
        background: gradients.subtle,
      }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            sx={{ 
              textAlign: 'center', 
              mb: { xs: 4, md: 6 },
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
              color: colors.text,
              px: { xs: 2, md: 0 },
            }}
          >
            How does Miro Fitness work?
          </Typography>
          
          <Grid 
            container 
            spacing={{ xs: 4, md: 6 }} 
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ px: { xs: 2, md: 0 } }}>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: colors.text,
                    fontSize: { xs: '1rem', md: '1.2rem' },
                    lineHeight: 1.8,
                    mb: 3,
                  }}
                >
                  When you start logging with Miro Fitness, multiple AI systems work together seamlessly. First, our voice recognition system captures your exercise details in real-time. Our natural language processing model, trained on millions of workout sessions, accurately interprets your commands and extracts key information like exercise names, sets, reps, and weights.
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: colors.text,
                    fontSize: { xs: '1rem', md: '1.2rem' },
                    lineHeight: 1.8,
                    mb: 3,
                  }}
                >
                  For nutrition tracking, our computer vision system analyzes your meal photos, breaking down portions and ingredients. The multimodal AI then processes this data to calculate precise macronutrient content - proteins, carbs, and fats - along with total calories.
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: colors.text,
                    fontSize: { xs: '1rem', md: '1.2rem' },
                    lineHeight: 1.8,
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  Finally, our machine learning algorithms analyze your patterns over time, providing insights into your progress and helping you optimize your fitness journey.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                position: 'relative',
                width: '100%',
                height: '0',
                paddingBottom: { xs: '100%', sm: '76.05633802816901%' },
                borderRadius: '20px',
                overflow: 'hidden',
                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                mx: { xs: 2, md: 0 },
              }}>
                <iframe 
                  src="https://www.loom.com/embed/98829d440d9d4c078604ba01112585a2?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" 
                  frameBorder="0" 
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box sx={{ 
        background: gradients.subtle,
        py: { xs: 10, md: 12 },
        px: { xs: 2, md: 0 },
      }}>
        <Container maxWidth="sm">
          <Fade in timeout={800}>
            <Box sx={{ 
              textAlign: 'center',
              width: '100%',
            }}>
              <Typography 
                variant="h4" 
                gutterBottom 
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '1.75rem', md: '2.25rem' },
                  mb: 4,
                  color: colors.text,
                  letterSpacing: '-0.02em',
                }}
              >
                Take Control of Your Progress
              </Typography>
              <MinimalButton 
                onClick={() => navigate('/register')}
                sx={{ mb: 3 }}
              >
                Start 3-Day Free Trial
              </MinimalButton>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: colors.muted,
                  fontWeight: 300,
                }}
              >
                No credit card required
              </Typography>
            </Box>
          </Fade>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
